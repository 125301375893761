import { axiosApi, basePath } from "./api";

// 是否同步商品进入赠品库
export function updateIsOpenSyncGift (data={traderId: sessionStorage.getItem("traderId")}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenSyncGift/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 是否对客户显示对账单数据
export function updateIsOpenBuyerFreeView (data={traderId: sessionStorage.getItem("traderId")}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenBuyerFreeView/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 微信/支付宝支付开关
export function updateIsOpenOnlinePay (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenOnlinePay/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 大额支付开关
export function updateIsOpenLargePay (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenLargePay/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 预付款开关
export function updateIsOpenOnlineCharge (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenOnlineCharge/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}



// 支付类型详情
export function onlineSetDetail (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerOnlineSet/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 支付详情更新
export function onlineSetUpdate(data = {}) {
  /* 
  type 类型(1-线上支付；2-大额支付；3-预付款)
  */
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerOnlineSet/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


//大额支付记录
export function Largemoneylist(data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/payChargeInfo/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

//线上交易记录
export function Onlinepaylist(data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/payOrderInfo/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}