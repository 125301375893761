<template>
  <div>
    <el-table :data="list">
      <el-table-column label="订单号" prop="orderSn"  align="center"></el-table-column>
      <el-table-column label="支付金额(元)" prop="amount"  align="center"></el-table-column>
      <el-table-column label="手续费(元)" prop="feeAmount"  align="center"></el-table-column>
      <el-table-column label="买家名称" prop="buyerName"  align="center"></el-table-column>
      <el-table-column label="支付类型" prop="payGatewayVO.payTypeName"  align="center"></el-table-column>
      <el-table-column label="支付渠道" prop="payGatewayVO.payChannelTypeName"  align="center"></el-table-column>
      <el-table-column label="是否支付成功" align="center" >
        <template slot-scope="{row}">
          <span v-if="row.payGatewayVO.paySuccess">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="交易时间" prop="showTime" align="center" width="140"></el-table-column>
      <el-table-column label="流水号" prop="separateSeqNo" align="center"></el-table-column>
      <el-table-column label="备注" prop="remark" align="center"></el-table-column>
      
    </el-table>

    <div class="tar mt-20">
      <router-link :to="`${basePath}/onlinePaymentInfo?userState=true`">
        <el-button type="text" class="width-auto">
          查看所有交易记录
          <i class="el-icon-arrow-right color-b"></i>
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { parseTime } from "@/utils";
import {Onlinepaylist} from "~/api/setting";
export default {
  name: 'onlinePayment',
  props: ['id',],
  data(){
    return {
      
      basePath: basePath,
      list: [],
    }
  },
  mounted(){
    this.getList()
  },
  computed:{
   
  },
  methods:{
    getList(){

      Onlinepaylist({sellerId: sessionStorage.getItem("traderId")}).then(res=>{
        this.list = res.elements.map(item=>{
          if(item.payGatewayVO.payTime){
            item.showTime = parseTime(item.payGatewayVO.payTime)
          }
          return item
        })
      }).catch(err=>{

      })
      
    },
  }
}
</script>

<style lang="less" scoped>

</style>