<template>
	<div id="largeMoneyInfo">
		<div class="clearfix">
			<el-form :inline='true' :model="formInline" class="form-search fl" label-width="auto">
				<el-form-item label="充值状态">
						<el-select v-model="formInline.chargeStatus" collapse-tags placeholder="请选择" clearable>
							<el-option v-for="item in options" :key="item.chargeStatus" :value="item.chargeStatus" :label="item.name" />
						</el-select>
				</el-form-item>
				
				<el-form-item label="交易时间">
					<ys-daterange-picker v-model="time"/>
				</el-form-item>
			
			</el-form>
			<el-button class="fl" type="info" @click="handleSearch">查 询</el-button>
		</div>
		<div class="clearfix">
			<!-- <div @click="Export">
				<span class="fr color-blue export">导出</span>
			</div> -->
			<el-tabs class="bbd ctabs mb-10">
				<el-tab-pane label="所有明细" name="0"></el-tab-pane>
			</el-tabs>
			<el-table :data="list" :class="{'table-body-hidden':list.length==0}" stripe>
				<el-table-column label="流水号" prop="chargeSn"  align="center"></el-table-column>
				<el-table-column label="客户名称" prop="buyerName"  align="center"></el-table-column>
				<el-table-column :label="userState?'充值时间':'支付时间'"  align="center" width="140" >
					<template slot-scope="{row}">
						<span v-if="row.showTime">{{ row.showTime }}</span>
						<span v-else>--</span>
					</template>
				</el-table-column>
				<el-table-column label="充值金额(元)" prop="chargeAmount"  align="center"></el-table-column>
				<el-table-column label="手续费(元)"  align="center">
					<template slot-scope="{row}">
						<span v-if="row.chargeFee">{{ row.chargeFee }}</span>
						<span v-else>--</span>
					</template>
				</el-table-column>
				<el-table-column label="充值状态"  align="center" >
					<template slot-scope="{row}">
						<el-tooltip placement="top" v-if="row.chargeStatus==='fail'">
							<div slot="content" >{{ row.failReason }}</div>
							<el-link type="danger">{{ row.chargeStatusName }}</el-link>
						</el-tooltip>
						<div v-else>{{ row.chargeStatusName }}</div>
					</template>
				</el-table-column>
				<el-table-column label="支付类型" prop="payGatewayVO.systemTypeName"  align="center"></el-table-column>
				<!-- <el-table-column label="备注" prop="payGatewayVO.desc"  align="center"></el-table-column> -->
				<el-table-column label="k3收款单号" prop="erpReceiveBillNo"  align="center"></el-table-column>
				<el-table-column label="操作" align="center" v-if="!userState">
					<template slot-scope="{row}" >
						<el-link v-if="row.chargeStatus=='handling'" type="primary" @click="toUrl(row.chargeSn)">付款信息</el-link>
						<span v-else>--</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar" :current-page="pageIndex" :page-size='pageSize' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
			<div class="tac mt-30" v-if="list.length==0">
				<img src="../../../assets/empty.png" alt="" />
				<div class="color-9 mt-30">
					<span>暂无记录</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '~/api/api'
	import {Largemoneylist} from "~/api/setting";
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	import { parseTime } from "@/utils";
	// import { exportInfo } from "~/api/download";
	import { getSelectBySn } from "~/api/paymentSet";

	export default {
		name: 'largeMoneyInfo',
		components: {YsDaterangePicker},
		data() {
			let initDate = new Date().setHours(23, 59, 59, 0)
			return {
				buyerId:"",
				sellerId:"",
				userState:false,//状态 客户查看为默认fasle,商户查看为true
				time: [new Date(initDate - 1000 * 60 * 60 * 24 * 90),initDate],
				settlementIntegration: 0, 
				paymentdata:{
					fundsId:"",
				},
				//查询参数
                formInline: {
					queryBeginDate:null,//开始时间
					queryEndDate:null,//结束时间
					chargeStatus:"",//充值状态
				},
				options: [
					{chargeStatus: 'handling', name: '待付款'},
					{chargeStatus: 'pay', name: '支付完成'},
					{chargeStatus: 'success', name: '充值成功'},
					{chargeStatus: 'fail', name: '充值失败'},
				],

				list: [],
				total:0,
				pageIndex:1,
				pageSize:10
			
      }
		},
		mounted() {
			
			this.userState = this.$route.query.userState === 'true';
			if(!this.userState) {
				this.buyerId=sessionStorage.getItem("traderId")
				this.sellerId=sessionStorage.getItem("sellerId")
			 } //客户查看，传值
			else
			{
				this.buyerId=""     //商户查看，不传
				this.sellerId=sessionStorage.getItem("traderId")
			}
			this.getList()
			// console.log("是否商户:",this.userState,"buyid:",this.buyerId);

			
		},
		created() {
			
		},
			
		methods: {
			handleSearch() { //查询
				this.getList()
			},

			// 获取列表
			getList(){
				if(this.time[0]){
					this.formInline.queryBeginDate = new Date(this.time[0]).getTime()
				}else{
					this.formInline.queryBeginDate = null
				}
				if(this.time[1]){
					this.formInline.queryEndDate = new Date(this.time[1]).getTime()
				}else{
					this.formInline.queryEndDate = null
				}
				this.formInline.pageIndex = this.pageIndex
				this.formInline.pageSize = this.pageSize
				let body={
					buyerId:this.buyerId,
					sellerId: this.sellerId,
					startDate:this.formInline.queryBeginDate,
					endDate:this.formInline.queryEndDate,
					chargeStatus:this.formInline.chargeStatus,
					pageIndex:this.formInline.pageIndex,
				    pageSize:this.formInline.pageSize

				}
				Largemoneylist(body).then(res=>{
					this.list = res.elements.map(item=>{ 
						if(item.separateTime){
							item.showTime = parseTime(item.separateTime)
						}else{
							item.showTime = ''
						}
						return item
					})
					this.total=res.totalCount
				}).catch(err=>{
					console.log(err);
					
				})
				
			},
			
			handleCurrentChange(val) { //分页change
				this.pageIndex = val
				this.getList()
			},

        getSelectBySn(data){
            getSelectBySn(data).then(res=>{
                if(res){
                    if (res.paySuccess) {
                         this.$message({
                            message:'支付已完成',
                            type:'success'
                        })
						this.getList()
                    }else{
                        this.$router.push({path:basePath+'/prePaySet',query:{chargeSn:res.outSn}})
                    }
                }else{
					this.getList()
                }
            }).catch(err=>{

            })
        },
			toUrl(chargeSn) {
                var data = {
                    outSn:chargeSn,
                    outType:'charge'
                }
                this.getSelectBySn(data)
				
			},




            //导入
			// exportInfo() {
			// 	var api = '/export/foundation/employee'
			// 	const body = {
			// 		loginName: this.form.loginName,
			// 		employeeName: this.form.employeeName,
			// 		departmentId: this.emptyId[this.emptyId.length - 1],
			// 		applicationType: 2,
			// 		traderId: sessionStorage.traderId,
			// 		isDeleted: 0,
			// 		idList: this.ids.join()
			// 	}
			// 	body.modelName = 'empty',
			// 	body.startRow = 0
			// 	body.fileName = '大额支付记录.xls'
			// 	exportInfo(api,body).then((res) => {
			// 	}).catch((err) => {
						
			// 	})
			// },
		}
	}
</script>

<style lang="less">
	#largeMoneyInfo {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 40px 0;
		.export{
            font-weight: normal;
            position: relative;
            top: 10px;
            cursor: pointer;
        }
		/*表单查询*/
		.form-search {
			
			.el-form-item {
				margin-right: 30px;
			}
			.el-input__inner {
				font-size: 12px;
			}
			.el-form-item__label {
				padding: 0 10px 0 0;
				width: 57px;
				font-size: 12px;
				color: #333333;
				letter-spacing: -0.29px;
			}
			/*日期控件*/
			.el-date-editor--daterange.el-input {
				width: 240px;
			}
		}
		.ctabs {
			.el-tabs__header {
				padding-left: 0px;
			}
			.el-tabs__item {
				font-size: 12px;
			}
		}
		.el-table {
			th>.cell {
				font-weight: normal;
				color: #333333;
			}
			.transactiactive-text-btn {
				font-size: 12px;width: auto;
			}
		}
		.table-body-hidden {
			.el-table__empty-block {
				display: none;
			}
		}
		.h32{
			height: 32px;line-height: 32px;
		}
	}
</style>