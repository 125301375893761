<template>
	<div id="withdrawal">
		<el-form label-width="186px" :model="form" :rules="rules" ref="form" class="mt-50">
            <el-form-item label="提现账号:">
                <span>支付宝账号({{form.accountName}})</span>
            </el-form-item>
			<el-form-item label="提现金额:">
				<span>{{form.cashAmount | fmoney}}</span>元
			</el-form-item>
            <el-form-item label="手续费:">
                <span>0.00元（提现需要扣除 0.00% 手续费）</span>
            </el-form-item>
			<el-form-item label="最终到账:">
				<span>{{form.money ||0 | fmoney}}</span>元
			</el-form-item>


			<el-form-item label="支付密码:" prop="pass" >
				<el-input type="password" :maxlength="6" v-model="form.pass" placeholder="请输入支付密码" @change="clearmsg"></el-input>
                <el-button type="text" class="ml-30" @click="forgetPass">忘记密码</el-button>
				<!--<p v-if="errormsg.length>0" class="color-danger lh-14 mt-5">{{errormsg}}</p>-->
			</el-form-item>
			<el-form-item class="mt-50">
				<el-button type="danger" :disabled="disabled" @click="submit">确定</el-button>
                <el-button type="text"  @click="back">返回修改</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	import { mapActions,mapMutations } from "vuex"
	import md5 from "md5"
	export default {
		data() {
			return {
			    disabled:false,
				form: {},
				rules: {
					pass: [{
						required: true,
						message: "请输入支付密码",
                        trigger: 'blur'
					}, {
						pattern: /^\d{6}$/,
						message: "请输入6位数字的支付密码",
                        trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			this.form = sessionStorage.getItem("payform") ? JSON.parse(sessionStorage.getItem("payform")) : {}
			this.clearmsg()
		},
		computed: {
			errormsg() {
				return this.$store.state.paymodule.errormsg
			}
		},
		methods: {
			...mapActions({
				"applyCash": "applyCash",
				
			}),
			...mapMutations([
				"clearmsg"
			]),
            // forgetPass(){
            //     this.$router.push(`${basePath}/pay/bind`)
            // },
            back(){
                this.$router.go(-1)
            },
			submit() {
				this.$refs.form.validate(val => {
					if(val) {
					    this.disabled = true
						this.applyCash({
							cashAmount: this.form.cashAmount,
                            cashBankId: this.form.cashBankId,
                            payPassword: md5(this.form.pass || ""),
						}).then(res=>{
						    this.disabled = false
                        })
					} else {
						// console.log("fail")
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#withdrawal {
		padding: 30px 155px;
		.el-form-item__label {
			padding-right: 30px;
		}
	}
</style>