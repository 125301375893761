<template>
    <div id="storeInformation">
        <div class="bg-f mt-5" v-loading="loading">
            <div class="ml-100 pl-20 pt-50 pb-40 bg">
                <el-form :model='ruleForm' label-width='130px' :rules="rules" ref="ruleForm">
                    <el-form-item label="编号:">
                        <span class="fl">{{ruleForm.traderNumber}}</span>
                    </el-form-item>
                    <el-form-item label="商户图片:">
						<span class="fl">
							<el-upload class="avatar-uploader" action="/sunrise-gateway/oss/ossUpload"
                                       :show-file-list="false" :on-success="handleAvatarScucess"
                                       :before-upload="beforeAvatarUpload">
								<img v-if="avater" :src="avater" class="avatar">
                                <!--<i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
								<img v-else src="../../../assets/header.jpg"/>
							</el-upload>
						</span>
                    </el-form-item>
                    <el-form-item label="商户名称:">
                        <el-input v-model.trim="ruleForm.storeName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="商户简称:">
                        <el-input v-model.trim="ruleForm.platformDescription" disabled></el-input>
                    </el-form-item>
                    <div class="clearfix">
                        <el-form-item label="所在地区:" class='fl'>
                            <el-input v-model.trim="address" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="" class='fl own'>
                            <el-input v-model.trim="ruleForm.addressVO.streetName" disabled></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="详细地址:">
                        <el-input v-model.trim="ruleForm.addressVO.addr" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照编码:">
                        <el-input v-model.trim="ruleForm.licenseNum" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照照片:">
						<span class="fl hasP">
							<!--<span @click="showImg"><img class='icon-90' :src="ruleForm.licenseImg"/></span>-->
							<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list='ruleForm.licenseImg'
                                       :disabled="true"
                                       list-type="picture-card" :on-preview="showImg">
							</el-upload>
							<el-dialog :visible.sync="dialogVis" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</span>
                    </el-form-item>
                    <el-form-item label="门店照片:" prop="photos">
						<span class="fl">
							<input type="hidden" v-model="ruleForm.photos"/>
							<el-upload :class="isDisable?'fileList2':''" action="/sunrise-gateway/oss/ossUpload"
                                       :file-list='fileList' list-type="picture-card"
                                       :disabled="true"
                                       :on-preview="handlePictureCardPreview" :on-success="handleAvatarPhotoScucess"
                                       :before-upload="beforeAvatarUpload" :on-remove="handleRemove">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</span>
                    </el-form-item>
                    <el-form-item label="联系人姓名:" prop="linkname">
                        <el-input v-model.trim="ruleForm.linkname" placeholder="请输入联系人"></el-input>
                    </el-form-item>
                    <el-form-item label="职位:" prop="jobOffer">
                        <el-input v-model.trim="ruleForm.jobOffer"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号:" prop="tel">
                        <el-input v-model.trim="ruleForm.tel" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="Email:" prop="email">
                        <el-input v-model.trim="ruleForm.email" placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="推荐人:">
                        <span class="fl">{{ruleForm.distManagerMobile}}</span>
                    </el-form-item>
                </el-form>
                <div class="mt-20 ml-100 pl-30">
                    <el-button type="danger" @click="handleUpadate">确定</el-button>
                    <el-button class="ml-50" @click="handleReset">重置</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {axiosApi, basePath} from '../../../api/api';

    export default {
        props:["parentRuleForm","address"],
        data() {
            return {
                rules: {
                    linkname: [{required: true, message: '请输入联系人姓名'},
                        {pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,6}$/, message: '请输入2-6个字符，可为汉字或字母'}
                    ],
                    photos: [{required: true, message: '请上传门店图片', type: 'array'},
                        {min: 1, max: 2, type: "array", message: "请上传两张门店照片"}
                    ],
                    jobOffer: [{
                        pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,10}$/,
                        min: 2,
                        max: 10,
                        message: '请输入2-10个字符',
                    }],
                    tel: [{required: true, message: '请输入手机号'},
                        {pattern: /^1\d{10}$/, message: '请输入正确的手机号'}
                    ],
                    email: [{
                        pattern: /^(?=\w+([-+.']\w+)*@).{2,21}\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                        message: '请输入正确的Email'
                    }]
                },
                inputName: '',
                loading: false,
                isDisable: false,//控制加号显示隐藏
                dialogVisible: false,//预览
                dialogVis: false,
                dialogImageUrl: '',//图片显示
                fileList: [],//门店照片
                avater: '',
                deleteImgList: [], //删除门店图片信息存储
                newImgList: [], //新增门店图片图片存储
                ruleForm: {
                    traderNumber: '',
                    avater: '',
                    storeName: '',
                    platformDescription: '',
                    addressVO: {},
                    licenseNum: '',
                    licenseImg: [],
                    photos: [],
                    linkname: '',//联系人
                    jobOffer: '',//职位
                    tel: '',//手机号
                    email: '',//邮箱
                    name: '',//推荐人姓名
                    distManagerMobile: '',//推荐人手机号
                }
            }
        },
        watch:{
            parentRuleForm:{
                handler:function(val){
                    this.ruleForm = val;
                    this.fileList = val.photos;//图片展示
                    this.isDisable = this.fileList.length > 1
                }
            }
        },
        methods: {
            showImg(file) {//预览
                this.dialogImageUrl = file.url;
                this.dialogVis = true
            },
            /* //获取店铺信息
            getStoreInfo() {
                const api = "/trader/findStoreInfo/select";
                const body = {traderId: sessionStorage.traderId};
                axiosApi(api, body, (res) => {
                    //console.log(res,'店铺信息')
                    this.ruleForm.traderNumber = res.data.data.traderNumber;//编号
                    this.avater = res.data.data.avater;//头像
                    this.ruleForm.storeName = res.data.data.storeName;//商户名称
                    this.ruleForm.platformDescription = res.data.data.platformDescription;//商户名称
                    this.ruleForm.addressVO = res.data.data.addressVO;//地区
                    this.address = res.data.data.addressVO.provinceName + '/' + res.data.data.addressVO.cityName + '/' + res.data.data.addressVO.regionName;
                    this.ruleForm.licenseNum = res.data.data.licenseNum;//执照编码
                    this.ruleForm.licenseImg.push({url: res.data.data.licenseImg});//营业执照
                    this.ruleForm.photos = res.data.data.photos;//门头照片
                    this.ruleForm.linkname = res.data.data.linkname;//联系人
                    this.ruleForm.jobOffer = res.data.data.jobOffer;//职位
                    this.ruleForm.tel = res.data.data.tel;//手机号
                    this.ruleForm.email = res.data.data.email;//邮箱
                    this.ruleForm.name = res.data.data.name;//推荐人姓名
                    this.ruleForm.distManagerMobile = res.data.data.distManagerMobile;//推荐人手机号
                    this.ruleForm.photos.forEach((item) => {
                        item.url = item.photoUrl;
                    });//图片处理
                    this.fileList = this.ruleForm.photos;//图片展示
                    if (this.fileList.length > 1) {
                        this.isDisable = true
                    } else {
                        this.isDisable = false
                    }
                }, 1, 10)
            }, */
            //修改店铺信息
            handleUpadate() {
                let traderPhotoRelationParams = []
                traderPhotoRelationParams.push({ //添加最新门店图片
                    traderId: sessionStorage.traderId,
                    type: '1',
                    photoUrl: this.newImgList
                })
                this.newImgList = [] //在一次添加后重置数组
                if (this.deleteImgList.length > 0) {
                    this.deleteImgList.forEach((item) => {
                        traderPhotoRelationParams.push({
                            traderId: sessionStorage.traderId,
                            photoId: item,
                            type: '2'
                        })
                    })
                    this.deleteImgList = [] //在一次添加后重置数组
                }
                ;
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', null, [
                        h('p', {style: 'text-align:center'}, '是否确认保存您的修改？'),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            done()
                            this.$refs.ruleForm.validate((valid) => {
                                if (valid) {
                                    const api = "/trader/updateTraderInfo/update";
                                    const body = {
                                        traderId: sessionStorage.traderId,
                                        avater: this.avater,
                                        storeName: this.ruleForm.storeName,
                                        licenseNum: this.ruleForm.licenseNum,
                                        licenseImg: this.ruleForm.licenseImg.url,
                                        linkname: this.ruleForm.linkname,
                                        jobOffer: this.ruleForm.jobOffer,
                                        tel: this.ruleForm.tel,
                                        email: this.ruleForm.email,
                                        distManagerMobile: this.ruleForm.distManagerMobile,
                                        deliveryAddressParam: this.ruleForm.addressVO,
                                        traderPhotoRelationParams: traderPhotoRelationParams
                                    };
                                    axiosApi(api, body, (res) => {
                                        this.$message({
                                            type: 'success',
                                            message: '修改成功',
                                            duration: 1000
                                        });
                                    }, 1, 10)
                                } else {
                                    return false
                                }
                            })
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            //重置
            handleReset() {
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', null, [
                        h('p', {style: 'text-align:center'}, '是否确认重置模版？ '),
                        h('p', {style: 'text-align:center;color:#999;margin-top:10px'}, '重置模版后，您未保存的信息将被覆盖 ')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.ruleForm.licenseImg = [];
                            this.$emit('reset')
                            // this.getStoreInfo();
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            //上传头像
            beforeAvatarUpload(file) {
                this.loading = true;
                const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
                const isLt4M = file.size / 1024 / 1024 < 4;
                if (!isImage) {
                    this.loading = false;
                    this.$message.error('上传图片只能是jpg,jpeg,png,bmp格式');
                }
                if (!isLt4M) {
                    this.loading = false;
                    this.$message.error('上传图片大小不能超过 4MB');
                }
                return isImage && isLt4M;
            },
            handleAvatarScucess(res) {
                this.loading = false;
                this.avater = res.data[0];
            },
            //上传门店照片
            handlePictureCardPreview(file) {//预览
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleAvatarPhotoScucess(res, file, fileList) {//上传成功的状态
                this.loading = false;
                this.newImgList.push(res.data[0])
                file.url = res.data[0];
                file.photoUrl = res.data[0];
                this.ruleForm.photos = fileList;
                this.$refs.ruleForm.validateField("photos"); //部分表单字段验证
                //成功的回调
                if (fileList.length >= 2) {
                    this.isDisable = true
                } else {
                    this.isDisable = false
                }
            },
            handleRemove(file, fileList) {//删除
                if (file.id) this.deleteImgList.push(file.id) //存储删除图片的信息（id,url）
                this.ruleForm.photos = fileList;
                this.$refs.ruleForm.validateField("photos"); //部分表单字段验证
                if (fileList.length >= 2) {
                    this.isDisable = true
                } else {
                    this.isDisable = false
                }
            },

        },
        mounted() {
            // this.$nextTick(function () {
            //     this.getStoreInfo();
            // })
        }
    }
</script>
<style lang="less">
    #storeInformation {
        .avatar {
            width: 90px;
            height: 90px;
            line-height: 90px;
        }

        .avatar-uploader-icon {
            border: 1px solid #ddd;
            width: 90px;
            height: 90px;
            line-height: 90px;
            font-size: 30px;
        }

        .has {
            .el-icon-delete2 {
                display: none;
            }

        }

        .el-upload--picture-card {
            width: 90px;
            height: 90px;
            line-height: 100px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
        }

        .fileList2 .el-upload--picture-card {
            display: none;
        }

        .own {
            width: 200px !important;

            .el-form-item__content {
                margin-left: 15px !important;
            }
        }
    }

</style>