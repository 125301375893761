<template>
   <el-dialog
      title="企业认证"
      width="500px"
      :visible="showVisible"
      @close="closeDialog"
    >
      <el-form class="pl-30 pr-30" label-width="100px">
        <el-form-item label='商户号:'>
          <el-input class="ml-20" v-model.trim="form.huifuId" placeholder="请输入商户号"></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color:#F56C6C">商户号在汇付开通商户后生成，需填写对应商户号，云商方可认证。</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" :loading="btnLoading" @click="confirm">确 认</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </div>
    </el-dialog>
</template>

<script>
    import { setCert,getEmployeeInfo } from "~/api/publicInfo";
    export default {
        name: 'enterpriseAuthentication',
        props: [
          'dialogVisible',
        ],
        data() {
            return {
                showVisible: false,
                btnLoading: false,
                form:{
                  huifuId:null,
                  traderId:null,
                  licenseNo:null,
                },
            }
        },
        watch: {
            dialogVisible(val) {
                this.showVisible = val
                if (val) {
                  this.getInfoList()
                }
            },
        },
        methods: {
          getInfoList(){
            // var data = {
            //   traderId: sessionStorage.traderId
            // }
            getEmployeeInfo().then(res=>{
              this.form.licenseNo = res.licenseNum
            }).catch(err=>{

            })
          },
          
          confirm(){
            this.btnLoading = true
            let func = this.$debouncing(() => {
                this.form.traderId = sessionStorage.traderId
                if(this.form.huifuId==null){
                  this.$message.warning("请输入正确商户号！")
                  this.btnLoading = false
                  return
                }
                setCert(this.form).then(res=>{
                  this.$emit('confirm')
                  this.closeDialog()
                  
                }).catch(err=>{
                  this.closeDialog()
                })
            });
            func();
          },
          closeDialog(){
            this.$emit('close')
            this.form.huifuId = null
            this.showVisible = false
            this.btnLoading = false
          },
        }
    }
</script>
<style lang="less">
 
</style>
