<template>
  <div id="generalSet">
    <div class="title-tabs bg-f">
      <ul class="clearfix">
        <template v-for="(tab, index) in dataTabs" class="item">
          <li
            :type="index"
            :class="type == index ? 'active' : ''"
            @click="handleTabs"
            :key="index + 'dc'"
          >
            {{ tab }}
            <div :class="type == index ? 'under-line' : ''"></div>
          </li>
          <div class="vertical-line" :key="index + 'dd'"></div>
        </template>
      </ul>
    </div>
    <!-- 开通K3设置 -->
    <div
      class="mt-30 bg-f pl-50 pr-50 pt-30 pb-50"
      v-if="type == 3"
      style="minheight: 765px"
    >
      <el-form
        ref="K3"
        label-width="170px"
        :rules="rules"
        class="setk3warehouse"
      >
        <div class="k3_item_set">
          <div class="title-box">仓库配置</div>
          <div class="k3_item_content">
            <div
              v-for="(n, i) in setformK3List"
              :key="i"
              class="setk3warehouse_item"
            >
              <div class="k3warehouse">
                <span class="k3WarehouseName tar" style="marginleft: 82px"
                  >销售组织号：</span
                >
                <el-input
                  v-model.trim="n.k3SalesCompanyNo"
                  :maxlength="32"
                  :disabled="n.isMainCompany"
                  class="mb-20"
                ></el-input>
                <el-button
                  type="text"
                  v-if="!n.isMainCompany"
                  class="addk3WarehouseBtn"
                  @click="deleteCompany(n, i)"
                  >删除
                </el-button>
                <span
                  v-if="!n.k3SalesCompanyNo || n.k3SalesCompanyNo.length > 32"
                  class="k3WarehouseNoNameError"
                  >请输入正确的销售组织号（1-32位字符）</span
                >
                <!-- <el-button type="primary" class="ml-10" v-if="i==0">客户补偿</el-button> -->
              </div>
              <!-- 仓库号 -->
              <div class="k3warehouse">
                <i>*</i>
                <el-input
                  class="k3WarehouseName"
                  v-model.trim="n.k3WarehouseName"
                  :maxlength="10"
                ></el-input>
                <el-input
                  class="k3WarehouseNo"
                  v-model.trim="n.k3WarehouseNo"
                  :maxlength="100"
                ></el-input>
                <el-button
                  type="text"
                  class="addk3WarehouseBtn"
                  @click="addGroupk3house(i)"
                  >新增
                </el-button>
                <span
                  v-if="
                    !/^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(n.k3WarehouseName)
                  "
                  class="k3WarehouseNameError"
                  >3-10汉字,字母,数字</span
                >
                <span v-if="!n.k3WarehouseNo" class="k3WarehouseNoNameError"
                  >请输入K3仓库号</span
                >
              </div>
              <div
                class="childk3warehouse"
                v-if="n.childWarehouse.length > 0"
                v-for="(item, index) in n.childWarehouse"
                :key="index"
              >
                <el-input
                  class="k3WarehouseName"
                  v-model.trim="item.name"
                  :maxlength="10"
                ></el-input>
                <el-input
                  class="k3WarehouseNo"
                  v-model.trim="item.no"
                  :maxlength="100"
                ></el-input>
                <i
                  class="el-icon-close cursor"
                  style="margin: 8px"
                  @click="deleteGroupk3house(item, i)"
                ></i>
                <span
                  v-if="!/^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(item.name)"
                  class="k3WarehouseNameError"
                  >3-10汉字,字母,数字</span
                >
                <span v-if="!item.no" class="k3WarehouseNoNameError"
                  >请输入K3仓库号</span
                >
              </div>
            </div>
            <div class="addGroup">
              <el-button
                type="primary"
                style="marginleft: 64px"
                @click="addGroup"
                >新增组织</el-button
              >
            </div>
          </div>
        </div>
        <!-- <div class="k3_item_set">
                    <div class="title-box">客户属性配置</div>
                    <div class="k3_item_content">
                        <el-form-item label="客户类别：">
                            <el-input v-model.trim="formK3.k3DefaultCustomerType"></el-input>
                        </el-form-item>
                        <el-form-item label="云商客户标记：">
                            <el-input v-model.trim="formK3.k3DefaultCustomerFlagName"></el-input>
                        </el-form-item>
                    </div>
                </div> -->
        <!-- <div class="k3_item_set">
                    <div class="title-box">收款单配置</div>
                    <div class="k3_item_content">
                        <el-form-item label="结算方式：" prop="k3receiptSettleType">
                            <el-input v-model.trim="formK3.k3receiptSettleType"></el-input>
                        </el-form-item>
                        <el-form-item label="凭证字：" prop="k3receiptMoneyCertificate">
                            <el-input v-model.trim="formK3.k3receiptMoneyCertificate"></el-input>
                        </el-form-item>
                    </div>
                </div> -->
        <!-- <div class="k3_item_set">
                    <div class="title-box">其他配置</div>
                    <div class="k3_item_content">
                        <el-form-item label="订单支持拆分发货：" v-if="!formK3.k3OpenWms">
                            <el-switch
                                    v-model="formK3.deliverySeparate"
                                    active-text
                                    inactive-text
                                    active-color="#13ce66"
                                    class="fr"
                                    style="margin-right:90px;"
                            ></el-switch>
                        </el-form-item>
                        <el-form-item label="供应商商品直发：">
                            <el-switch
                                    v-model="formK3.openStraight"
                                    active-text
                                    inactive-text
                                    active-color="#13ce66"
                                    class="fr"
                                    style="margin-right:90px;"
                            ></el-switch>
                        </el-form-item>
                    </div>
                </div> -->
      </el-form>
      <div class="pt-30">
        <el-button
          type="primary"
          style="marginleft: 171px"
          @click="setk3warehouseConfirm"
          >确定</el-button
        >
      </div>
    </div>
    <!-- 短信设置 -->
    <div
      class="mt-30 bg-f pl-50 pr-50 pt-30 pb-50"
      v-if="type == 1"
      style="minheight: 765px"
    >
      <div class="main mt-20" v-if="!isHotel">
        <div class="main-tit">
          <span class="ml-30">订单短信</span>
          <el-switch
            v-model="orderMessageSwitch"
            active-text
            inactive-text
            :active-value="true"
            :inactive-value="false"
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handleOrderMessageSwitch"
          ></el-switch>
        </div>
        <div class="main-con">
          <p class="ml-30">开启订单短信，顾客在下单时将收到订单提醒短信。</p>
        </div>
      </div>
      <!-- 账期支付和货到付款模式下验证码管理 -->
      <div class="main mt-20">
        <div class="main-tit">
          <span class="ml-30"
            >账期支付模式下验证码管理，开启后需要发送手机验证码</span
          >
          <el-switch
            v-model="buyerInfoSwitch"
            active-text
            inactive-text
            :active-value="true"
            :inactive-value="false"
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handlebuyerInfoSwitch"
          ></el-switch>
        </div>
      </div>
    </div>
    <!--支付设置 -->
    <div
      class="mt-30 bg-f pl-50 pr-50 pt-30 pb-50"
      v-if="type == 2"
      style="minheight: 765px"
    >
      <div
        class="main mt-20"
        v-if="!isHotel && !isDealerUser && !isCityPartner"
      >
        <div class="main-tit">
          <span class="ml-30">线上支付设置</span>
          <div class="fr mr-50">
            <span>
              <span>微信\支付宝支付:</span>
              <el-link v-if="openOnlinePay" type="success">已开通</el-link>
              <el-link v-else type="danger">未开通</el-link>
            </span>

            <span class="ml-30" >
              <span>大额支付:</span>
              <el-link v-if="openLargePay" type="success">已开通</el-link>
              <el-link v-else type="danger">未开通</el-link>
            </span>
            <el-link type="primary" class="ml-30" :underline="false" @click="$router.push(basePath +'/paymentSetup')">设置</el-link>
          </div>
          <!-- <el-switch
            v-model="paymentSwitch"
            active-text
            inactive-text
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handlePaymentSwitch"
          ></el-switch> -->
        </div>
        <div class="main-con">
          <p class="ml-30">
            开启线上支付在交易和提现时会产生手续费，具体手续费根据选择的支付渠道有关。
          </p>
        </div>
      </div>

      <div
        class="main mt-20"
        v-if="!isHotel && !isDealerUser && !isCityPartner"
      >
        <div class="main-tit">
          <span class="ml-30">线上预付款设置</span>
          <div class="fr mr-50">
            <span>
              <span>线上预付款:</span>
              <el-link v-if="openOnlineCharge" type="success">已开通</el-link>
              <el-link v-else type="danger">未开通</el-link>
            </span>
            <el-link type="primary" class="ml-30" :underline="false" @click="$router.push(basePath +'/prePaymentSetup')">设置</el-link>
          </div>
        </div>
        <div class="main-con">
          <p class="ml-30">
            开启授信线上还款在交易时会产生手续费，具体手续费跟据选择的支付渠道有关。线上打款为无订单支付，交易记录，需要在资产—授信线上交易记录中查询。
          </p>
        </div>
      </div>
      <!-- 开通货到付款，针对没有开通k3的用户 -->
      <div
        class="main mt-20"
        v-if="
          !this.form.k3SalesCompanyNo &&
          !isHotel &&
          !isDealerUser &&
          !isCityPartner
        "
      >
        <div class="main-tit">
          <span class="ml-30">货到付款设置</span>
          <el-switch
            v-model="openCod"
            active-text
            inactive-text
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handlePaymentSwitch"
          ></el-switch>
        </div>
      </div>

      <div class="main mt-20">
        <div class="main-tit">
          <span class="ml-30">账期支付订单代支付</span>
          <el-switch
            v-model="hotelPaymentOrderSwitch"
            active-text
            inactive-text
            :active-value="true"
            :inactive-value="false"
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handleHotelPaymentOrderSwitch"
          ></el-switch>
        </div>
        <div class="main-con">
          <p class="ml-30">
            开启账期支付订单代支付，支持账期支付的顾客在云商/云销下单，只可提交订单，无法直接进行账期支付。
          </p>
        </div>
      </div>
      <!-- 信用特批设置 -->
      <div class="main mt-20" v-if="isChildCompany || isParentCompany">
        <div class="main-tit">
          <span class="ml-30">信用特批设置</span>
          <el-button
            type="text"
            class="fr mr-30 mt-10"
            @click="dialogCreditVisible = true"
            >{{ creditForm.id ? "修改" : "设置" }}</el-button
          >
        </div>
        <div class="main-con">
          <p class="ml-30">
            信用特批设置，可以在代客付款时使得某些因为信用控制而不能进行的业务流程，在特批范围内得以例外处理，完成业务流程。
          </p>
        </div>
      </div>
      <el-dialog
        title="信用特批设置"
        :visible.sync="dialogCreditVisible"
        class="chooseGoods"
      >
        <div class="clearfix">
          <el-form
            :inline="true"
            class="fl goodsForm"
            label-width="120px"
            :rules="rulesCredit"
            :model="cloneCreditForm"
            ref="cloneCreditForm"
          >
            <!--去除chrome浏览器自动填充账号密码-->
            <el-form-item
              style="width: 0px; height: 1px; overflow: hidden; margin: 0"
            >
              <el-input
                type="text"
                v-model.trim="cloneCreditForm.code0"
                :maxlength="6"
              ></el-input>
            </el-form-item>
            <el-form-item
              style="width: 0px; height: 1px; overflow: hidden; margin: 0"
            >
              <el-input
                type="password"
                v-model="cloneCreditForm.pass0"
                :maxlength="16"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="特批人"
              prop="specialPerson"
              style="margin-left: -8px"
            >
              <el-input
                auto-complete="off"
                :maxlength="10"
                v-model.trim="cloneCreditForm.specialPerson"
              ></el-input>
            </el-form-item>
            <el-form-item label="特批密码" prop="specialPassword">
              <el-input
                auto-complete="off"
                :minlength="6"
                :maxlength="16"
                type="password"
                v-model.trim="cloneCreditForm.specialPassword"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="specialPasswordConfirm">
              <el-input
                auto-complete="off"
                :minlength="6"
                :maxlength="16"
                type="password"
                v-model.trim="cloneCreditForm.specialPasswordConfirm"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="saveCredit">确 定</el-button>
          <el-button @click="dialogCreditVisible = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="mt-30 bg-f pl-50 pr-50 pt-30 pb-50" v-if="type == 0">
      <!--
            <div class="main mt-20" v-if="!isHotel">
              <div class="main-tit">
                <span class="ml-30">订单审核</span>
                <el-switch
                  v-model="value2"
                  active-text
                  inactive-text
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="orderCheck"
                ></el-switch>
              </div>
              <div class="main-con">
                <p class="ml-30">订单审核适合多角色进行管理订单。开启订单审核后，每修改订单提交后，都需要审核通过才能生效。</p>
              </div>
            </div>
            <div class="main mt-20" v-if="!isHotel  && !isDealerUser">
              <div class="main-tit">
                <span class="ml-30">订单自动取消</span>
                <div class="fr mr-50">
                  <span>{{form.newTime}}小时</span>
                  <el-button type="text" class="width-28 ml-20" @click="setTimeBtn">设置</el-button>
                </div>
              </div>
              <div class="main-con">
                <p class="ml-30">下单未付款，若超过设置时间，则自动取消订单。</p>
              </div>
            </div>-->
      <!-- <el-dialog title="设置订单有效时间" :visible.sync="dialogTableVisible">
              <el-form ref="time" :model="form">
                <el-form-item
                  label="订单有效时间"
                  class="ml-60 mt-30"
                  :label-width="formLabelWidth"
                  prop="time"
                  :rules="[
            {required: true, pattern: /^[1-9]{1,2}$/ , message: '请输入正整数（最多输入两位）'}
          ]"
                >
                  <el-input v-model.trim="form.time" :maxlength="3"></el-input>
                  <span>小时</span>
                </el-form-item>
              </el-form>

              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="setOrderTime()">确 定</el-button>
                <el-button @click="dialogTableVisible = false;message=''">取 消</el-button>
              </div>
            </el-dialog>-->
      <!-- <div class="main mt-20" v-if="!isHotel  && !isDealerUser">
              <div class="main-tit">
                <span class="ml-30">订单运费</span>
                <div class="fr mr-50">
                  <span>{{form.newFee}}元</span>
                  <el-button type="text" class="width-28 ml-20" @click="setFeeBtn">设置</el-button>
                </div>
              </div>
            </div>-->
      <!-- 线上 支付设置 -->
      <!-- <div class="main mt-20" v-if="!isHotel  && !isDealerUser">
              <div class="main-tit">
                <span class="ml-30">线上支付设置</span>
                <el-switch
                  v-model="paymentSwitch"
                  active-text
                  inactive-text
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="handlePaymentSwitch"
                ></el-switch>
              </div>
              <div class="main-con">
                <p class="ml-30">开启线上支付在交易和提现时会产生手续费，具体手续费根据选择的支付渠道有关。</p>
              </div>
            </div>-->

      <!-- 账期支付和货到付款模式下验证码管理 -->
      <!-- <div class="main mt-20">
              <div class="main-tit">
                <span class="ml-30">账期支付和货到付款模式下验证码管理，开启后需要发送手机验证码</span>
                <el-switch
                  v-model="buyerInfoSwitch"
                  active-text
                  inactive-text
                  :active-value="true"
                  :inactive-value="false"
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="handlebuyerInfoSwitch"
                ></el-switch>
              </div>
            </div>-->
      <!-- <el-dialog title="设置订单运费" :visible.sync="dialogFormVisible">
              <el-form :model="form" ref="fee">
                <el-form-item
                  label="订单运费："
                  class="ml-60 mt-30"
                  :label-width="formLabelWidth"
                  prop="fee"
                  :rules="[
            { required: true, message: '请输入订单运费', trigger: 'blur' },
            {required: true, pattern:/^([+]?\d{1,10})(\.|\.\d{1,2})?$/ , message: '请输入正确的运费（整数不超过10位，小数不超过2位）' }
          ]"
                >
                  <el-input v-model.trim="form.fee" :maxlength="13"></el-input>
                  <span>元</span>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="setFee()">确 定</el-button>
                <el-button @click="dialogFormVisible = false">取 消</el-button>
              </div>
            </el-dialog>-->

      <!-- <div class="main mt-20" v-if="!isHotel && !isDealerUser">
              <div class="main-tit">
                <span class="ml-30">开通JDE</span>
                <div class="fr mr-50">
                  <span class="data mr-50">JDE公司编码 {{form.newJdeNum}}</span>
                  <span class="data">JDE库存 {{form.newJdeStock}}</span>
                  <el-button
                    v-if="!form.newJdeNum"
                    type="text"
                    class="width-28 ml-20"
                    @click="setJdeBtn"
                  >设置</el-button>
                  <span v-else class="ml-20 color-blue">已开通</span>
                </div>
              </div>
            </div>-->
      <!-- <el-dialog title="设置JDE" :visible.sync="dialogJDEVisible">
              <el-form :model="form" ref="JDE" :rules="rules" label-width="170px">
                <el-form-item label="JDE公司编码：" prop="jdeNum">
                  <el-input v-model.trim="form.jdeNum" :maxlength="6"></el-input>
                </el-form-item>

                <el-form-item label="JDE库存：" prop="jdeStock">
                  <el-input v-model.trim="form.jdeStock" :maxlength="13"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="setJde()">确 定</el-button>
                <el-button @click="dialogJDEVisible = false">取 消</el-button>
              </div>
            </el-dialog> -->

      <el-dialog
        title="设置K3"
        :visible.sync="dialogK3Visible"
        class="dialogK3"
      >
        <el-form :model="formK3" ref="K3" label-width="170px" :rules="rules">
          <el-form-item label="销售组织号：" prop="k3SalesCompanyNo">
            <el-input
              v-model.trim="formK3.k3SalesCompanyNo"
              :maxlength="32"
              :disabled="dis"
            ></el-input>
          </el-form-item>
          <el-form-item label="库存组织号：" prop="k3WarehouseCompanyNo">
            <el-input
              v-model.trim="formK3.k3WarehouseCompanyNo"
              :maxlength="32"
              :disabled="dis"
            ></el-input>
          </el-form-item>
          <!-- 仓库号 -->
          <!-- 仓库单独拿出来 放在K3仓库设置-->
          <!-- <div class='k3warehouse'>
                      <i>*</i>
                      <el-input class="k3WarehouseName" v-model.trim="formK3.k3WarehouseName" :maxlength="10"></el-input>
                      <el-input class="k3WarehouseNo" v-model.trim="formK3.k3WarehouseNo" :maxlength="100"></el-input>
                      <el-button type="text" class="addk3WarehouseBtn" @click="addk3house">新增</el-button>
                      <span v-if="!/^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(formK3.k3WarehouseName)" class="k3WarehouseNameError">3-10汉字,字母,数字</span>
                      <span v-if="!formK3.k3WarehouseNo" class="k3WarehouseNoNameError">请输入K3仓库号</span>
                    </div>
                    <div class="childk3warehouse" v-if="formK3.childWarehouse.length>0" v-for="(item, index) in formK3.childWarehouse" :key="index">
                      <el-input class="k3WarehouseName" v-model.trim="item.name" :maxlength="10"></el-input>
                      <el-input class="k3WarehouseNo" v-model.trim="item.value" :maxlength="100"></el-input>
                      <el-button type="text" class="addk3WarehouseBtn" @click="deletek3house(item)">删除</el-button>
                      <span v-if="!/^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(item.name)" class="k3WarehouseNameError">3-10汉字,字母,数字</span>
                      <span v-if="!item.value" class="k3WarehouseNoNameError">请输入K3仓库号</span>
                    </div>-->
          <el-form-item label="供应商编码：" prop="providerCode">
            <el-input
              v-model.trim="formK3.providerCode"
              :maxlength="32"
              :disabled="dis"
            ></el-input>
          </el-form-item>
          <el-form-item label="超团仓库号：" prop="k3SuperteamWarehouseNo">
            <el-input
              v-model.trim="formK3.k3SuperteamWarehouseNo"
              :maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="久集仓库号：" prop="k3SupercWarehouseNo">
            <el-input
              v-model.trim="formK3.k3SupercWarehouseNo"
              :maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="经过WMS：" prop="k3OpenWms">
            <el-checkbox
              v-model="formK3.k3OpenWms"
              @change="k3OpenWmsChange"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
          </el-form-item>
          <el-form-item label=" " class="mb-0">默认客户属性</el-form-item>
          <el-form-item label="客户类别：">
            <el-input v-model.trim="formK3.k3DefaultCustomerType"></el-input>
          </el-form-item>
          <el-form-item label="业务员：" prop="k3DefaultManagerName">
            <el-input v-model.trim="formK3.k3DefaultManagerName"></el-input>
          </el-form-item>
          <el-form-item label="部门：" prop="k3DefaultDepartmentName">
            <el-input v-model.trim="formK3.k3DefaultDepartmentName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="云商客户标记：">
            <el-input
              v-model.trim="formK3.k3DefaultCustomerFlagName"
            ></el-input>
          </el-form-item> -->
          <el-form-item label=" " class="mb-0">出库单配置</el-form-item>
          <el-form-item label="主商品赠品关系：" prop="outStockGiftSplit">
            <el-radio
              class="radio"
              v-model="formK3.outStockGiftSplit"
              :label="1"
              >拆分&nbsp;&nbsp;&nbsp;
            </el-radio>
            <el-radio
              class="radio"
              v-model="formK3.outStockGiftSplit"
              :label="0"
              >不拆分</el-radio
            >
            <p class="color-9">
              温馨提示：开启分批发货卖家分配提货时，分批发货订单主商品赠品不拆分；
            </p>
          </el-form-item>
          <el-form-item label="出库单商品数量：" prop="outStockPagesize">
            <el-select v-model="formK3.outStockPagesize" placeholder="请选择">
              <el-option
                v-for="items in outStockPagesizeList"
                :key="items.value"
                :label="items.label"
                :value="items.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" " class="mb-0">收款单配置</el-form-item>
          <!-- <el-form-item label="结算方式：" prop="k3receiptSettleType">
            <el-input v-model.trim="formK3.k3receiptSettleType"></el-input>
          </el-form-item>
          <el-form-item label="凭证字：" prop="k3receiptMoneyCertificate">
            <el-input
              v-model.trim="formK3.k3receiptMoneyCertificate"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="订单支持拆分发货：" v-if="!formK3.k3OpenWms">
            <el-switch
              v-model="formK3.deliverySeparate"
              active-text
              inactive-text
              active-color="#13ce66"
              class="fr"
              style="margin-right: 90px"
            ></el-switch>
          </el-form-item>
          <!-- <el-form-item v-if="formK3.deliverySeparate" label="赠品提货规则">
            <el-radio class="radio" v-model="formK3.pickupType" :label="1"
              >买家自主提货&nbsp;&nbsp;&nbsp;
            </el-radio>
            <el-radio class="radio" v-model="formK3.pickupType" :label="2"
              >卖家分配提货</el-radio
            >
          </el-form-item> -->
          <el-form-item label="供应商商品直发：">
            <el-switch
              v-model="formK3.openStraight"
              active-text
              inactive-text
              active-color="#13ce66"
              class="fr"
              style="margin-right: 90px"
            ></el-switch>
          </el-form-item>
          <!-- <el-form-item label="退货通知单状态：" prop="outStockGiftSplit">
            <el-radio
              class="radio"
              v-model="formK3.salesBackInformStatus"
              :label="0"
              >创建&nbsp;&nbsp;&nbsp;
            </el-radio>
            <el-radio
              class="radio"
              v-model="formK3.salesBackInformStatus"
              :label="1"
              >已审核</el-radio
            >
          </el-form-item>
          <el-form-item label="销售退货单状态：" prop="outStockGiftSplit">
            <el-radio
              class="radio"
              v-model="formK3.salesBackOrderStatus"
              :label="0"
              >创建&nbsp;&nbsp;&nbsp;
            </el-radio>
            <el-radio
              class="radio"
              v-model="formK3.salesBackOrderStatus"
              :label="1"
              >已审核</el-radio
            >
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-if="!form.k3SalesCompanyNo"
            type="primary"
            @click="setK3()"
            >确 定</el-button
          >
          <el-button v-else type="primary" @click="upDateK3()">确 定</el-button>
          <el-button @click="dialogK3Visible = false">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 绑定设备设置 -->
      <div class="main mt-20" v-if="!isHotel">
        <div class="main-tit">
          <span class="ml-30">绑定手机</span>
          <el-switch
            v-model="bindPhoneSwitch"
            active-text
            inactive-text
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handleBindSwitch"
          ></el-switch>
        </div>
        <div class="main-con">
          <p class="ml-30">
            开启后云销APP将绑定手机设备码，1个账户对应1台手机，无法单账号多设备登录
          </p>
        </div>
      </div>
      <!-- 商品均按瓶计价 -->
      <!-- <div class="main mt-20" v-if="!isHotel && !isDealerUser">
              <div class="main-tit">
                <span class="ml-30">所有商品均按瓶设价，按瓶价展示给顾客</span>
                <el-switch
                  v-model="priceUnit"
                  active-text
                  inactive-text
                  :active-value="1"
                  :inactive-value="2"
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="handlePaymentSwitch"
                ></el-switch>
              </div>
            </div>-->
      <!-- 开通货到付款，针对没有开通k3的用户 -->
      <!-- <div class="main mt-20" v-if="!this.form.k3SalesCompanyNo&&!isHotel && !isDealerUser">
              <div class="main-tit">
                <span class="ml-30">货到付款设置</span>
                <el-switch
                  v-model="openCod"
                  active-text
                  inactive-text
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="handlePaymentSwitch"
                ></el-switch>
              </div>
            </div>-->
      <!-- 通用设置增加拜访显示全部客户开关 -->
      <div class="main mt-20">
        <div class="main-tit">
          <span class="ml-30">商户拜访显示全部客户</span>
          <el-switch
            v-model="isOpenVisit"
            active-text
            inactive-text
            :active-value="true"
            :inactive-value="false"
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handleHotelPaymentOrderSwitchVisit"
          ></el-switch>
        </div>
        <div class="main-con">
          <p class="ml-30">
            开启后台云销App-商户拜访功能拥有所有客户数据权限，可进行跨绑定客户拜访
          </p>
        </div>
      </div>
      <!-- 酒店久集   用于C端用户的订单管理 -->
      <div
        class="main mt-20"
        :class="{ isError: isError }"
        v-if="!isHotel && !isDealerUser && !isCityPartner"
      >
        <div class="main-tit clearfix">
          <span class="ml-30">请输入客服热线</span>
          <el-input
            v-model="tel"
            @change="telChange"
            :maxlength="20"
          ></el-input>
          <span>用于C端用户的订单管理</span>
          <span class="color-blue fr mr-50 cursor" @click="setHotLine"
            >保存</span
          >
        </div>
        <p v-if="isError" class="err-tel">请输入正确的电话号码</p>
      </div>
      <!-- 开通久集设置 -->
      <div
        class="main mt-20"
        v-if="!isHotel && !isDealerUser && !isCityPartner"
      >
        <div class="main-tit">
          <span class="ml-30">开通K3</span>
          <div class="fr mr-50">
            <span class="data mr-30"
              >销售组织号 {{ form.k3SalesCompanyNo }}</span
            >
            <span class="data mr-30"
              >库存组织号 {{ form.k3WarehouseCompanyNo }}</span
            >
            <span class="data mr-30">主仓库号 {{ form.k3WarehouseNo }}</span>
            <span class="data">供应商编码 {{ form.providerCode }}</span>
            <el-button
              v-if="!form.k3SalesCompanyNo"
              type="text"
              class="width-28 ml-20"
              @click="dredgeK3"
              >设置
            </el-button>
            <span v-else class="ml-20 color-blue cursor" @click="dredgeSuper"
              >设置</span
            >
          </div>
        </div>
      </div>
      <!-- 通用设置增加商品折扣返利不显示 -->
      <!-- 3.5.1需求去除 -->
      <!-- <div class="main mt-20" v-if="!isCityPartner">
                <div class="main-tit">
                    <span class="ml-30">商品隐藏折扣返利</span>
                    <el-switch
                            v-model="isOpenShowDiscount"
                            active-text
                            inactive-text
                            :active-value="0"
                            :inactive-value="1"
                            active-color="#13ce66"
                            class="fr mr-50 lh"
                            @change="handleOpenShowDiscount"
                    ></el-switch>
                </div>
                <div class="main-con">
                    <p class="ml-30">开启商品隐藏折扣返利后，云销业务员代下单时，商品不再显示折扣返利</p>
                </div>
            </div> -->

      <div class="main mt-20" v-if="!isDealerUser">
        <div class="main-tit">
          <span class="ml-30">云销代下单手动选择仓库</span>
          <el-switch
            v-model="isOpenSalesMultiWarehouse"
            active-text
            inactive-text
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            class="fr mr-50 lh"
            @change="handleOpenSalesMultiWarehouse"
          ></el-switch>
        </div>
        <div class="main-con">
          <p class="ml-30">
            云销APP业务员代下单时，开关开启的情况下，支持手动选择某个商品的下单仓库
          </p>
        </div>
      </div>
      <!-- 常州业务及部分优化调整-原型20180914 -->
      <!-- 订单短信 -->
      <!-- <div class="main mt-20" v-if="!isHotel">
              <div class="main-tit">
                <span class="ml-30">订单短信</span>
                <el-switch
                  v-model="orderMessageSwitch"
                  active-text
                  inactive-text
                  :active-value="true"
                  :inactive-value="false"
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="handleOrderMessageSwitch"
                ></el-switch>
              </div>
              <div class="main-con">
                <p class="ml-30">开启订单短信，顾客在下单时将收到订单提醒短信。</p>
              </div>
            </div>-->
      <!-- 酒店客户订单代支付 -->
      <!-- <div class="main mt-20">
              <div class="main-tit">
                <span class="ml-30">账期支付订单代支付</span>
                <el-switch
                  v-model="hotelPaymentOrderSwitch"
                  active-text
                  inactive-text
                  :active-value="true"
                  :inactive-value="false"
                  active-color="#13ce66"
                  class="fr mr-50 lh"
                  @change="handleHotelPaymentOrderSwitch"
                ></el-switch>
              </div>
              <div class="main-con">
                <p class="ml-30">开启账期支付订单代支付，支持账期支付的顾客在云商/云销下单，只可提交订单，无法直接进行账期支付。</p>
              </div>
            </div>-->
      <!-- End -->
      <!-- 商品价格同步K3 -->
      <!-- <div class="main mt-20" >
                      <div class="main-tit">
                          <span class="ml-30">商品价格同步K3</span>
                          <div class="fr mr-50">
                              <el-button type="text" class="width-28 ml-20" @click="turnOnK3">开通</el-button>
                          </div>
                      </div>
                      <div class="main-con">
                          <p class="ml-30">开启商品价格同步K3，顾客在云商下单的商品价格以K3为准（此功能开启后，将不可关闭）</p>
                      </div>
            </div>-->
    </div>
  </div>
</template>
<script>
import { axiosApi, basePath } from "../../api/api";
import md5 from "md5";
export default {
  data() {
    var checkName = (rule, value, callback) => {
      const re = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,10}$/gi;
      if (!re.test(value)) {
        callback(new Error("请输入特批人名称"));
      } else {
        callback();
      }
    };
    var checkPassWord = (rule, value, callback) => {
      const reg = /^[^\u4e00-\u9fa5]{6,16}$/gi;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的密码"));
      } else {
        if (this.form.specialPasswordConfirm !== "") {
          this.$refs.cloneCreditForm.validateField("specialPasswordConfirm");
        }
        callback();
      }
    };
    var checkRepeatPassWord = (rule, value, callback) => {
      const reg = /^[^\u4e00-\u9fa5]{6,16}$/gi;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的密码"));
      } else if (value !== this.cloneCreditForm.specialPassword) {
        callback(new Error("两次输入的密码不同"));
      } else {
        callback();
      }
    };
    return {
      outStockPagesizeList: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
        { value: 100, label: "不限制" },
      ],
      isOpenShowDiscount: 1, //是否开启显示折扣返利
      isOpenSalesMultiWarehouse: 1, //是否开启多仓库
      dialogCreditVisible: false,
      creditForm: {
        id: "",
        specialPerson: "",
        specialPassword: "",
        specialPasswordConfirm: "",
      }, //信用特批
      cloneCreditForm: {
        id: "",
        specialPerson: "",
        specialPassword: "",
        specialPasswordConfirm: "",
      }, //副本
      rulesCredit: {
        specialPerson: [
          { required: true, message: "请输入特批人名称", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
        specialPassword: [
          { required: true, message: "请输入特批密码" },
          { validator: checkPassWord, trigger: "blur" },
        ],
        specialPasswordConfirm: [
          { required: true, message: "请输入特批密码" },
          { validator: checkRepeatPassWord, trigger: "blur" },
        ],
      },
      isOpenVisit: false, //商户拜访
      identity: "", //超团登陆身份
      value2: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogJDEVisible: false,
      dialogK3Visible: false,
      formLabelWidth: "120px",
      form: {
        time: null,
        newTime: 0,
        fee: null,
        newFee: 0,
        jdeNum: null,
        newJdeNum: null,
        jdeStock: null,
        newJdeStock: null,
        k3SalesCompanyNo: null,
        k3WarehouseCompanyNo: null,
        k3WarehouseNo: null,
        providerCode: null,
        k3SupercWarehouseNo: "", //久集仓库号
        k3SuperteamWarehouseNo: "", //超团仓库号
        // pickupType: 1,
      },
      formJDE: {
        traderId: sessionStorage.getItem("traderId"),
        jdeWarehouseNo: null,
        jdeCompanyNo: null,
      },
      formK3: {
        outStockGiftSplit: 1,
        // salesBackOrderStatus: 1, //销售退货单状态
        // salesBackInformStatus: 1, //退货通知单状态
        outStockPagesize: 5,
        traderId: sessionStorage.getItem("traderId"),
        k3SalesCompanyNo: null,
        k3WarehouseCompanyNo: null,
        k3WarehouseNo: null,
        providerCode: null,
        k3SupercWarehouseNo: "", //久集仓库号
        k3SuperteamWarehouseNo: "", //超团仓库号
        k3DefaultCustomerType: null, // k3默认客户类型
        // : null, // k3默认业务名
        // k3DefaultDepartmentName: null, // k3默认部门名称
        // k3DefaultCustomerFlagName: null, // 云商客户添加标记名
        // k3receiptSettleType: null, // k3收款-决算方式
        // k3receiptMoneyCertificate: null, // 凭证字
        k3OpenWms: 0, // 是否经过 WMS
        deliverySeparate: false, // 是否支持分批发货
        openStraight: false, // 是否供应商商品直发
        // 主仓库,子仓库
        k3WarehouseName: "仓库号",
        k3WarehouseNo: null,
        childWarehouse: [], // 子仓库列表
        // pickupType: 1,
      },
      setformK3List: [
        {
          // K3仓库设置
          k3SalesCompanyNo: "",
          k3WarehouseName: "仓库号",
          k3WarehouseNo: "",
          childWarehouse: [], // 子仓库列表
        },
      ],
      rules: {
        jdeNum: [
          {
            required: true,
            pattern: /^\d{5}$/,
            message: "请输入正确的公司编码（5位数字）",
          },
        ],
        jdeStock: [
          {
            required: true,
            pattern: /^\d{1,12}$/,
            message: "请输入正确的JDE库存（1~12位数字）",
          },
        ],
        k3SalesCompanyNo: [
          {
            required: true,
            max: 32,
            message: "请输入正确的销售组织号（1-32位字符）",
          },
        ],
        k3WarehouseCompanyNo: [
          {
            required: true,
            max: 32,
            message: "请输入正确的K3库存组织号（1~32位字符）",
          },
        ],
        k3WarehouseNo: [
          {
            required: true,
            max: 32,
            message: "请输入正确的K3仓库（1~32位字符）",
          },
        ],
        providerCode: [
          {
            required: true,
            max: 32,
            message: "请输入正确的供应商编码（1~32位字符）",
          },
        ],
        k3SuperteamWarehouseNo: [
          {
            max: 32,
            message: "请输入正确的超团仓库号（1~32位字符）",
          },
        ],
        k3SupercWarehouseNo: [
          {
            max: 32,
            message: "请输入正确的久集仓库号（1~32位字符）",
          },
        ],
        // k3DefaultManagerName: [{required: true, message: '输入业务员名称' }],
        // k3DefaultDepartmentName: [{required: true, message: '输入部门名称' }],
        k3receiptSettleType: [
          {
            required: true,
            message: "请输入结算方式",
          },
        ],
        k3receiptMoneyCertificate: [
          {
            required: true,
            message: "请输入凭证字",
          },
        ],
      },
      paymentSwitch: 1,
      buyerInfoSwitch: true, // 控制短信开关
      bindPhoneSwitch: false,
      loginName: sessionStorage.getItem("loginName"),
      dis: false,
      priceUnit: 1,
      openCod: false, // 是否开通货到付款
      isHotel: false, // 是否是酒店用户
      tel: "", //酒店久集电话号码
      isError: false, //酒店久集电话号码验证
      //常州业务及部分优化调整-原型20180914
      orderMessageSwitch: false, // 订单短信
      hotelPaymentOrderSwitch: false, // 酒店客户订单代支付
      dataTabs: ["通用设置", "短信设置", "支付设置"],
      type: "0",
      k3WarehouseInfo: [], //K3仓库信息
      // isDealerUser:sessionStorage.getItem('isDealerUser') == 'true' ? true : false,//是否是经销商



      openOnlinePay: false, // 微信支付宝
      openLargePay: false,  // 大额支付
      openOnlineCharge: false, // 线上预付款
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loading();
      // this.isShow(); //超团登录信息查询
      this.changeCredit();
    });
  },
  computed: {
    isDealerUser() {
      return this.$store.state.clientType.isDealerUser;
    },
    isChildCompany() {
      return this.$store.state.clientType.isChildCompany;
    },
    isParentCompany() {
      return this.$store.state.clientType.isParentCompany;
    },
    isCityPartner() {
      this.dataTabs = ["通用设置", "短信设置", "支付设置"];
      return this.$store.state.clientType.isCityPartner;
    },
  },
  watch: {
    dialogCreditVisible(val) {
      if (!val) {
        this.cloneCreditForm = {
          id: "",
          specialPerson: "",
          specialPassword: "",
          specialPasswordConfirm: "",
        };
        this.$refs.cloneCreditForm.resetFields();
      } else {
        this.cloneCreditForm.specialPerson = JSON.parse(
          JSON.stringify(this.creditForm)
        ).specialPerson;
        this.cloneCreditForm.id = JSON.parse(
          JSON.stringify(this.creditForm)
        ).id;
      }
      // this.$nextTick(() => {
      // 	this.$refs.cloneCreditForm.resetFields()
      // })
    },
  },
  methods: {
    //开启云销代下单手动选择仓库
    handleOpenSalesMultiWarehouse(val) {
      axiosApi(
        "/trader/sellerIsOpenSalesMultiWarehouse/update",
        {
          isOpenSalesMultiWarehouse: val == 1 ? true : false,
          traderId: sessionStorage.getItem("traderId"),
        },
        (res) => {
          this.$message.success("设置成功");
        },
        1,
        10,
        (err) => {
          this.$message.warning(err.data.message);
          this.isOpenSalesMultiWarehouse = !val;
        }
      );
    },
    //开启显示折扣返利
    // 3.5.1需求去除
    // handleOpenShowDiscount(val){
    // 	axiosApi('/trader/sellerIsOpenShowDiscount/update',{ isOpenShowDiscount:val,traderId:sessionStorage.getItem('traderId') },res => {
    // 		this.$message.success('设置成功')
    //           },1,10,err => {
    // 		this.$message.warning(err.data.message);
    // 		this.isOpenShowDiscount = !val;
    //           })
    //       },
    //信用特批
    changeCredit() {
      axiosApi(
        "/trader/sellerSpecialCredit/select",
        { isSetNull: true },
        (res) => {
          console.log(res, "res");
          if (res.data.data) {
            this.creditForm.id = res.data.data.id;
            this.creditForm.specialPerson = res.data.data.remark;
          }
        },
        1,
        10,
        (err) => {
          this.$message.warning(err.data.message);
        }
      );
      // this.dialogCreditVisible = true;
    },
    //保存信用特批设置
    saveCredit() {
      let body = JSON.parse(JSON.stringify(this.cloneCreditForm));
      delete body.code0;
      delete body.pass0;
      body.specialPassword = md5(body.specialPassword);
      body.specialPasswordConfirm = md5(body.specialPasswordConfirm);
      this.$refs.cloneCreditForm.validate((valid) => {
        if (valid) {
          axiosApi(
            "/trader/sellerSpecialCredit/update",
            { ...body, traderId: sessionStorage.getItem("traderId") },
            (res) => {
              console.log(res, "成功");
              this.$message.success("设置成功");
              this.dialogCreditVisible = false;
              this.changeCredit();
            },
            1,
            10,
            (err) => {
              this.$message.warning(err.data.message);
            }
          );
        }
      });
    },
    setk3warehouseConfirm() {
      // K3仓库设置 确定
      let body = JSON.parse(JSON.stringify(this.formK3));
      delete body.childWarehouse;
      delete body.k3WarehouseName;
      delete body.k3WarehouseNo;
      // 仓库名称，仓库号通过，新增子仓库名称，子仓库号通过
      // let a= /^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(this.formK3.k3WarehouseName),
      //     b = !!this.formK3.k3WarehouseNo,
      //     c = true
      let a = true,
        b = true,
        c = true,
        d = true;
      this.setformK3List.forEach((v) => {
        if (!v.k3SalesCompanyNo) a = false; //销售组织号为空
        if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(v.k3WarehouseName))
          b = false; // 主仓库名称
        if (!v.k3WarehouseNo) c = false; // 主仓库编号
        for (let i = 0; i < v.childWarehouse.length; i++) {
          // 子仓库验证判断
          let item = v.childWarehouse[i];
          if (item) {
            if (
              !/^[\u4e00-\u9fa5a-zA-Z0-9]{3,10}$/.test(item.name) ||
              !item.no
            ) {
              d = false;
              break;
            }
          }
        }
      });
      this.setformK3List.forEach((v) => {
        let nameArr = [v.k3WarehouseName],
          noArr = [v.k3WarehouseNo],
          newNameArr = [],
          newNoArr = [];
        if (v.childWarehouse.length > 0) {
          v.childWarehouse.forEach((it) => {
            nameArr.push(it.name);
            noArr.push(it.no);
          });
        }
        newNameArr = Array.from(new Set(nameArr));
        newNoArr = Array.from(new Set(noArr));
        if (newNameArr.length != nameArr.length) {
          this.$message.warning("仓库名称不能相同");
          return false;
        }
        if (newNoArr.length != noArr.length) {
          this.$message.warning("仓库编号不能相同");
          return false;
        }
      });
      if (a && b && c && d) {
        // form表单验证通过
        body.k3WarehouseInfo = [];
        this.setformK3List.forEach((v) => {
          body.k3WarehouseInfo.push({
            name: v.k3WarehouseName || v.name,
            no: v.k3WarehouseNo || v.no,
            companyNo: v.k3SalesCompanyNo || v.companyNo,
            isMain: v.isMain || true,
            isMainCompany: v.isMainCompany || false,
          });
          if (v.childWarehouse.length > 0) {
            v.childWarehouse.forEach((it) => {
              body.k3WarehouseInfo.push({
                name: it.name,
                no: it.no,
                isMain: false,
                companyNo: v.k3SalesCompanyNo || v.companyNo,
                isMainCompany: v.isMainCompany,
              });
            });
          }
        });
        body.k3WarehouseInfo = JSON.parse(JSON.stringify(body.k3WarehouseInfo));
        body.k3WarehouseInfo.forEach((v) => {
          delete v.childWarehouse;
          delete v.k3WarehouseName;
          delete v.k3WarehouseNo;
        });
        const api = "/trader/sellerK3SuperHouseNo/update";
        // if(this.formK3.k3SupercWarehouseNo.length == 0 && this.formK3.k3SuperteamWarehouseNo.length == 0) {
        //   this.$message.info('超团仓库号和久集仓库号至少输入一项');
        // } else {
        axiosApi(
          api,
          body,
          (res) => {
            this.getOrderconfig();
            this.dialogK3Visible = false;
            this.$message.success("开通成功！");
          },
          1,
          10,
          (res) => {
            this.$message.info(res.data.message);
            this.dialogK3Visible = false;
          }
        );
      } else {
        return false;
      }
    },
    addGroup() {
      // 添加组织
      this.setformK3List.push({
        k3SalesCompanyNo: "",
        k3WarehouseName: "仓库号",
        k3WarehouseNo: "",
        childWarehouse: [], // 子仓库列表
      });
    },
    deleteCompany(n, i) {
      this.setformK3List.splice(i, 1);
    },
    addGroupk3house(i) {
      // 新增子仓库
      if (this.setformK3List[i].childWarehouse.length >= 3) {
        this.$message.warning("最多添加3个仓库");
        return false;
      }
      this.setformK3List[i].childWarehouse.push({
        name: "仓库" + (this.setformK3List[i].childWarehouse.length + 1) + "号",
        no: "",
      });
    },
    deleteGroupk3house(item, i) {
      // 删除子仓库
      const index = this.setformK3List[i].childWarehouse.indexOf(item);
      if (index !== -1) {
        this.setformK3List[i].childWarehouse.splice(index, 1);
      }
    },
    handleTabs(e) {
      if (e.target.attributes.type) {
        if (this.type == e.target.attributes.type.value) return false;
      }
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      if (this.type == 0) {
        //通用设置
      } else if (this.type == 3) {
        // K3仓库设置
        axiosApi(
          "/trader/k3WarehouseInfo/select",
          {
            traderId: sessionStorage.getItem("traderId"),
          },
          (res) => {
            // 获取 K3仓库 数组
            // this.k3WarehouseInfo
            // this.setformK3.list
            let data = res.data.data;
            if (data) {
              this.setformK3List = [
                {
                  k3SalesCompanyNo: "",
                  k3WarehouseName: "仓库号",
                  k3WarehouseNo: "",
                  childWarehouse: [], // 子仓库列表
                },
              ];
              if (Object.keys(data).length == 0 && this.form.k3SalesCompanyNo) {
                this.setformK3List = [
                  {
                    k3SalesCompanyNo: this.form.k3SalesCompanyNo,
                    k3WarehouseName: "仓库号",
                    k3WarehouseNo: "",
                    isMainCompany: true,
                    childWarehouse: [], // 子仓库列表
                  },
                ];
              }

              Object.keys(data).forEach((item, index) => {
                if (index > 0) this.addGroup();
              });
              let a = 0;
              for (let i in data) {
                this.setformK3List[a].k3SalesCompanyNo = i;
                data[i].forEach((v) => {
                  if (v.isMain) {
                    this.setformK3List[a].k3WarehouseName = v.name;
                    this.setformK3List[a].k3WarehouseNo = v.no;
                    this.setformK3List[a] = Object.assign(
                      this.setformK3List[a],
                      v
                    );
                  } else {
                    this.setformK3List[a].childWarehouse.push(v);
                  }
                });
                a++;
              }
              this.setformK3List.sort((a, b) => {
                return a.isMainCompany != b.isMainCompany
                  ? a.isMainCompany == true
                    ? -1
                    : 1
                  : 0;
              });
            }
          },
          1,
          10,
          (err) => {
            this.$message.error(err.data.message);
          }
        );
      }
      this.currentPage = 1;
      setTimeout(() => {
        this.loading();
      }, 0);
    },
    handleOrderMessageSwitch() {
      // 订单消息开关
      setTimeout(() => {
        axiosApi(
          "/trader/sellerIsSendSms/update",
          {
            isSendOrderSms: this.orderMessageSwitch,
          },
          (res) => {
            this.$message.success("操作成功");
          },
          1,
          10,
          (res) => {
            this.orderMessageSwitch = !this.orderMessageSwitch;
          }
        );
      }, 0);
    },
    handleHotelPaymentOrderSwitch() {
      // 酒店客户订单代支付
      setTimeout(() => {
        axiosApi(
          "/trader/sellerIsOpenPaymentOnBehalf/update",
          {
            traderId: sessionStorage.getItem("traderId"),
            paymentOnBehalf: this.hotelPaymentOrderSwitch,
          },
          (res) => {
            this.$message.success("操作成功");
          }
        );
      }, 0);
    },
    handleHotelPaymentOrderSwitchVisit() {
      //拜访显示全部客户订单
      setTimeout(() => {
        axiosApi(
          "/trader/sellerIsOpenVisit/update",
          {
            traderId: sessionStorage.getItem("traderId"),
            isOpenVisit: this.isOpenVisit,
          },
          (res) => {
            this.$message.success("操作成功");
          }
        );
      }, 0);
    },
    addk3house() {
      // 新增子仓库
      if (this.formK3.childWarehouse.length >= 3) {
        this.$message.warning("最多添加3个仓库");
        return false;
      }
      this.formK3.childWarehouse.push({
        name: "仓库" + (this.formK3.childWarehouse.length + 1) + "号",
        value: "",
      });
    },
    deletek3house(item) {
      const index = this.formK3.childWarehouse.indexOf(item);
      if (index !== -1) {
        this.formK3.childWarehouse.splice(index, 1);
      }
    },
    k3OpenWmsChange() {
      if (this.formK3.k3OpenWms === 1 && this.formK3.deliverySeparate) {
        this.$message.error("启用WMS的卖家订单不支持拆分发货");
        this.formK3.k3OpenWms = 0;
      }
    },
    /* 线上支付设置，货到付款设置，商品均按瓶计价 开关 */
    handlePaymentSwitch() {
      setTimeout(() => {
        axiosApi(
          "/trader/sellerIsOpenCodOrOpenPriceUnit/update",
          {
            traderId: sessionStorage.getItem("traderId"),
            isOpenOnlinePay: this.paymentSwitch,
            priceUnit: this.priceUnit,
            openCod: this.openCod,
          },
          (res) => {
            this.$message.success("设置成功");
          }
        );
      }, 0);
    },
    /*货到付款和账期支付是否需要设置开关(通用设置)*/
    handlebuyerInfoSwitch() {
      setTimeout((res) => {
        axiosApi(
          "/trader/buyerInfo/update",
          {
            traderId: sessionStorage.getItem("traderId"),
            openPayCode: this.buyerInfoSwitch,
          },
          (res) => {
            this.$message.success("设置成功");
          },
          1,
          100,
          (res) => {
            this.$message.error(res.data.message);
          }
        );
      }, 0);
    },
    /* 绑定手机设置 开关 */
    handleBindSwitch(val) {
      axiosApi(
        "/trader/appdevice/setAllowDeviceFree",
        {
          traderId: sessionStorage.getItem("traderId"),
          allowDeviceFree: val === true ? 0 : 1,
        },
        (res) => {
          this.bindPhoneSwitch = val;
        }
      );
    },
    orderCheck(val) {
      this.value2 = val;
      if (this.value2 === true) {
        axiosApi(
          "/trader/sellerOpenOrder/update ",
          {
            traderId: sessionStorage.getItem("traderId"),
            isOpenOrder: 1,
          },
          function (res) {},
          1,
          100,
          (res) => {
            this.$message({
              title: "失败",
              message: res.data.message,
              type: "info",
            });
          }
        );
      } else {
        axiosApi(
          "/trader/sellerOpenOrder/update ",
          {
            traderId: sessionStorage.getItem("traderId"),
            isOpenOrder: 0,
          },
          function (res) {},
          1,
          100,
          (res) => {
            this.value2 = true;
            this.$message({
              title: "失败",
              message: res.data.message,
              type: "info",
            });
          }
        );
      }
    },
    setOrderTime() {
      this.$refs.time.validate((valid) => {
        if (valid) {
          this.dialogTableVisible = false;
          axiosApi(
            "/trader/updateOrderCancelTime/update ",
            {
              traderId: sessionStorage.getItem("traderId"),
              cancelTime: this.form.time,
            },
            (res) => {
              this.getOrderconfig();
              this.$message({
                title: "成功",
                message: "恭喜你，操作成功！",
                type: "success",
              });
            }
          );
        } else {
          return false;
        }
      });
    },
    setFee() {
      this.$refs.fee.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          axiosApi(
            "/trader/updateOrderFreight/update ",
            {
              traderId: sessionStorage.getItem("traderId"),
              freight: this.form.fee,
            },
            (res) => {
              this.getOrderconfig();
              this.$message({
                title: "成功",
                message: "恭喜你，操作成功！",
                type: "success",
              });
            }
          );
        } else {
          return false;
        }
      });
    },
    loading() {
      //获取商品审核和订单审核的状态  traderId 商户id
      axiosApi(
        "/trader/findSellerJurisdiction/select",
        {
          traderId: sessionStorage.getItem("traderId"),
        },
        (res) => {
          if (res.data.data.isOpenOrder === 0) {
            this.value2 = false;
          } else if (res.data.data.isOpenOrder === 1) {
            this.value2 = true;
          }
          this.paymentSwitch = res.data.data.isOpenOnlinePay;
          this.priceUnit = res.data.data.priceUnit;
          if (res.data.data.allowDeviceFree === 1) {
            this.bindPhoneSwitch = false;
          } else if (res.data.data.allowDeviceFree === 0) {
            this.bindPhoneSwitch = true;
          }
          this.openCod = res.data.data.openCod;
          this.tel = res.data.data.hotline; //酒店久集客服热线
          this.hotelPaymentOrderSwitch = res.data.data.paymentOnBehalf || false; // 赋值是否开启代付款功能
          this.orderMessageSwitch = res.data.data.isSendOrderSms || false; // 赋值是否发送订单短信
          // this.form.pickupType = res.data.data.pickupType;
          this.isOpenShowDiscount = res.data.data.isOpenShowDiscount ? 1 : 0; //是否开启显示折扣返利
          // this.formK3.pickupType = res.data.data.pickupType; //是否支持卖家分配提货
        }
      );
      this.getOrderconfig();
      //              this.getJdeInfo();
    },
    getOrderconfig() {
      //获取订单取消时间和订单费用  traderId 商户id
      axiosApi(
        "/trader/findTraderInfoBySerllerId/select",
        {
          traderId: sessionStorage.getItem("traderId"),
        },
        (res) => {
          const a = res.data.data;
          this.isOpenVisit = res.data.data.isOpenVisit;


          this.openOnlinePay = a.openOnlinePay // 微信支付宝
          this.openLargePay = a.openLargePay // 大额支付
          this.openOnlineCharge = a.openOnlineCharge // 线上预付款

          this.form.newTime = a.cancelTime;
          this.form.newFee = a.freight;
          //取消getJdeInfo 接口
          this.form.newJdeNum = a.jdeCompanyNo;
          this.form.newJdeStock = a.jdeWarehouseNo;
          this.form.k3SalesCompanyNo = a.k3SalesCompanyNo;
          // this.form.salesBackOrderStatus = a.salesBackOrderStatus;
          // this.form.salesBackInformStatus = a.salesBackInformStatus;
          this.form.k3WarehouseCompanyNo = a.k3WarehouseCompanyNo;
          this.isOpenSalesMultiWarehouse = a.isOpenSalesMultiWarehouse ? 1 : 0; //是否多仓库
          this.form.providerCode = a.providerCode;

          this.form.k3WarehouseNo = a.mainK3WarehouseNo; // 主仓库号
          if (a.k3WarehouseInfo) {
            // 判断k3WarehouseInfo是否存在
            let k3WarehouseInfo = JSON.parse(a.k3WarehouseInfo);
            k3WarehouseInfo.forEach((v) => {
              if (v.no == a.mainK3WarehouseNo)
                this.form.k3WarehouseName = v.name; // 主仓库名称
            });
            this.form.childWarehouse = [];
            k3WarehouseInfo.forEach((v) => {
              if (v.no != a.mainK3WarehouseNo) {
                this.form.childWarehouse.push({
                  name: v.name,
                  no: v.no,
                });
              }
            });
          }
          this.form.outStockGiftSplit = a.outStockGiftSplit ? 1 : 0; //主商品和赠品拆分
          this.form.outStockPagesize = a.outStockPagesize; //商品数量
          this.form.k3SupercWarehouseNo = a.k3SupercWarehouseNo; //久集仓库号
          this.form.k3SuperteamWarehouseNo = a.k3SuperteamWarehouseNo; //超团仓库号
          this.form.k3DefaultCustomerType = a.k3DefaultCustomerType; // k3默认客户类型
          // this.form.k3DefaultManagerName = a.k3DefaultManagerName // k3默认业务名
          // this.form.k3DefaultDepartmentName = a.k3DefaultDepartmentName // k3默认部门名称
          // this.form.k3DefaultCustomerFlagName = a.k3DefaultCustomerFlagName; // 云商客户添加标记名
          // this.form.k3receiptSettleType = a.k3receiptSettleType; // k3收款-决算方式
          // this.form.k3receiptMoneyCertificate = a.k3receiptMoneyCertificate; // 凭证字
          this.form.deliverySeparate = a.deliverySeparate; //是否支持分批发货
          this.form.openStraight = a.openStraight; //是否供应商商品直发
          this.buyerInfoSwitch = a.openPayCode; // 账期支付是否开启验证码
          this.isHotel = a.isHotel == 1 ? true : false; // 是否是酒店用户
          if (a.k3SalesCompanyNo) {
            // 存在主组织号
            this.dataTabs = ["通用设置", "短信设置", "支付设置"];
            this.formK3 = Object.assign({}, this.formK3, this.form);
          }
        }
      );
    },
    setTimeBtn() {
      this.dialogTableVisible = true;
      this.form.time = this.form.newTime;
    },
    setFeeBtn() {
      this.dialogFormVisible = true;
      this.form.fee = this.form.newFee;
    },
    setJdeBtn() {
      this.dialogJDEVisible = true;
      this.form.jdeNum = null;
      this.form.jdeStock = null;
      setTimeout(() => {
        this.$set(this.form, "jdeNum", this.form.newJdeNum);
        this.$set(this.form, "jdeStock", this.form.newJdeStock);
      }, 0);
    },
    setJde() {
      this.$refs.JDE.validate((valid) => {
        if (valid) {
          this.formJDE = {
            traderId: sessionStorage.getItem("traderId"),
            jdeWarehouseNo: this.form.jdeStock,
            jdeCompanyNo: this.form.jdeNum,
          };
          if (this.form.k3SalesCompanyNo) {
            //如果已开通K3
            this.$confirm("JDE开通成功后，K3将自动关闭", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.setK3JDECallback("JDE");
              })
              .catch(() => {});
          } else {
            this.setK3JDECallback("JDE");
          }
          this.dialogJDEVisible = false;
        } else {
          return false;
        }
      });
    },
    setK3() {
      this.$refs.K3.validate((valid) => {
        if (valid) {
          if (this.form.newJdeNum) {
            //如果已开通JDE
            this.$confirm("K3开通成功后，JDE将自动关闭", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.setK3JDECallback("K3");
              })
              .catch(() => {});
          } else {
            this.setK3JDECallback("K3");
          }
          this.dialogK3Visible = false;
        } else {
          return false;
        }
      });
    },
    setK3JDECallback(type) {
      const api =
        type === "JDE"
          ? "/trader/sellerIsOpenJde/update"
          : "/trader/sellerIsOpenK3/update";
      let body =
        type === "JDE" ? this.formJDE : JSON.parse(JSON.stringify(this.formK3));
      body.traderId = sessionStorage.getItem("traderId");
      if (type === "JDE") {
        axiosApi(
          api,
          body,
          (res) => {
            this.getOrderconfig();
            this.$message.success("开通成功！");
          },
          1,
          10,
          (res) => {
            this.$message.info(res.data.message);
          }
        );
        return false;
      }
      this.$refs.K3.validate((valid) => {
        if (valid) {
          // form表单验证通过
          body.k3WarehouseInfo = {};
          delete body.k3WarehouseInfo;
          delete body.childWarehouse;
          delete body.k3WarehouseName;
          delete body.k3WarehouseNo;
          axiosApi(
            api,
            body,
            (res) => {
              this.getOrderconfig();
              this.$message.success("开通成功！");
              this.dialogK3Visible = false;
            },
            1,
            10,
            (res) => {
              this.$message.info(res.data.message);
              this.dialogK3Visible = false;
            }
          );
        } else {
          return false;
        }
      });
    },
    upDateK3() {
      let body = JSON.parse(JSON.stringify(this.formK3));
      this.$refs.K3.validate((valid) => {
        if (valid) {
          // form表单验证通过
          delete body.childWarehouse;
          delete body.k3WarehouseName;
          delete body.k3WarehouseNo;
          const api = "/trader/sellerK3SuperHouseNo/update";
          // if(this.formK3.k3SupercWarehouseNo.length == 0 && this.formK3.k3SuperteamWarehouseNo.length == 0) {
          //   this.$message.info('超团仓库号和久集仓库号至少输入一项');
          // } else {
          axiosApi(
            api,
            body,
            (res) => {
              this.getOrderconfig();
              this.dialogK3Visible = false;
              this.$message.success("开通成功！");
            },
            1,
            10,
            (res) => {
              this.$message.info(res.data.message);
              this.dialogK3Visible = false;
              this.loading();
            }
          );
          // }
        } else {
          return false;
        }
      });
    },
    dredgeK3() {
      this.dis = false;
      this.dialogK3Visible = true;
    },
    //          开通超团久集仓库
    dredgeSuper() {
      this.formK3 = Object.assign({}, this.formK3, this.form);
      this.dis = true;
      this.dialogK3Visible = true;
    },
    telChange() {
      let reg = /^[\d\-]+$/;
      if (!reg.test(this.tel) && this.tel) {
        this.isError = true;
      } else if (this.tel == "" || !this.tel) {
        this.isError = false;
      } else {
        this.isError = false;
      }
    },
    setHotLine() {
      if (this.isError) {
        this.$message.info("请输入正确的电话号码！");
      } else {
        axiosApi(
          "/trader/sellerHotline/update",
          {
            traderId: sessionStorage.traderId,
            hotLine: this.tel,
          },
          (res) => {
            this.$message.success("设置成功！");
          },
          1,
          10,
          (res) => {
            this.$message.info(res.data.message);
          }
        );
      }
    },
    //超团登录信息
    isShow() {
      axiosApi(
        "/groupon/sellerType/select",
        {
          id: sessionStorage.traderId,
        },
        (res) => {
          this.identity = res.data.data;
        },
        1,
        10,
        (res) => {
          // this.$message.info(res.data.message);
        }
      );
    },
  },
};
</script>
<style lang="less">
    #generalSet {
        .k3_item_set {
            width: 100%;
            min-height: 210px;
            height: auto;
            border: 1px solid #dddddd;
            margin-bottom: 30px;

            .title-box {
                width: 100%;
                height: 40px;
                background-color: rgb(245, 245, 245);
                display: flex;
                align-items: center;
                padding-left: 30px;
                box-sizing: border-box;
            }

            .k3_item_content {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 30px 60px;
            }
        }

        .addGroup {
            padding-top: 20px;
            /*// border: 1px solid #ddd;*/
            /*// border-top: none;*/
            padding-bottom: 20px;
        }

        .setk3warehouse {
            /*// border-top: 1px solid #ddd;*/
            .setk3warehouse_item {
                padding-top: 20px;
                /*//   border: 1px solid #ddd;*/
                /*//   border-top: none;*/
            }

            .k3WarehouseName {
                width: 140px !important;
                margin-bottom: 20px;
                display: inline-block;
            }

            .childk3warehouse {
                position: relative;
                display: flex;

                .k3WarehouseName {
                    margin-left: 70px;
                    display: inline-block;
                }

                .k3WarehouseNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 33px;
                    left: 69px;
                }

                .k3WarehouseNoNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 33px;
                    left: 225px;
                }

                .el-icon-close {
                    color: #ff4949;
                }
            }

            .k3warehouse {
                position: relative;
                display: flex;
                line-height: 32px;

                i {
                    margin-left: 61px;
                    content: "*";
                    color: #ff4949;
                    margin-right: 4px;
                    font-style: normal;
                }

                .k3WarehouseNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 24px;
                    left: 69px;
                }

                .k3WarehouseNoNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 24px;
                    left: 225px;
                }
            }

            .k3WarehouseNo {
                margin-left: 11px;
                margin-bottom: 20px;
            }

            .addk3WarehouseBtn {
                width: 50px;
            }
        }

        .main {
            border: 1px solid #dddddd;
        }

        .mt-30 {
            position: relative;
        }

        .main-tit {
            height: 50px;
            line-height: 50px;
        }

        .lh {
            margin-top: 15px;
            line-height: 60px;
        }

        .main-con {
            height: 80px;
            line-height: 80px;
            border-top: 1px solid #dddddd;
        }

        .paymentSet {
            line-height: 20px;
            padding-top: 20px;
            box-sizing: border-box;
        }

        .width-28 {
            width: 28px;
        }

        .position {
            position: relative;
            top: 120px;
            left: 40px;
        }

        .el-dialog {
            min-height: 300px;
        }

        .err-tel {
            position: absolute;
            bottom: 0;
            left: 135px;
            color: red;
            font-size: 12px;
        }

        .isError {
            height: 60px;
            position: relative;
        }

        .dialogK3 {
            .k3WarehouseName {
                width: 85px !important;
                margin-bottom: 20px;
            }

            .childk3warehouse {
                position: relative;

                .k3WarehouseName {
                    margin-left: 70px;
                }

                .k3WarehouseNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 24px;
                    left: 64px;
                }

                .k3WarehouseNoNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 33px;
                    left: 170px;
                }
            }

            .k3warehouse {
                position: relative;

                i {
                    margin-left: 55px;
                    content: "*";
                    color: #ff4949;
                    margin-right: 4px;
                }

                .k3WarehouseNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 24px;
                    left: 64px;
                }

                .k3WarehouseNoNameError {
                    position: absolute;
                    color: #ff4949;
                    font-size: 12px;
                    top: 24px;
                    left: 170px;
                }
            }

            .k3WarehouseNo {
                margin-left: 11px;
                margin-bottom: 20px;
            }

            .addk3WarehouseBtn {
                width: 50px;
            }
        }
    }
</style>
