<template>
  <div
    class="step2 pb-50"
    :class="{ 'width-1280': !hlepregister }"
    v-loading="pictureLoading"
  >
    <div v-if="hlepregister" class="title-top bg-f mb-5">代客注册</div>
    <div class="bg-f" :class="{ replace: hlepregister, step: !hlepregister }">
      <div class="mb-100 ml-200" v-if="params">
        <div v-if="form.state == 3">
          <p class="font-30 tac mt-50">您的申请未获得通过！</p>
          <p class="font-12 lh-12 tac color-danger">原因：{{ form.reason }}</p>
          <div class="mt-30">
            <div v-if="storeState == 3" class="tac">
              <el-button class="w100" type="danger" @click="returnStore"
                >返回店铺管理</el-button
              ><br />
              <el-button
                class="mt-20 w130"
                type="text"
                @click="deleteTraderRecord"
                >删除本次申请记录</el-button
              >
            </div>
            <div v-else class="tac">
              <el-button type="danger" @click="reApply">重新申请</el-button>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="font-30 tac mt-50">您的申请正在审核中！</p>
          <p class="font-12 lh-12 tac">
            审核将需要1-3个工作日，请耐心等待，谢谢！
          </p>
          <p class="tac mt-30" v-if="storeState == 1">
            <el-button class="w100" type="danger" @click="returnStore"
              >返回店铺管理</el-button
            >
          </p>
        </div>
      </div>
      <div class="mb-50" v-else>
        <span v-if="newShop">创建新店铺</span>
        <div v-else>
          <!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
          <i class="iconfont icon-inspector color-b font-18 mr-10"></i>
          <span class="color-6 font-16"
            >2/3{{ hlepregister ? "填写客户信息" : "填写信息" }}</span
          >
        </div>
      </div>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        :label-width="hlepregister ? '170px' : '340px'"
      >
        <el-form-item label="营业执照编码:" prop="licenseNum">
          <el-input
            v-model="form.licenseNum"
            :disabled="params"
            @blur="getK3List"
          ></el-input>
          <!-- <transition name="el-zoom-in-top">
            <div class="dropdown_wrap" v-show="storeCodeList.length > 0">
              <div
                @click="chooseStoreCode(item)"
                v-for="item in storeCodeList"
                class="dropdown_item"
                :key="item.licenseNum"
              >
                <div>{{ item.licenseNum }}</div>
                <p>门店名称：{{ item.storeName }}</p>
                <p>门店编码：{{ item.storeNo }}</p>
              </div>
            </div>
          </transition> -->
        </el-form-item>
        <el-form-item
          label="门店编码:"
          v-if="form.storeCodeNo && !isCityPartner2"
        >
          <el-input v-model="form.storeCodeNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="门店类型:" v-if="form.storeCodeNo">
          <el-input v-model="form.sellerType" disabled></el-input>
          <!-- <el-select v-model="form.sellerType" clearable>
						<el-option :label="item.name" :value="item.key" v-for='item in searchType' :key='item.key'></el-option>
					</el-select> -->
        </el-form-item>
        <el-form-item label="商户名称:" prop="storeName">
          <el-input
            v-model.trim="form.storeName"
            placeholder="请输入2-40个字符"
            :disabled="params || !!form.storeCodeNo"
          ></el-input>
          <!-- <el-checkbox v-model="form.isHotel" v-if="hlepregister" class="ml-5">酒店</el-checkbox> -->
        </el-form-item>
        <el-form-item label="商户简称:" v-if="hlepregister" >
          <el-input
            v-model.trim="form.platformDescription"
            :maxlength="20"
            placeholder="请输入"
            :disabled="params || !!form.storeCodeNo"
          ></el-input>
          <!-- <el-checkbox v-model="form.isHotel" v-if="hlepregister" class="ml-5">酒店</el-checkbox> -->
        </el-form-item>
        <el-form-item label="商户地址:" prop="selectedOptions" required>
          <selectArea v-model="form.selectedOptions" @changeName="changeName"/>
          <!-- <el-form-item class="fl mb-20" prop="selectedOptions">
            <el-cascader
              class="mr-10 fl"
              :options="areas"
              ref="a"
              v-model="form.selectedOptions"
              :disabled="params || (!!form.storeCodeNo && isRegister)"
              @change="selectedOptionsChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item class="mb-20 fl" prop="streetId" v-if="showStreet">
            <el-select
              clearable
              v-model="form.streetId"
              placeholder="请选择街道(镇)"
              @change="addressName"
              :disabled="params || (!!form.storeCodeNo && isRegister)"
            >
              <el-option
                :label="item.districtName"
                :value="item.id"
                :key="item.id"
                v-for="item in fourListsDelivey"
              ></el-option>
            </el-select>
          </el-form-item> -->
          
        </el-form-item>
        <el-form-item  prop="attr">
            <el-input
              v-model.trim="form.attr"
              placeholder="请输入4-50个字符"
              :disabled="params || (!!form.storeCodeNo && isRegister)"
            ></el-input>
          </el-form-item>
        <el-form-item label="营业执照照片:" prop="dialogImageUrl">
          <p class="color-9 lh-14 p10">
            图片仅支持jpg,jpeg,png,bmp格式,文件小于4M
          </p>
          <!--通过搜索营业执照编码选中门店（选中form.storeCodeNo不为空），注册页面（isRegister）不能修改，代注册页面可以修改
						hasP属性不显示加号和删除按钮
					-->
          <div
            :class="{
              noAdd: form.dialogImageUrl.length,
              hasP: (!!form.storeCodeNo && isRegister) || params,
            }"
          >
            <input type="hidden" v-model="form.dialogImageUrl" />
            <el-upload
              action="/sunrise-gateway/oss/ossUpload"
              :file-list="form.dialogImageUrl"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="handleAvatarScucess"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRa"
              :on-error="errFun"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="门店照片:" prop="dialogImageUrlList">
          <p class="color-9 lh-14 p10">
            图片仅支持jpg,jpeg,png,bmp格式,文件小于4M
          </p>
          <!--通过搜索营业执照编码选中门店（选中form.storeCodeNo不为空），注册页面（isRegister）不能修改，代注册页面可以修改-->
          <!--<div v-if="!!form.storeCodeNo&&isRegister||params" class="hasP">
						<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="form.dialogImageUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>-->
          <div :class="{ noAdd: form.dialogImageUrlList.length >= 2 }">
            <input type="hidden" v-model="form.dialogImageUrlList" />
            <el-upload
              action="/sunrise-gateway/oss/ossUpload"
              :file-list="form.dialogImageUrlList"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="handleAvatarSuccessList"
              :on-remove="handleRemove"
              :before-upload="beforeAvatarUpload"
              :on-error="errFun"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>

       

        <el-form-item label="客户分组">
					<el-cascader v-model="form.groupId" :disabled="params" clearable :options="treeList" :props="treeProps"></el-cascader>
				</el-form-item>
				<el-form-item
          label="客户类型:"
          prop="cType"
          v-if="!form.storeCodeNo && hlepregister"
        >
          <el-select
            v-model="form.cType"
            clearable
            placeholder="请选择客户类型"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in prepareList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属区域">
          <districtSelect v-model="form.districtId" class="mr-10"/>
        </el-form-item>
				<el-form-item label="K3编码:" v-if="needK3No" class="K3select">
          <!-- <el-input
            v-model.trim="form.k3No"
            placeholder="请输入K3编码"
            :disabled="params"
          ></el-input> -->
          <el-select v-model="form.k3No" :disabled="params" clearable placeholder="请选择">
            <el-option
              v-for="item in K3info"
              :key="item.custNumber"
              :label="item.custNumber"
              :value="item.custNumber">
              <div style="line-height:1.5">
                <div>{{item.custName}}</div>
                <div>{{item.custNumber}}</div>
              </div>
            </el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="K3数据更新" placement="right">
						<el-button @click="getK3List" style="width:30px;height:30px;background:#409eff;padding:0;line-height:30px;color:#fff" icon="el-icon-refresh" circle></el-button>
					</el-tooltip>
        </el-form-item>

        <el-form-item label="联系人姓名:" prop="linkname">
          <el-input
            v-model.trim="form.linkname"
            placeholder="请输入2-6个字符，可为汉字或字母"
            :disabled="params"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位:" class="mt-30" prop="jobOffer">
          <el-input
            v-model.trim="form.jobOffer"
            placeholder="请输入2-10个字符,可为汉字字母或数字"
            :disabled="params"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人电话:" prop="tel">
          <el-input
            v-model.trim="form.tel"
            :maxlength="11"
            placeholder="请输入11位手机号"
            :disabled="params"
          ></el-input>
        </el-form-item>
        <el-form-item label="Email:" class="mt-30" prop="email">
          <el-input
            v-model.trim="form.email"
            placeholder="请输入正确的邮箱且长度小于20"
            :disabled="params"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务员:" v-if="hlepregister" class="mb-30">
          <el-select
            v-model="form.managerId"
            :disabled="params"
            filterable
            clearable
            placeholder="请选择业务员"
          >
            <el-option
              :label="`${item.employeeName}(${item.mobile})`"
              :value="item.managerId"
              v-for="item in people"
              :key="item.mobile"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="请选择平台:"
          v-if="!hlepregister"
          class="mb-30"
          prop="sellerId"
        >
          <el-select
            v-model="form.sellerId"
            :disabled="params"
            @visible-change="getSearchList"
            @change="sellerChange"
            filterable
            clearable
            placeholder="请选择平台"
          >
            <el-option
              :label="item.storeName"
              :value="item.sellerId"
              v-for="item in selectList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--<el-form-item v-if="!isCityPartner && !isCityPartner2" label="推荐经销商:"  class="mb-30">-->
        <!--<el-select v-model='form.bindingDealerTraderId'  filterable clearable :disabled="!!form.storeCodeNo || params" placeholder="请选择经销商">-->
        <!--<el-option :label="item.storeName" :value="item.buyerId" v-for='item in agencyList' :key='item.buyerId'></el-option>-->
        <!--</el-select>-->
        <!--</el-form-item>-->
        <el-form-item
          label="推荐人:"
          class="mt-30"
          prop="distManagerId"
          v-if="!hlepregister"
        >
          <!-- <el-input v-model.trim="form.recNum" :maxlength="11" placeholder="请输入11位手机号" :disabled="params"></el-input> -->
          <el-select
            v-model="form.distManagerId"
            :disabled="params"
            clearable
            placeholder="请选择业务员"
          >
            <el-option
              :label="`${item.employeeName}(${item.mobile})`"
              :value="item.managerId"
              v-for="item in staffList"
              :key="item.mobile"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="mt-50 mb-0" v-if="!params">
          <el-button v-if="newShop" type="danger" @click="goPage('form')"
            >提交</el-button
          >
          <el-button v-else type="danger" @click="goPage('form')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 放大图片 -->
      <el-dialog
        :visible.sync="dialogVisible"
        size="tiny"
        @close="dialogVisible = false"
      >
        <img width="100%" :src="dialogImageUrl" alt="show-message" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import { mapActions } from "vuex";
import { MessageBox } from "element-ui";
import districtSelect from "@/components/districtSelect/districtSelect.vue";

export default {
   components: {
    districtSelect
  },
  data() {
    var streetId = (rule, value, callback) => {
      if (
        this.form.selectedOptions.length > 0 &&
        (!value || value.length == 0)
      ) {
        callback(new Error("请选择街道(镇)"));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      var info = this.form.selectedOptions
      if (info.length===0) {
        callback(new Error('请选择省市区'));
      } else {
        if(info[0]==null){
          callback(new Error('请选择省'));
        }else if(info[1]===null){
          callback(new Error('请选择市'));
        }else if(info[2]===null){
          callback(new Error('请选择区'));
        }else{
          callback();
        }
      }
    };
    return {

      saledialog: false, //是否打开添加业务员
      agencyList: [], //经销商列表
			prepareList: [], // 客户类型
			needK3No: false, // 是否显示K3编码
      K3info: [], // K3编码列表
      isRegister: this.$route.path.indexOf("/step") > 0 ? true : false, // 注册
      hlepregister: this.$route.path.indexOf("/replace") > 0 ? true : false, // 代客注册
      pictureLoading: false, //上传图片loading，或者点击下一步页面加载跳转
      storeState: this.$route.params.state, //店铺申请查看    1 表示审核中  3表示未通过
      newShop: false, //创建新店铺
      traderPhotoRelationParams: [], //审核门店照片信息
      params: false,
      form: {
        cusTypeId: null,
        platformDescription: null, //客户简称
        bindingDealerTraderId: null, //经销商id
        buyerSellerRelationId: "", //进货商关联关系表主键
        email: "",
        jobOffer: null,
        storeName: null,
        attr: null,
        licenseNum: null,
        linkname: null,
        tel: null,
        selectedOptions: [],
        photo: null,
        dialogImageUrlList: [], //门店照片信息
        photos: null,
        dialogImageUrl: [], //记录营业执照照片
        state: "", //1 表示审核中  3表示未通过
        reason: "",
        isHotel: false,
        sellerId: null,
        cType: 2,
				k3No: null, // K3编码
        groupId: null,
        storeCodeNo: null,
        sellerType: null,
        streetId: null, //街道id
        streetName: null, //街道名称
        distManagerId: null,
        managerId: null,
        districtId: '0',
      },
      areaNameList:[],
      showStreet: true,
      fourListsDelivey: [], //四级地址列表
      dialogVisible: false,
      fileList: [], //门店照片的url
      dialogImageUrl: null, //只做门店照片的预览用
      rules: {
        storeName: [
          {
            required: true,
            min: 2,
            max: 40,
            message: "请输入2-40个字符",
          },
        ],
        platformDescription: [
          {
            required: true,
            min: 2,
            max: 40,
            message: "请输入2-40个字符",
          },
        ],
        linkname: [
          {
            required: true,
            pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,6}$/,
            message: "请输入2-6个字符，可为汉字或字母",
          },
        ],
        tel: [
          {
            required: true,
            pattern: /^1\d{10}$/,
            message: "请输入正确的手机号",
          },
        ],
        selectedOptions: [
          { validator: validateAddress, trigger: 'change' },
        ],
        attr: [
          {
            required: true,
            message: "请输入详细地址",
          },
          {
            min: 4,
            max: 50,
            message: "请输入4-50个字符",
          },
        ],
        streetId: [
          {
            validator: streetId,
          },
        ],
        licenseNum: [
          {
            required: true,
            pattern: /^[0-9a-zA-Z]{10,20}$/,
            message: "请输入10-20个字符，可为字母数字",
            trigger: "blur,change",
          },
        ],
        dialogImageUrl: [
          {
            required: true,
            type: "array",
            message: "请上传营业执照照片",
          },
        ],
        dialogImageUrlList: [
          {
            required: true,
            type: "array",
            message: "请上传门店照片",
          },
        ],
        jobOffer: [
          {
            pattern: /^([A-Za-z0-9]|[\u4E00-\u9FA5]){2,10}$/,
            min: 2,
            max: 10,
            message: "请输入2-10个字符,可以为汉字字母或数字",
          },
        ],
        email: [
          {
            pattern:
              /^(?=\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,20}$/,
            message: "请输入正确的邮箱格式,且长度不能大于20",
          },
        ],
        sellerId: [
          {
            required: true,
            message: "请选择供货商",
          },
        ],
        managerId: [
          {
            required: true,
            message: "请选择业务员",
          },
        ]
      },
      selectList: [], // 供应商列表
      staffList: [], //业务员；列表
      bool: true, // 判断是否选中门店编码，若未选中则清除带出信息
      isCityPartner: false, //是否是城市拍档平台
      treeList: [], // 客户分组list
      treeProps: {
        value: 'id',
        label: 'name',
        children:'sub',
        emitPath:false
      },
    };
  },
  computed: {
    people() {
      return this.$store.state.salesMan.people;
    },
    isCityPartner2() {
      return this.$store.state.clientType.isCityPartner;
    },
  },
  mounted() {
		this.getPrepare()
    this.getLeftTree()
    //代客注册
    if (this.hlepregister) {
      this.findSales();
    }

    // console.log(this.$route.params.id)
    if (this.$route.params.id && this.$route.params.id != "-1") {
      this.params = true;
    }
    if (this.params) {
      //是否有参数传入
      this.checking();
    } else {
      if (!this.hlepregister) {
        if (sessionStorage.getItem("loginName")) {
          this.form.tel = sessionStorage.loginName;
          this.newShop = true;
        } else {
          this.form.tel = sessionStorage.stepMobile;
        }
      } else {
        // this.getAgencyList(sessionStorage.getItem('traderId'),2)
      }
    }
  },
  watch: {
    bool(val) {
      if (!val && this.form.storeCodeNo) {
        this.resetFormInfo();
      }
    },
    "form.sellerId": function (val) {
      if (!val) {
        // this.agencyList = [];
        this.form.bindingDealerTraderId = null;
      }
    },
    "form.streetId": function (val) {
      if (!val) {
        this.selectList = [];
      }
    },
  },
  methods: {
    ...mapActions({
      findSales: "findSales",
      makesure: "sure",
    }),
    // getAgencyList(id,type){
    // 	console.log('代客');
    // 	let url = type == 1 ? '/unlogin/trader/findClientAllList/select' : '/trader/findClientAllList/select';
    // 	axiosApi( url,{ "sellerId": id,"ctype":"1" },res => {
    // 		console.log(res,'经销商');
    // 		this.agencyList = res.data.data.elements;
    // 	})
    // },
    changeName(e){
			this.areaNameList = e
		},
    // 获取客户分组
    getLeftTree(){
        // 查询 左侧树
        axiosApi('/trader/customer/group/listTree',{},(res) => {
            this.treeList = this.treeInit(res.data.data);
        });
        // 默认查询 全国区域 右侧表格数据
    },
    treeInit(tree){
      tree.forEach(item=>{
        if(item.sub.length>0){
          this.treeInit(item.sub)
        }else{
          delete item.sub
        }
      })
      return tree
    },
    getK3List(){
      axiosApi('/trader/k3CustomerInfos/getByLicenseNum',{licenseNum:this.form.licenseNum},res => {
        if(res.data.code==='000000'){
          this.K3info = res.data.data
          if(res.data.data[0] && !this.form.k3No){
            // 默认选中第一个
            this.form.k3No = res.data.data[0].custNumber || null 
          }
        }
      })
    },
		getPrepare(){
				axiosApi('/trader/proxyClientRegister/prepare',{},res => {
					if(res.data.code==='000000'){
						this.prepareList = res.data.data.cTypeVoList
						this.needK3No = res.data.data.needK3No
					}
				})
		},
    selectedOptionsChange() {
      //三级地址更改
      this.form.sellerId = null;
      this.form.streetId = "";
      // this.selectStreetInfoLists(this.form.selectedOptions[2]);
    },
    getSearchList(val) {
      if (!val) {
        this.selectList = [];
        return false;
      }
      if (this.showStreet) {
        if (!this.form.streetId) {
          this.$message.error("请选择省市区街道信息");
          return false;
        }
      } else {
        if (!this.form.selectedOptions[2]) {
          this.$message.error("请选择省市区街道信息");
          return false;
        }
      }
      let body = {
        // streetId:this.form.streetId,
        regionId: this.form.selectedOptions[2],
        cityId: this.form.selectedOptions[1],
        provinceId: this.form.selectedOptions[0],
      };
      axiosApi(
        "/unlogin/trader/partnerFind/select",
        body,
        (res) => {
          // this.selectList = res.data.data
          let flag = false;
          res.data.data.map((v) => {
            if (v.sellerId === this.form.sellerId) {
              flag = true;
            }
          });
          if (!flag) {
            this.$set(this.form, "sellerId", null);
          }
          this.selectList = res.data.data;
          console.log(this.selectList, "供应商列表");
        },
        0,
        10
      );
    },
    sellerChange(val) {
      console.log(val);
      console.log(this.selectList);
      if (!val) {
        return false;
      }
      let data = this.selectList.filter((v) => {
        return v.sellerId == val;
      })[0].supplierType;

      if (data == 4) {
        this.isCityPartner = true;
      } else {
        this.isCityPartner = false;
      }
      // 该供应商下查询业务员
      if (this.form.sellerId) {
        axiosApi(
          "/unlogin/foundation/employee/selectAll",
          {
            traderId: this.form.sellerId,
            searchManagerOnly: 1,
          },
          (res) => {
            this.staffList = res.data.data.elements;
            if (!this.params) this.form.distManagerId = null;
          },
          0,
          10
        );
        // this.getAgencyList(this.form.sellerId,1);
        if (!this.params) {
          this.form.bindingDealerTraderId = null;
        }
      }
    },
    // queryStoreType(val) {
    //   this.bool = false;
    //   if (
    //     this.form.licenseNum &&
    //     this.form.licenseNum.length >= 15 &&
    //     this.form.licenseNum.length <= 20
    //   ) {
    //     this.debounce(() => {
    //       const body = {
    //         checkState: 1, // 只查审核通过的
    //         licenseNum: val,
    //         sellerId: this.hlepregister
    //           ? sessionStorage.getItem("traderId")
    //           : null, // 代客注册要传sellerId
    //       };
    //       axiosApi(
    //         "/unlogin/trader/seller/getStoreCodeCheckList",
    //         body,
    //         (res) => {
    //           this.storeCodeList = res.data.data.datalist.elements;
    //         },
    //         null,
    //         null
    //       );
    //     });
    //   } else {
    //     this.storeCodeList = [];
    //   }
    // },
    // clearStoreCodeList() {
    //   this.storeCodeList = [];
    // },
    // chooseStoreCode(item) {
    //   this.bool = true;
    //   this.form.storeName = item.storeName;
    //   this.form.attr = item.addr;
    //   this.form.licenseNum = item.licenseNum;
    //   this.form.selectedOptions = [
    //     item.provinceId + "",
    //     item.cityId + "",
    //     item.regionId + "",
    //   ];
    //   this.selectStreetInfoLists(item.regionId);
    //   if (item.streetId) {
    //     this.form.streetId = item.streetId;
    //     this.form.streetName = item.streetName;
    //     this.showStreet = true;
    //   } else {
    //     this.showStreet = false;
    //   }
    //   this.form.photo = item.headImg;
    //   this.form.photos = item.licenseImg;
    //   if (item.licenseImg) {
    //     this.form.dialogImageUrl = [
    //       {
    //         url: item.licenseImg,
    //       },
    //     ]; //记录营业执照照片
    //   } else {
    //     this.form.dialogImageUrl = [];
    //   }
    //   this.getSearchList(1);
    //   this.form.sellerId = this.hlepregister
    //     ? sessionStorage.getItem("traderId")
    //     : item.sellerId;
    //   this.form.cType =
    //     item.storeType === "A" ? "3" : item.storeType === "C" ? "5" : null;
    //   console.log(item.storeType === "B");
    //   if (item.storeType === "B") {
    //     this.form.cType = item.subStoreType;
    //   }
    //   console.log(this.form.cType);
    //   this.form.storeCodeNo = item.storeNo;
    //   this.form.sellerType = item.storeTypeName;
    //   this.form.distManagerId = null;
    //   this.storeCodeList = [];
    // },
    resetFormInfo() {
      this.showStreet = true;
      this.resetData(
        {
          name: this.form,
          data: [
            "storeName",
            "attr",
            "photo",
            "photos",
            "cType",
            "storeCodeNo",
            "sellerType",
            "distManagerId",
          ],
        },
        null,
        {
          name: this.form,
          data: ["selectedOptions", "dialogImageUrlList", "dialogImageUrl"],
        }
      );
      this.form.cType = 2; // 重置客户类型为2
      if (!this.hlepregister) this.form.sellerId = null;
    },
    //删除本次申请记录
    deleteTraderRecord() {
      this.$confirm("您确定要删除本次申请记录？", "提示信息", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          axiosApi(
            "/trader/deleteTraderRecord/delete",
            {
              loginName: sessionStorage.loginName,
              traderId: this.$route.params.id,
            },
            (res) => {
              if (res.data.data == 1) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 1000,
                });
                this.$router.push(`${basePath}/storeManage`);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
            duration: 1000,
          });
        });
    },
    //返回店铺管理
    returnStore() {
      this.$router.push(`${basePath}/storeManage`);
    },
    //重新申请
    reApply() {
      this.params = false; //重新申请状态
      this.form.licenseNum = null;
      this.form.storeCodeNo = null;
      this.form.sellerType = null;
      this.form.cType = null;
    },
    //审核中,从注册第三步里 查看信息跳过来的。
    checking() {
      //返回字段state为3 表示审核未通过   为1 审核中
      //				this.$refs.form.resetFields();//表单验证重置
      axiosApi(
        "/unlogin/trader/findStoreInfo/select",
        {
          traderId: this.$route.params.id,
        },
        (res) => {
          if (res.data.data) {
            console.log(res, "用户");
            this.form.bindingDealerTraderId =
              res.data.data.bindingDealerTraderId || null;
            console.log(this.form.bindingDealerTraderId, "用户");
            this.form.storeName = res.data.data.storeName;
            this.form.linkname = res.data.data.linkname;
            this.form.tel = res.data.data.tel;
            if (res.data.data.customerType) {
              this.form.cusTypeId =
                res.data.data.customerType.parentId != 0
                  ? [
                      res.data.data.customerType.parentId,
                      res.data.data.customerType.id,
                    ]
                  : [res.data.data.customerType.id];
            } else {
              this.form.cusTypeId = null;
            }

            let selectedOptions = [];
            if (res.data.data.addressVO)
              selectedOptions.push(
                res.data.data.addressVO.provinceId.toString(),
                res.data.data.addressVO.cityId.toString(),
                res.data.data.addressVO.regionId.toString(),
                res.data.data.addressVO.streetId.toString()
              );
            if (res.data.data.addressVO)
              // this.selectStreetInfoLists(res.data.data.addressVO.regionId); //查询四级地址列表
            this.form.selectedOptions = selectedOptions;
            // this.form.streetId = res.data.data.addressVO.streetId;
            if (res.data.data.addressVO)
              this.form.attr = res.data.data.addressVO.addr;

            this.form.dialogImageUrl = [
              {
                url: res.data.data.licenseImg,
              },
            ];
            let photos = res.data.data.photos;
            this.form.dialogImageUrlList = [];
            photos.forEach((item) => {
              this.form.dialogImageUrlList.push({
                url: item.photoUrl,
                id: item.id,
              });
            });
            this.form.distManagerId = res.data.data.distMangerId || null; // 0或null 代表没有业务员
            this.form.jobOffer = res.data.data.jobOffer;
            this.form.email = res.data.data.email;
            this.form.state = res.data.data.state;
            // this.$set(this.form,'state',res.data.data.state)

            this.form.reason = res.data.data.reason;
            this.form.buyerSellerRelationId =
              res.data.data.buyerSellerRelationId;
            this.getSearchList(1);
            this.form.sellerId = res.data.data.sellerId;
            if (this.form.sellerId) {
              axiosApi(
                "/unlogin/foundation/employee/selectAll",
                {
                  traderId: this.form.sellerId,
                  searchManagerOnly: 1,
                },
                (res) => {
                  this.staffList = res.data.data.elements;
                  if (!this.params) this.form.distManagerId = null;
                },
                0,
                10
              );
              // this.getAgencyList(this.form.sellerId,1);
              if (!this.params) {
                this.form.bindingDealerTraderId = null;
              }
            }
            this.form.licenseNum = res.data.data.licenseNum;
            this.form.storeCodeNo = res.data.data.storeCodeNo;
            this.form.sellerType = res.data.data.sellerType;
            this.form.cType = res.data.data.cType;
            this.form.k3No = res.data.data.k3No
            this.form.groupId = res.data.data.groupId
            this.form.districtId = res.data.data.districtId
          }
        }
      );
    },
    //注册接口传参数
    loading() {
      // let info = this.$refs.a.inputValue.split("/");
      this.fileList = [];
      this.form.dialogImageUrlList.forEach((item, index) => {
        this.fileList.push(item.url);
      });
      const api = "/unlogin/registerTraderImpl/insert";
      const body = {
        // bindingDealerTraderId:this.form.bindingDealerTraderId || 0,
        mobile: sessionStorage.stepMobile,
        loginPwd: sessionStorage.loginPwd,
        storeName: this.form.storeName,
        provinceId: this.form.selectedOptions[0],
        cityId: this.form.selectedOptions[1],
        regionId: this.form.selectedOptions[2],
        streetId: this.form.selectedOptions[3],
        
        provinceName: this.areaNameList[0],
        cityName: this.areaNameList[1],
        regionName: this.areaNameList[2],
        streetName: this.areaNameList[3],
        addr: this.form.attr,

        licenseNum: this.form.licenseNum,
        licenseImg: this.form.dialogImageUrl[0].url,
        photoUrl: this.fileList,
        linkname: this.form.linkname,
        jobOffer: this.form.jobOffer,
        tel: this.form.tel,
        email: this.form.email,
        distManagerId: this.form.distManagerId,
        appId: 2,

        sellerId: this.form.sellerId,
        cType: this.form.cType,
        k3No:this.form.k3No,
        groupId: this.form.groupId,
        // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
        storeCodeNo: this.form.storeCodeNo,
        districtId: this.form.districtId,
        
        // xToken : sessionStorage.getItem("xToken")
      };
      axiosApi(
        api,
        body,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          this.$router.push(basePath + "/register/step3/" + res.data.data);
        },
        1,
        10,
        (err) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          MessageBox.alert(err.data.message, "提示信息", {
            confirmButtonText: "确定",
          });
        }
      );
    },
    //创建新店铺
    createLoading() {
      // 应该是创建多门店？？？
      let info = this.$refs.a.inputValue.split("/");
      this.fileList = [];
      this.form.dialogImageUrlList.forEach((item, index) => {
        this.fileList.push(item.url);
      });
      // this.fileList = Array.from(new Set(this.fileList))
      // this.fileList = this.fileList.unique();//数组去重
      const api = "/trader/addChainBuyer/insert";
      const body = {
        // bindingDealerTraderId:this.form.bindingDealerTraderId || 0,
        mobile: sessionStorage.loginName,
        storeName: this.form.storeName,
        provinceId: this.form.selectedOptions[0],
        cityId: this.form.selectedOptions[1],
        regionId: this.form.selectedOptions[2],
        provinceName: info[0],
        cityName: info[1],
        regionName: info[2],
        addr: this.form.attr,
        // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
        licenseNum: this.form.licenseNum,
        licenseImg: this.form.dialogImageUrl[0].url,
        photoUrl: this.fileList,
        linkname: this.form.linkname,
        jobOffer: this.form.jobOffer,
        tel: this.form.tel,
        email: this.form.email,
        distManagerId: this.form.distManagerId,
        districtId: this.form.districtId,
        // xToken : sessionStorage.getItem("xToken")
      };
      axiosApi(
        api,
        body,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          this.$router.push(basePath + "/register/step3");
        },
        1,
        10,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          MessageBox.alert(res.data.message, "提示信息", {
            confirmButtonText: "确定",
          });
        }
      );
    },
    handleAvatarScucess(res) {
      //营业执照照片
      //console.log(res, "aa")
      this.pictureLoading = false;
      this.form.dialogImageUrl.push({
        url: res.data[0],
      });
      this.$refs.form.validateField("dialogImageUrl"); //部分表单字段验证
    },
    handleAvatarSuccessList(res) {
      //门店照片
      this.form.dialogImageUrlList.push({
        url: res.data[0],
      });
      this.pictureLoading = false;
      this.$refs.form.validateField("dialogImageUrlList"); //部分表单字段验证
    },
    handleRa(file, fileList) {
      //营业执照照片删除
      this.form.dialogImageUrl = [];
      this.form.dialogImageUrl = fileList;
      this.$refs.form.validateField("dialogImageUrl"); //部分表单字段验证
    },
    handleRemove(file, fileList) {
      //门店照片删除
      this.form.dialogImageUrlList = [];
      this.form.dialogImageUrlList = fileList;
      if (this.form.state == 3 && file.id) {
        //console.log(file, fileList)
        let obj = {};
        obj.traderId = this.$route.params.id;
        obj.photoId = file.id;
        obj.type = 2;
        this.traderPhotoRelationParams.push(obj);
      }
      this.$refs.form.validateField("dialogImageUrlList"); //部分表单字段验证
    },
    beforeAvatarUpload(file) {
      let bool = this.isUploadImg(file);
      if (bool) {
        this.pictureLoading = true;
      }
      return bool;
    },
    handlePictureCardPreview(file) {
      //console.log(file, "fangda")
      this.dialogImageUrl = file.url; //门店照片的放大预览
      //console.log(this.dialogImageUrl)
      this.dialogVisible = true;
    },
    errFun(err) {
      //console.log(err);
    },
    goPage(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.pictureLoading = true;
          if (this.form.state == 3) {
            this.reloading(); //审核未通过
          } else {
            if (this.newShop) {
              this.createLoading(); //创建新店铺
            } else {
              if (this.hlepregister) {
                this.helpregistering(); //代客户注册
              } else {
                this.loading(); //注册时候
              }
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    helpregistering() {
      // 代注册
      this.fileList = [];
      this.form.dialogImageUrlList.forEach((item, index) => {
        this.fileList.push(item.url);
      });
      const api = "/trader/proxyClientRegister/insert";
      const body = {
        // bindingDealerTraderId:this.form.bindingDealerTraderId || 0,
        mobile: sessionStorage.stepMobile,
        loginPwd: sessionStorage.loginPwd,
        storeName: this.form.storeName,
        provinceId: this.form.selectedOptions[0],
        cityId: this.form.selectedOptions[1],
        regionId: this.form.selectedOptions[2],
        streetId: this.form.selectedOptions[3],
        
        provinceName: this.areaNameList[0],
        cityName: this.areaNameList[1],
        regionName: this.areaNameList[2],
        streetName: this.areaNameList[3],

        addr: this.form.attr,
        licenseNum: this.form.licenseNum,
        licenseImg: this.form.dialogImageUrl[0].url,
        photoUrl: this.fileList,
        linkname: this.form.linkname,
        jobOffer: this.form.jobOffer,
        tel: this.form.tel,
        email: this.form.email,
        appId: 2,
        sellerId: sessionStorage.traderId,
        cType: this.form.cType,
				k3No:this.form.k3No,
        groupId: this.form.groupId,
        // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
        storeCodeNo: this.form.storeCodeNo,
        platformDescription: this.form.platformDescription,
        managerId: this.form.managerId,
        districtId: this.form.districtId,
        // xToken : sessionStorage.getItem("xToken")
      };
      axiosApi(
        api,
        body,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          //console.log(res,"sdsdsd")
          this.$router.push(
            basePath +
              "/register/replace3/" +
              res.data.data.id +
              "/" +
              res.data.data.buyerId
          );
        },
        1,
        10,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          MessageBox.alert(res.data.message, "提示信息", {
            confirmButtonText: "确定",
          });
        }
      );
    },
    reloading() {
      // 驳回重新提交
      let info = this.$refs.a.inputValue.split("/");
      //console.log(this.form.dialogImageUrlList, "门店照片")
      let obj = {
        traderId: this.$route.params.id,
        photoUrl: [],
        type: 1,
      };
      this.form.dialogImageUrlList.forEach((item, index) => {
        if (!item.id) {
          obj.photoUrl.push(item.url);
        }
      });
      // obj.photoUrl = Array.from(new Set(obj.photoUrl));
      obj.photoUrl = obj.photoUrl.unique(); //数组去重
      this.traderPhotoRelationParams.push(obj);
      // this.fileList = Array.from(new Set(this.fileList))
      this.fileList = this.fileList.unique(); //数组去重
      const photoUrl = this.form.dialogImageUrlList.map((item) => {
        return item.url;
      });
      const api = "/unlogin/trader/reapply/update";
      const body = {
        // bindingDealerTraderId:this.form.bindingDealerTraderId || 0,
        traderId: this.$route.params.id,
        storeName: this.form.storeName,
        licenseNum: this.form.licenseNum,
        licenseImg: this.form.dialogImageUrl[0].url,
        linkname: this.form.linkname,
        jobOffer: this.form.jobOffer,
        tel: this.form.tel,
        email: this.form.email,
        distManagerId: this.form.distManagerId,
        deliveryAddressParam: {
          provinceId: this.form.selectedOptions[0],
          cityId: this.form.selectedOptions[1],
          regionId: this.form.selectedOptions[2],
          provinceName: info[0],
          cityName: info[1],
          regionName: info[2],
          addr: this.form.attr,
          streetId: this.form.streetId,
          streetName: this.form.streetName,
        },
        traderPhotoRelationParams: this.traderPhotoRelationParams,
        buyerSellerRelationId: this.form.buyerSellerRelationId,

        sellerId: this.form.sellerId,
        cType: this.form.cType,
        k3No:this.form.k3No,
        groupId: this.form.groupId,
        districtId: this.form.districtId,
        // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
        storeCodeNo: this.form.storeCodeNo,
        photoUrl: photoUrl,
        // xToken : sessionStorage.getItem("xToken")
      };
      axiosApi(
        api,
        body,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          this.$router.push(
            basePath + "/register/step3/" + this.$route.params.id
          );
        },
        1,
        10,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.pictureLoading = false;
          MessageBox.alert(res.data.message, "提示信息", {
            confirmButtonText: "确定",
          });
        }
      );
    },
    // selectStreetInfoLists(val) {
    //   //通过区县查询四级街道地址信息
    //   axiosApi(
    //     "/unlogin/trader/district/selectStreetInfoList",
    //     {
    //       parentId: val,
    //     },
    //     (res) => {
    //       this.fourListsDelivey = res.data.data;
    //     },
    //     1,
    //     10000,
    //     () => {}
    //   );
    // },
    addressName(val) {
      if (!this.params) {
        this.form.sellerId = null;
      }
      let obj = {};
      if (val) {
        //					obj = this.fourListsDelivey.find((item) => {
        //					return item.id === val;
        //				});
        this.fourListsDelivey.map((v) => {
          if (v.id === val) obj = v;
        });
        this.form.streetName = obj ? obj.districtName : "";
      }
    },
  },
};
</script>

<style lang="less">
.step2 {
  .dropdown_wrap {
    padding: 10px 0;
    border: 1px solid #ddd;
    position: absolute;
    min-width: 240px;
    top: 34px;
    z-index: 100;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    transform-origin: center top 0px;
    .dropdown_item {
      padding: 10px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
      p {
        color: #666;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .dropdown_item:hover {
      background-color: #eee;
    }
    .dropdown_item:last-child {
      border-bottom: 0;
    }
  }
  .step {
    padding: 50px 0 50px 200px;
  }
  .el-form-item__label {
    padding-right: 30px;
  }
  .p10 {
    padding: 9px 0 10px;
  }
  .ml-200 {
    margin-left: -200px;
  }
  .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    color: #333;
  }
  .el-cascader.is-disabled .el-cascader__label {
    color: #333;
    cursor: not-allowed;
  }
  .noAdd {
    .el-upload.el-upload--picture-card {
      display: none;
    }
  }
  .w100 {
    width: 100px;
  }
  .w130 {
    width: 130px;
  }
  .replace {
    padding: 30px 30px 50px;
  }
}
.el-select-dropdown__item{
  height: auto;
  padding: 5px 20px;
}
</style>
