<template>
	<div id="accountSafe">
		<div class="title-top bg-f">
			账户安全
		</div>
        <div class="pl-50 pt-20 font-14 bg-f">您当前的帐号 {{user}}</div>
		<div class="safe  bg-f pt-50 pb-20">
			<div class="flex ml-50 mr-50 bd pt-20 pb-20">
				<p class="lh32 ml-20">
                    <i class="iconfont icon-password"></i>
                    <span>登录密码</span>
                </p>
				<el-button type="text" @click="goPage(1)">修改</el-button>
			</div>
            <div class="flex ml-50 mr-50 bd pt-20 pb-20">
                <p class="lh32 ml-20">
                    <i class="iconfont icon-phone"></i>
                    <span>安全手机 {{phone}}</span>
                </p>
                <!--<el-button type="text" @click="goPage(2)" v-if="!phone">绑定</el-button>-->
            </div>
            <!--<div class="flex ml-50 mr-50 bd pt-20 pb-20">-->
                <!--<p class="lh32 ml-20">绑定微信</p>-->
                <!--<el-button type="text" @click="goPage(3)">修改</el-button>-->
            <!--</div>-->
            <div class="flex ml-50 mr-50 bd pt-20 pb-20">
                <p class="lh32 ml-20">
                    <i class="iconfont icon-home"></i>
                    <span>
                        企业认证
                        <span>（企业名称：{{companyInfo.storeName}}  营业执照注册号：{{companyInfo.licenseNum}} <span v-if="companyInfo.merchantId">商户号：{{companyInfo.merchantId}}</span>）</span>
                    </span>
                </p>
                <el-button type="text" @click="goPage(4)" v-if="!isCompanyVerified">去认证</el-button>
                <span style="width:90px" class="tac"  v-if="isCompanyVerified">已认证</span>
            </div>
            <!-- <div class="flex ml-50 mr-50 bd pt-20 pb-20">
                <p class="lh32 ml-20">
                    <i class="iconfont icon-people"></i>
                    <span>
                        个人认证
                        <span v-if="isVerified">（身份证姓名：{{personInfo.realName}}  身份证号码：{{personInfo.idCardNumber}}）</span>
                    </span>
                </p>
                <el-button type="text" @click="goPage(5)" v-if="!isVerified">去认证</el-button>
                <span  style="width:90px" class="tac"  v-if="isVerified">已认证</span>
            </div> -->
            <!-- <div class="flex ml-50 mr-50 bd pt-20 pb-20">
                <p class="lh32 ml-20">
                    <i class="iconfont icon-password"></i>
                    <span>支付密码</span>
                </p>
                <el-button type="text" @click="goPage(6)">{{hasPayPassword ? '修改':'去设置'}}</el-button>
            </div> -->
		</div>
        <!-- 企业认证弹框 --> 
        <EnterpriseAuthentication :dialogVisible="showVisible" @confirm="confirmInfo" @close="showVisible=false" />
	</div>
</template>

<script>
    import { axiosApi, basePath } from '~/api/api'
    import EnterpriseAuthentication from "~/components/enterpriseAuthentication";
	export default {
        components:{
            EnterpriseAuthentication,
        },
	    data(){
	      return{
	          user:'',
              phone:'',
              hasPayPassword:true,
              showVisible: false, // 企业验证弹框
              isCompanyVerified:'',//企业验证
              isVerified:'',//个人验证
              companyInfo:{
                  storeName:'',
                  licenseNum:'',
                  merchantId:null,
              },
              personInfo:{
                  realName:'',
                  idCardNumber:''
              },
          }
        },
		mounted() {
			this.changeHeight();
			this.getStoreInfo()
		},
		methods: {
            confirmInfo(){
                // 企业认证弹框确认
                this.getStoreInfo()
            },
            getStoreInfo(){
                axiosApi("/anon/cbs/account/employeeInfo", {}, (res)=>{
                    const data = res.data.data
                    this.user = data.loginName
                    this.phone = data.mobile
                    this.isCompanyVerified = data.isCompanyVerified
                    this.isVerified = data.isVerified
                    this.companyInfo = {
                        storeName:data.storeName,
                        licenseNum:data.licenseNum,
                        merchantId: data.merchantId
                    }
                    this.personInfo = {
                        realName:data.realName,
                        idCardNumber:data.idCardNumber
                    }
                    this.hasPayPassword = data.hasPayPassword
                }, 1, 10,err=>{
                    this.$message.error(err.data.message)
                })
            },
			changeHeight() {
				let height = document.documentElement.clientHeight;
				document.getElementsByClassName("safe")[0].style.minHeight = height - 381 + "px";
			},
			goPage(val){
			    if (val==1){
                    this.$router.push("/customer/register/changePassFirst");
                }
                if (val==2){
                    this.$router.push("/customer/register/changePassFirst");
                }
                // 原企业认证
                if (val==4){ 
                    this.showVisible = true
                    // axiosApi("/trader/findStoreInfo/select", {traderId: sessionStorage.traderId}, (res)=>{
                    //     this.$router.push(`${basePath}/enterpriseAuthentication?mobile=${res.data.data.mobile}&storeName=${res.data.data.storeName}&licenseNum=${res.data.data.licenseNum}`)
                    // }, 1, 10,err=>{
                    //     this.$message.error(err.data.message)
                    // })
                }
                // if (val==5){
                //     this.$router.push("/customer/pay/nameverify");
                // }
                // if (val==6){
                //     this.$router.push(`${basePath}/pay/bind`)
                // }

			}
		}
	}
</script>

<style lang="less">
	#accountSafe {
		.flex {
			display: flex;
			justify-content: space-between;
		}
		.lh32 {
			line-height: 32px;
		}
        .safe{
            &>div{
                border-top: none !important;
                &:first-child{
                    border-top: 1px solid #DDDDDD !important;
                }
            }
        }
	}
</style>