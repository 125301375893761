
<template>
  <div class="dialogTips">
    <!-- 添加付款信息 -->
    <el-dialog title="温馨提示" :visible="dialogVisible" label-width="70px" @close="closeDialog">
      <div class="dialogDiv">
        <div>{{unavailableMsg}}</div>
        <div v-if="disableInfo.length>0">
          <el-tag
            class="tagClass"
            v-for="item in disableInfo"
            :key="item.week"
            type="danger"
            effect="dark">
            {{ item.weekName }}:{{item.timeStart}}-{{item.timeEnd}}
          </el-tag>
          <div class="redText">*以上时间不可，如需付款请通过线下支付，联系业务员</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">返 回</el-button>
      </div>
    </el-dialog>

    
  </div>
</template>

<script>

export default {
  props:['showDialog','disableInfo','unavailableMsg'],
  data(){
    return {
      dialogVisible: false,
      loading: false,
      info: [],
    }
  },
  watch: {
    showDialog(value) {
      this.dialogVisible = value;
      if (value) {
      }
    },
    disableInfo: {
      handler: function (val) {
        if(val){
         
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    setInit(info){
    },
    closeDialog(){
      this.dialogVisible = false
      this.$emit('close')
    },
  }
}
</script>

<style lang="less">
  .dialogTips{
    .el-dialog__footer .el-button--default {
      margin-left: 0 !important; 
    }
    .dialogDiv{
      padding: 0 20px;
      box-sizing: border-box;
      .tagClass{
        margin: 10px;
      }
      .redText{
        font-size: 12px;
        color: red;
      }
    }
  }
   
</style>