<template>
	<div id="payMode">
		<div class="title-top bg-f">收款账号设置</div>
		<div class="ml-30 mt-30 mr-30 bg-f pay-main">
			<div class="main">
				<div class="main-con pt-20 pb-50">
					<div class="ml-30 mr-30 mt-20">
						<div class="el-width100 pb-20">
							<span class="font-16">银行收款</span>
							<el-button style="width: auto;padding: 0 15px;" @click="editBankDialog" icon="el-icon-plus" v-if="showButton('ADD',100048)" class="fr">添加收款账号</el-button>
						</div>
						<div class="mt-20">
								<el-table :data="tableData3" border tooltip-effect="dark" class="table-default">
										<el-table-column prop="bankUserName" label="用户名称" min-width="100">
										</el-table-column>
										<el-table-column prop="bankName" label="收款渠道" min-width="100">
										</el-table-column>
										<el-table-column prop="bankDeposit" label="开户支行" min-width="150">
										</el-table-column>
										<el-table-column prop="bankCard" label="账号" min-width="150">
										</el-table-column>
										<el-table-column label="收款二维码" min-width="80">
												<template slot-scope="scope">
														<div v-if="scope.row.qrCode">
																<el-button type="text"  size="small" @click="dialogVisible = true;dialogImageUrl = scope.row.qrCode">查看</el-button>

														</div>
														<div v-else>无</div>
												</template>
										</el-table-column>
										<el-table-column label="收款类型" min-width="80">
											<template slot-scope="{row}">
												<div>{{ row.useType==1?'通用账号':'特殊账号' }}</div>
											</template>
										</el-table-column>
										<el-table-column prop="settleType" label="结算方式" min-width="150">
											<template slot-scope="{row}">
												<div v-if="row.settleType">
													<span>{{row.settleType}}</span>
													<span>( {{row.settleType=="JSFS01_SYS"?'现金结算':'银行转账'}} )</span>
												</div>
											</template>
										</el-table-column>
											<el-table-column prop="moneyCertificate" label="凭证字" min-width="150">
												<template slot-scope="{row}">
													<div v-if="row.moneyCertificate">
														<span>{{row.moneyCertificate}}</span>
														<span>(
															<span v-if="row.moneyCertificate=='PRE001'">记</span>
															<span v-if="row.moneyCertificate=='PRE004'">转</span>
															<span v-if="row.moneyCertificate=='PING_PZZ4'">商业字</span>
															)
														</span>
													</div>
											</template>
										</el-table-column>
										<el-table-column prop="remark" label="备注" min-width="200">
										</el-table-column>
										<el-table-column label="操作" min-width="150">
												<template slot-scope="scope">
														<el-button v-if="showButton('EDIT',100048)" @click.native.prevent="editBankDialog(scope.$index, tableData3)" type="text" size="small">编辑</el-button>
														<el-button v-if="showButton('DEL',100048)" @click.native.prevent="deleteRow(scope.$index, tableData3)" type="text" size="small">删除</el-button>
												</template>
										</el-table-column>
								</el-table>
						</div>

					</div>
				</div>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt>
				</el-dialog>
				<el-dialog :title="isAdd ? '添加收款账号': '编辑收款账号'" :visible.sync="addPay" width="600px">
					<el-form :model="formPay" :rules="rules" ref="formPay">
						<el-form-item label="收款渠道:" prop="bank">
							<el-select size="small" filterable :filter-method="selectBankList" v-model="formPay.bank" placeholder="请选择收款渠道" clearable @change="changed">
								<el-option v-for="val in item" :key="val.id" :label="val.bankName" :value="val.bankName" ></el-option>
							</el-select>
						</el-form-item>
						<div v-if="bankType == 3">
							<el-form-item label="开户人:" prop="name">
								<el-input size="small" v-model.trim="formPay.name" auto-complete="off" placeholder="请输入账户名称" :maxlength='21'></el-input>
							</el-form-item>
							<el-form-item label="开户支行:" prop="smallBank">
								<el-input size="small" v-model.trim="formPay.smallBank" auto-complete="off" placeholder="请输入开户支行" :maxlength='17'></el-input>
							</el-form-item>
							<el-form-item label="银行卡号:" prop="bankNum">
								<el-input size="small" v-model.trim="formPay.bankNum" auto-complete="off" placeholder="请输入银行账号" :maxlength='26'></el-input>
							</el-form-item>
						</div>
						<div v-else>
							<el-form-item label="账号:"  prop="bankNum2" :rules="[
								{ required: true, message: '账号不能为空'},
								{ validator: checkData, trigger: 'blur'}]">
								<el-input size="small" v-model.trim="formPay.bankNum2" auto-complete="off" placeholder="手机号/邮箱地址/银行卡号" :maxlength='26'></el-input>
							</el-form-item>
							<el-form-item label="用户名称:" prop="name2">
								<el-input size="small" v-model.trim="formPay.name2" auto-complete="off" placeholder="请输入用户名称" :maxlength='21'></el-input>
							</el-form-item>
						</div>
						<el-form-item label="收款二维码:" >
							<multipleImgUpload @cloneDialogImageUrlList="getDialogImageUrlList" :preview="preview" :dialogImageUrlList="formPay.dialogImageUrlList" :max="max"></multipleImgUpload>
						</el-form-item>
						<!-- <el-form-item v-if="!isCityPartner" label="K3收款账号:" :rules="[{ required: rules.k3AccountNumber[0].required, message: '请输入K3收款账号', trigger: 'blur' }]" prop="k3AccountNumber">
							<el-input size="small" v-model.trim="formPay.k3AccountNumber" auto-complete="off" placeholder="请输入K3收款账号"></el-input>
						</el-form-item> -->
						<el-form-item label="备注:" prop="text">
							<el-input size="small" v-model.trim="formPay.text" auto-complete="off" placeholder="请输入备注" :maxlength='31'></el-input>
						</el-form-item>
						<el-form-item label="账号类型" prop="useType">
							<el-select size="small" v-model="formPay.useType" placeholder="请选择账号">
								<el-option label="通用账号" :value="1"></el-option>
								<el-option label="特殊账号" :value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="结算方式：" prop="settleType">
							<el-select size="small" v-model="formPay.settleType" placeholder="请选择结算方式">
								<el-option label="银行转账" value="09"></el-option>
								<el-option label="现金结算" value="JSFS01_SYS"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="凭证字：" prop="moneyCertificate">
							<el-select size="small" v-model="formPay.moneyCertificate" placeholder="请选择结算方式">
								<el-option label="记" value="PRE001"></el-option>
								<el-option label="转" value="PRE004"></el-option>
								<el-option label="商业字" value="PING_PZZ4"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="danger" v-if="isAdd" :loading="btnLoading" @click="addSub">确 定</el-button>
						<el-button type="danger" v-else :loading="btnLoading" @click="update">确 定</el-button>
						<el-button @click="addPay = false">取 消</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi } from "../../../api/api";
	export default {
		data() {
			return {
				changeFirst:true,
				showFlag : true,
				max:1,
				preview:false,
				dialogImageUrl:null,
				dialogVisible:false,
				btnLoading: false,
				value1: false,
				addPay: false,
				activeTab: '1',
				isAdd: true,
				item: [],
				tableData3: [],
				body: {
					traderId: sessionStorage.traderId,
					bankType: '',
					id: ''
				},
				formPay: {
					name: '',
					bank: '',
					smallBank: '',
					bankNum: '',
					name2: '',
					bankNum2: '',
					// k3AccountNumber: '',
					text: '',
					dialogImageUrlList:[],
					useType: 1,
					settleType: null,
					moneyCertificate: null,
				},
				bankType: '',//银行类型 1 支付宝 2 微信 3 银行卡
				oldBankType: '',//上一次选择的bankType
				rules: {
					name: [{
						required: true,
						pattern: /^[\u4e00-\u9fa5A-Za-z]{2,16}$/,
						message: '请输入2~16个汉字或字母',
						trigger: 'change'
					}],
					name2: [{
						required: true,
						pattern: /^[\u4e00-\u9fa5A-Za-z]{2,16}$/,
						message: '请输入2~16个汉字或字母',
						trigger: 'change'
					}],
					bank: [{
						required: true,
						message: '请选择开户银行',
						trigger: 'change'
					}],
					smallBank: [{
						required: true,
						pattern: /^[\u4e00-\u9fa5A-Za-z]{4,16}$/,
						message: '4~16位汉字或字母',
						trigger: 'change'
					}],
					bankNum: [{
						required: true,
						pattern: /^\d{0,25}$/,
						message: '请输入1~25位数字',
						trigger: 'change'
					}],
					bankNum2: [{
						required: true,
						message: '请输入账号',
						trigger: 'change'
					}],
					// k3AccountNumber: [{
					// 	message: '请输入正确的K3收款账号（1~30位）',
					// 	trigger: 'change'
					// }],
					text: [{
						max: 30,
						message: '请输入0~30位字符',
						trigger: 'change'
					}],
					useType: [{
						required: true,
						message: '请选择账号类型',
						trigger: 'change'
					}],
					
					settleType: [{
						required: true,
						message: '请选择账号类型',
						trigger: 'change'
					}],
					moneyCertificate: [{
						required: true,
						message: '请选择账号类型',
						trigger: 'change'
					}],
				},
			}
		},
		watch:{
			addPay(val){
				if(!val){
					this.$set(this.formPay,'dialogImageUrlList',[])
					this.showFlag = false;
				}else{
					this.showFlag = true;
				}
			}
		},
		computed:{
			isCityPartner(){
				return this.$store.state.clientType.isCityPartner;
			}
		},
		mounted() {
			this.$nextTick(function() {
				this.loading();
			})
		},
		methods: {
			checkData (rule, value, callback) {
				if (value) {
					if (/[\u4E00-\u9FA5]/g.test(value)) {
						callback(new Error('编码不能输入汉字!'));
					} else {
						callback();
					}
				}
				callback();
			},
			getDialogImageUrlList(val){
				this.formPay.dialogImageUrlList = val;
			},
			//图片校验
			beforeAvatarUpload(file) {
				let bool = this.isUploadImg(file);
				if (bool) {
					this.pictureLoading = true;
				}
				return bool;
			},
			//图片上传失败
			errFun(err) {

			},
			//图片
			handleAvatarSuccessList(res) {
				this.formPay.dialogImageUrlList.push({
					url: res.data[0]
				});
				this.pictureLoading = false;
			},
			//图片删除
			handleRemove(file, fileList) {
				this.formPay.dialogImageUrlList = fileList;
			},
			//图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			loading() {
				const api = '/trader/findSellerBankInfoList/select';
				const body = {
					traderId: this.body.traderId,
					allBank: true,
				};
				axiosApi(api, body, (res) => {
					this.tableData3 = res.data.data ? res.data.data : [];
				});
			},
			changed(){
				if(this.formPay.bank){
					this.item.forEach((item)=>{
						if(this.formPay.bank==item.bankName){
							this.bankType = item.bankType;
							if(!this.changeFirst){
								this.resetData(null,{
									name: this.formPay,
									data: ['name','name2','smallBank','bankNum','bankNum2','dialogImageUrl','text']
								})
								this.formPay.dialogImageUrlList = [];
							}
							this.oldBankType = this.bankType;
						}
					})
					this.showFlag = false;
				}else{
					this.resetData(null,{
						name: this.formPay,
						data: ['name','name2','smallBank','bankNum','bankNum2','dialogImageUrl','text']
					})
					this.formPay.dialogImageUrlList = [];
				}
				this.changeFirst = false;
			},
			deleteRow(index, rows) {
				this.$confirm('您是否要删除此条消息？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/trader/deleteSellerBankInfo/delete";
					const body = {
						id: this.tableData3[index].id
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 1000
						});
						rows.splice(index, 1);
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			editBankDialog(index, rows) {
				let isEdit = arguments.length == 2;
				if(!isEdit) this.bankType = 3
				//数据重置
				this.resetData(null,{
					name: this.formPay,
					data: ['name','name2','smallBank','bankNum','bankNum2',,'text']
				});
				this.changeFirst = true;
				this.body.id = "";
				this.isAdd = !isEdit;
				if(!!this.$refs.formPay) this.$refs.formPay.resetFields()

				this.body = Object.assign({}, this.body);
				this.formPay = Object.assign({}, this.formPay);
				if(isEdit){
					this.formPay.dialogImageUrlList = [{
						url:rows[index].qrCode
					}];
					if(rows[index].bankType != 3){ // bankType 不为3说明不是银行卡
						this.$set(this.formPay, 'name2', rows[index].bankUserName);
						this.$set(this.formPay, 'bankNum2', rows[index].bankCard);
					}else{
						this.$set(this.formPay, 'name', rows[index].bankUserName);
						this.$set(this.formPay, 'bankNum', rows[index].bankCard);
					}
					this.$set(this.body, 'id', rows[index].id);
					this.$set(this.formPay, 'bank', rows[index].bankName);
					this.$set(this.formPay, 'smallBank', rows[index].bankDeposit);
					// this.$set(this.formPay, 'k3AccountNumber', rows[index].k3AccountNumber);
					this.$set(this.formPay, 'text', rows[index].remark);
					this.$set(this.formPay, 'useType', rows[index].useType);
					this.bankType = rows[index].bankType;
				}else{
					this.resetData(null,{
						name: this.formPay,
						data: ['id','bank','name','name2','smallBank','bankNum','bankNum2','text']
					});
					this.formPay.dialogImageUrlList = [];
					this.bankType = 3;
				}

				this.addPay = true;
				//查询银行列表
				if(this.item.length == 0){
					this.selectBankList();
				}
			},
			//查询银行列表
			selectBankList(val){
				//检查JDE公司编码和JDE库位是否存在
				if(!val)axiosApi('/trader/findJdeInfoByTraderId/select', {
					'traderId': sessionStorage.getItem('traderId')
				}, (res) => {
					if(!res.data.data.erpType) {
						// this.rules.jdeNum[0].required = false;//jde科目号选填
						// this.rules.k3AccountNumber[0].required = false;//k3收款账号选填
					} else if (res.data.data.erpType === 1) {
						// this.rules.jdeNum[0].required = true;//jde科目号必填
					} else if (res.data.data.erpType === 2) {
						// this.rules.k3AccountNumber[0].required = true;//k3收款账号必填
					}
				});
				const api = "/foundation/bank/selectAll";
				axiosApi(api, {bankName: val ? val : ""}, (res) => {
					this.item = res.data.data.elements;
				}, 1, 100)
			},
			addSub() {
				this.$refs['formPay'].validate((valid) => {
					if(!valid) return false;
					const api = '/trader/insertSellerBankInfo/insert';
					const body = {
						traderId: this.body.traderId,
						bankType: this.bankType,
						bankUserName: this.bankType == 3 ? this.formPay.name : this.formPay.name2,
						bankName: this.formPay.bank,
						bankDeposit: this.formPay.smallBank,
						bankCard: this.bankType == 3 ? this.formPay.bankNum : this.formPay.bankNum2,
						qrCode:this.formPay.dialogImageUrlList[0] ? this.formPay.dialogImageUrlList[0].url : null,
						// k3AccountNumber: this.formPay.k3AccountNumber,
						remark: this.formPay.text,
						useType: this.formPay.useType,
						settleType: this.formPay.settleType,
						moneyCertificate: this.formPay.moneyCertificate,
					};
					this.handSave(api, body);
				})
			},
			update() {
				const api = '/trader/updateSellerBankInfo/update';
				const body = {
					id: this.body.id,
					bankType: this.bankType,
					bankUserName: this.bankType == 3 ? this.formPay.name : this.formPay.name2,
					bankName: this.formPay.bank,
					bankDeposit: this.formPay.smallBank,
					bankCard: this.bankType == 3 ? this.formPay.bankNum : this.formPay.bankNum2,
					qrCode:this.formPay.dialogImageUrlList[0] ? this.formPay.dialogImageUrlList[0].url : null,
					// k3AccountNumber: this.formPay.k3AccountNumber,
					remark: this.formPay.text,
					useType: this.formPay.useType,
					settleType: this.formPay.settleType,
					moneyCertificate: this.formPay.moneyCertificate,
				};
				this.handSave(api, body);
			},
			handSave(api, body){
				this.$refs.formPay.validate((valid) => {
					if(valid) {
						this.btnLoading = true;
						axiosApi(api, body, (res) => {
							this.$message({
								title: '成功',
								message: '恭喜你，操作成功！',
								type: 'success'
							});
							this.btnLoading = false;
							this.addPay = false;
							this.tableData3 = [];
							this.loading();
						}, 1, 10, (res) => {
							this.btnLoading = false;
							this.$message({
								title: '失败',
								message: res.data.message,
								type: 'info'
							});
						});
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#payMode {
		width: 100%;
		.el-table img{
			width: 100%;
			height: 100%;
		}
	}

	#payMode .pay-main {
		width: 100%;
		position: relative;
		right: 30px;
		top: -25px;
	}

	#payMode .el-width100 .el-button {
		width: 100px;
	}

	#payMode .main-tit {
		height: 50px;
		line-height: 50px;
	}

	#payMode .lh {
		line-height: 60px;
	}

	#payMode .main-con {
		line-height: 30px;
	}

	#payMode .el-form-item__error {
		left: 185px;
	}

	#payMode .table-default {

	}
</style>
