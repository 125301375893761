<template>
    <el-dialog id="payPopup" :visible.sync="visible" @close="changeVisible">
        <div id="pay-order" class="bg-f">
        <div class="pay-order-content">
            <div class="content pt-30" v-loading="loading">
                <div class="clearfix pb-30" v-if="!paymentOnBehalf">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>
                            <span class="font-20 color-3 lh-20 ">订单提交成功，请尽快付款！订单号：{{orderPrePayVo.parentOrderSn}}</span>
                            <div class="color-6 lh-14  mt-10" v-if="orderPrePayVo.orderStatus==1">请您在<span
                                    class="color-danger">{{orderPrePayVo.remainAutoCancleTime}}</span>内完成支付，否则订单会被自动取消
                            </div>
                        </div>
                        <div>
                            支付金额 <span class="color-danger font-30">￥<span>{{orderPrePayVo.needPayMoney ? orderPrePayVo.needPayMoney : 0 | fmoney}}</span></span>
                        </div>
                    </div>
                    <el-form class="formClass" label-width="100px">

                        <el-form-item label='是否计算积分' v-if="enableCalcIntegration">
                            <el-switch v-model="calcIntegration" />
                        </el-form-item>

                        <el-form-item label='是否计算久币' v-if="enableCalcCoin">
                            <el-switch v-model="calcCoin" />
                        </el-form-item>
                    </el-form>
                </div>
                <!-- paymentOnBehalf判断酒店是否能账期支付 -->
                <div class="clearfix pb-30" v-else>
                    <div>
                        <span class="font-20 color-3 lh-20 ">订单提交成功，请耐心等待工作人员处理！订单号：{{orderPrePayVo.parentOrderSn}}</span>
                    </div>
                    <div class="mt-30">
                        <span class="font-20">支付金额</span> <span class="color-danger font-30">￥<span>{{orderPrePayVo.needPayMoney ? orderPrePayVo.needPayMoney : 0 | fmoney}}</span></span>
                    </div>
                    <el-button class="mt-30" type="danger" @click="$router.push(basePath + '/purchaseOrderDetail')">确
                        定
                    </el-button>
                </div>
                <el-tabs v-model="payType" type="border-card" v-if="!paymentOnBehalf"  @tab-click="tabClick">
                    <template v-if="orderPrePayVo.orderStatus === 1 && !hotelStatus">
                        <!-- 货到付款 -->
                        <el-tab-pane v-if="orderPrePayVo.openCod" class="stock line-pay" name="1">
                            <span slot="label">货到付款</span>
                            <p class="font-14 lab-title mb-20">供货商信息</p>
                            <ul>
                                <li class="mb-10"><span class="lspan">供货商名称：</span><span class="rspan">{{orderPrePayVo.storeName || ''}}</span>
                                </li>
                                <li class="mb-10"><span class="lspan">联系人：</span><span class="rspan">{{orderPrePayVo.linkname || ''}}</span>
                                </li>
                                <li class="mb-10"><span class="lspan">联系电话：</span><span class="rspan">{{orderPrePayVo.tel || ''}}</span>
                                </li>
                                <li class="mb-50"><span class="lspan">联系地址：</span><span class="rspan">{{(orderPrePayVo.storeProvince || '') +" "+(orderPrePayVo.storeCity|| '')+" "+(orderPrePayVo.storeRegion|| '')+" "+(orderPrePayVo.storeAddr|| '') }}</span>
                                </li>
                            </ul>
                            <el-form :model="codForm" :rules="creditFules" ref="codForm">
                                <el-form-item label="手机号：" v-if="orderPrePayVo.openPayCode">
                                    <span class="font-16 fw">{{orderPrePayVo.vMobile | formatPhone}}</span>
                                </el-form-item>
                                <el-form-item label="短信验证码：" prop="codCode" class="stockSms"
                                              v-if="orderPrePayVo.openPayCode">
                                    <el-input size="small" v-model.trim="codForm.codCode" :maxlength="6"></el-input>
                                    <el-button class="ml-20 w-110" @click="getStockCode('212', 'sms0011')"
                                               :disabled="docTime>0">
                                        <span v-if="!docDis">发送验证码</span>
                                        <span v-else>重新获取</span>
                                        <span v-show="docTime>0&&docDis">{{docTime | seconds}}</span>
                                    </el-button>
                                </el-form-item>
                                <el-form-item label=" ">
                                    <el-button class="mt-10" :loading="btnLoading" type="primary" @click="codPay">确认支付
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <!-- 授信支付 -->
                        <!-- <el-tab-pane v-if="orderPrePayVo.isCredit === 0" class="credit-pay" name="2">
                            <span slot="label">授信支付<span v-if="orderPrePayVo.leftCreditMoney < orderPrePayVo.needPayMoney" class="color-9">(余额不足)</span></span>
                            <p class="">可用授信额度：{{ orderPrePayVo.leftCreditMoney }}，总额度：{{ orderPrePayVo.creditMoney }}</p>
                            <el-form class="credit-pay-content" :model="creditForm" :rules="creditFules" ref="creditForm" label-width='224px'>
                                <el-form-item label='手机号:'>
                                    <span>{{ creditForm.mobile }}</span>
                                </el-form-item>
                                <el-form-item label="短信验证码:" prop="creditVcode">
                                    <el-input class="w120 mr-20" :maxlength="6" v-model.trim="creditForm.creditVcode"></el-input>
                                    <el-button class="w115" @click="getCode" :disabled="smsTime>0||orderPrePayVo.leftCreditMoney<orderPrePayVo.needPayMoney">
                                        <span v-if="isFirst">获取验证码</span>
                                        <span v-else>重新获取</span>
                                        <span v-show="smsTime>0">{{smsTime | seconds}}</span>
                                    </el-button>
                                </el-form-item>
                                <el-form-item class="mt-50" label="">
                                    <el-button type="danger" :loading="btnLoading" @click="handleCommit(1)">确定</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane> -->
                    </template>
                    <!--线下支付-->
                    <el-tab-pane
                            v-if="orderPrePayVo.isOpenPay"
                            class="line-pay" name="3">
                        <span slot="label">线下支付<span v-if="true" class="color-9">(上传凭证)</span></span>
                        <p class="lab-title font-14 mb-20">选择汇款银行</p>
                        <ul class="bank-card-wrap mb-30">
                            <li class="bank-card cursor mr-20 mb-10 dib" style="position:relative;"
                                v-for="(n, index) in orderPrePayVo.findSellerBankInfoVOs" :key="index"
                                @click="bankCardClick(n, index)">
                                <div class="l-info">
                                    <div class="bank-card-title">
                                        <span>{{ n.bankName }}</span>
                                    </div>
                                    <div class="bank-card-content">
                                        <div class="font-12">
                                            <label for="">持卡人：</label>
                                            <span>{{ n.bankUserName }}</span>
                                        </div>
                                        <p class="font-14">{{ n.bankCard }}</p>
                                    </div>
                                    <div class="bank-deposit-w">
                                        <i :class="bankCardIndex == index ? 'opacity' : ''"
                                           class="iconfont icon-correct color-blue font-20"></i>
                                        <div v-if="n.bankType === 3" class="dib bank-deposit">{{n.bankDeposit}}</div>
                                    </div>
                                </div>
                                <div class="r-qrCode" style="position:absolute;bottom: 10px;right: 10px;" @mousemove="dialogVisible = true;dialogImageUrl = n.qrCode">
                                    <img style="width: 60px;height: 60px;" v-if="n.qrCode" :src="n.qrCode" alt="">
                                </div>
                            </li>
                        </ul>
                        <p class="lab-title font-14 mb-20">上传付款凭证<span class="color-9">（请核对汇款银行并上传该银行付款凭证单据，以便卖家核对，要求图片清晰可见，最大不超过5M）</span>
                        </p>
                        <div class="payment-voucher-info" v-if="orderPrePayVo.isOpenPay && orderPrePayVo.findSellerBankInfoVOs.length>0">
                            <div class="fl clearfix">
                                <!--<el-upload class="avatar-uploader fl" v-loading="imgLoading"
                                           action="/sunrise-gateway/oss/ossUpload" accept="image/*"
                                           :show-file-list="false" :on-success="handleAvatarSuccess"
                                           :before-upload="beforeAvatarUpload">
                                    <img v-if="paymentImg" :src="paymentImg" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    <span class="uploader-mongolia-layer font-16"
                                          :class='{opacity: paymentImg}'>点击重新上传</span>
                                </el-upload>-->
                                <multiple-img-upload @cloneDialogImageUrlList="getDialogImageUrlList" :dialogImageUrlList="dialogImageUrlList" :max="3" :width="180" :height="180" :multiple="true"></multiple-img-upload>
                            </div>
                            <div class="fr">
                                <!-- <img class="db" :src="`http://qrcode.shuogesha.com/qrcode?pixel=165_165&content=${ PROJECT.mcbs }/payOrder?traderId=${ traderId }%26sn=${ orderPrePayVo.sn }%26sum=${ orderPrePayVo.needPayMoney }`" width="165px" height="165px" /> -->
                                <vue-q-art :config="config" :downloadButton="false"></vue-q-art>
                                <p class="tac">您也可以扫码上传付款凭证</p>
                            </div>
                        </div>
                        <div class="line-pay-footer">
                            <el-button type='danger' :disabled="orderPrePayVo.isOpenPay && orderPrePayVo.findSellerBankInfoVOs.length==0" class="mt-50 mb-50" :loading="btnLoading" @click="handleCommit(2)">
                                确认支付
                            </el-button>
                            <el-button class="mt-50 mb-50 ml-20" :loading="btnLoading" @click="nextPay">稍后支付</el-button>
                        </div>
                    </el-tab-pane>
                    <!--线上支付-->
                    <!-- v-if="orderPrePayVo.isOpenOnlinePay" -->
                    
                    <!-- <el-tab-pane name="4" v-if="false">
                        <span slot="label"><i class="iconfont icon-internet"></i>线上支付</span>
                        <cashier :isChild="true" :buyerId="orderPrePayVo.buyerId" :calcIntegration="calcIntegration" :calcCoin="calcCoin"></cashier>
                    </el-tab-pane> -->

                    <!-- <el-tab-pane name="4" class="stock line-pay" v-if="orderPrePayVo.isOpenOnlinePay==1">
                        <span slot="label"><i class="iconfont icon-internet"></i>线上支付</span>
                        <div v-if="orderPrePayVo.onlinePayInfo&&!orderPrePayVo.onlinePayInfo.isDisable">
                            <span v-if="orderPrePayVo.onlinePayInfo">
                                {{orderPrePayVo.onlinePayInfo.unavailableMsg}}
                            </span>
                        </div>
                        <el-button class="mt-50 mb-50 ml-20" :disabled="orderPrePayVo.onlinePayInfo&&!orderPrePayVo.onlinePayInfo.isDisable">确认付款</el-button>
                    </el-tab-pane> -->

                    <!--账期支付-->
                    <el-tab-pane name="5" class="stock line-pay"
                                 v-if="orderPrePayVo.openAccountPeriod">
                        <span slot="label">账期支付<i v-if="!orderPrePayVo.accountsAvailable"
                                                  class="iconfont icon-inspector mid font-16 ml-10"></i></span>
                        <!-- 账期不可用原因 -->
                        <div v-if="!orderPrePayVo.accountsAvailable">
                            <div>{{orderPrePayVo.unAvailableMsg}}</div>
                             <!--去除chrome浏览器自动填充账号密码-->
                            <el-form class="mt-20" v-if="isOpenSellerSpecialCredit && orderPrePayVo.isNeedCheckSpecial" :model="stockForm" :rules="creditFules" ref="stockForm">
                                <el-checkbox  v-model="checkedCredit">开启信用特批</el-checkbox>
                                <div class="clearfix mt-20" v-if="checkedCredit">
                                    <el-form-item style="width: 0px;height:1px;overflow:hidden;margin:0;">
                                        <el-input type="text" v-model.trim="stockForm.code0" :maxlength="6" ></el-input>
                                    </el-form-item>
                                    <el-form-item style="width: 0px;height:1px;overflow:hidden;margin:0;">
                                        <el-input type="password" v-model="stockForm.pass0" :maxlength="16"></el-input>
                                    </el-form-item>
                                    <el-form-item label="特批人"  class="specialPerson mt-10" prop="specialPerson"  >
                                        <el-input auto-complete="off" :maxlength="10" v-model.trim="stockForm.specialPerson"></el-input>
                                    </el-form-item>
                                    <el-form-item label="特批密码" class="specialPerson" prop="specialPassword">
                                        <el-input auto-complete="off" :minlength="6" :maxlength="16" type="password" v-model.trim="stockForm.specialPassword"></el-input>
                                    </el-form-item>
                                    <el-form-item label="" v-if="checkedCredit && errMassage">
                                        <p class="color-danger ml-20">{{ errMassage }}</p>
                                    </el-form-item>
                                    <el-form-item label=" ">
                                        <el-button class="mt-10" :loading="btnLoading" type="primary" @click="stockPay">
                                            确认支付
                                        </el-button>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </div>
                        <!-- 账期可用 -->
                        <template v-else>
                            <p class="mb-10">{{orderPrePayVo.availableMsg}}</p>
                            <p class="font-14 lab-title mb-20">区域信息</p>
                            <ul>
                                <li class="mb-10"><span class="lspan">区域名称：</span><span class="rspan">{{orderPrePayVo.storeName || ''}}</span>
                                </li>
                                <li class="mb-10"><span class="lspan">联系人：</span><span class="rspan">{{orderPrePayVo.linkname || ''}}</span>
                                </li>
                                <li class="mb-10"><span class="lspan">联系电话：</span><span class="rspan">{{orderPrePayVo.tel || ''}}</span>
                                </li>
                                <li class="mb-50"><span class="lspan">联系地址：</span><span class="rspan">{{(orderPrePayVo.storeProvince || '') +" "+(orderPrePayVo.storeCity|| '')+" "+(orderPrePayVo.storeRegion|| '')+" "+(orderPrePayVo.storeAddr|| '') }}</span>
                                </li>
                            </ul> 
                            <!-- <el-checkbox v-model="checkedCredit">开启信用特批</el-checkbox>
                            <div class="clearfix mt-20" v-if="checkedCredit">
                                <el-form :inline="true" class="fl goodsForm" label-width="80px" :rules="rulesCredit" :model="creditForm" ref="creditForm">
                                    
                                    <el-form-item style="width: 0px;height:1px;overflow:hidden;margin:0;">
                                        <el-input type="text" v-model.trim="creditForm.code0" :maxlength="6" ></el-input>
                                    </el-form-item>
                                    <el-form-item style="width: 0px;height:1px;overflow:hidden;margin:0;">
                                        <el-input type="password" v-model="creditForm.pass0" :maxlength="16"></el-input>
                                    </el-form-item>
                                    <el-form-item label="特批人" prop="specialPerson" style="margin-left: -8px;">
                                        <el-input auto-complete="off" :maxlength="10" v-model.trim="creditForm.specialPerson"></el-input>
                                    </el-form-item>
                                    <br/>
                                    <el-form-item label="特批密码" prop="specialPassword">
                                        <el-input auto-complete="off" :minlength="6" :maxlength="16" type="password" v-model.trim="creditForm.specialPassword"></el-input>
                                    </el-form-item>
                                    <br>
                                    <el-form-item label="" v-if="checkedCredit && errMassage">
                                        <p class="color-danger ml-20">{{ errMassage }}</p>
                                    </el-form-item>
                                </el-form>
                            </div> -->
                            <el-form :model="stockForm" :rules="creditFules" ref="stockForm"> 
                                <el-form-item label="手机号：" v-if="orderPrePayVo.openPayCode && !topay">
                                    <span class="font-16 fw">{{orderPrePayVo.vMobile | formatPhone}}</span>
                                </el-form-item>
                                <el-form-item label="短信验证码：" prop="stockCode" class="stockSms"
                                              v-if="orderPrePayVo.openPayCode && !topay">
                                    <el-input size="small" v-model="stockForm.stockCode" :maxlength="6"></el-input>
                                    <el-button class="ml-20 w-110" @click="getStockCode('3', 'sms0010')"
                                               :disabled="stockTime>0">
                                        <span v-if="!disabled">发送验证码</span>
                                        <span v-else>重新获取</span>
                                        <span v-show="stockTime>0&&disabled">{{stockTime | seconds}}</span>
                                    </el-button>
                                </el-form-item>
                               
                                <el-form-item label=" ">
                                    <el-button class="mt-10" :loading="btnLoading" type="primary" @click="stockPay">
                                        确认支付
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane name="7" class="stock line-pay" v-if="orderPrePayVo.isOpenLargePay==1">
                        <span slot="label"><i class="iconfont icon-internet"></i>大额支付</span>
                        <div v-if="orderPrePayVo.largePayInfo&&orderPrePayVo.largePayInfo.isDisable">
                            <el-card class="box-card infoTitle">
                                <div class="payInfo alignCenter">
                                <span>大额支付</span>
                                <span>支付<span style="color: red;margin-left:20px;font-size:20px">￥<span>{{orderPrePayVo.needPayMoney ? orderPrePayVo.needPayMoney : 0 | fmoney}}</span></span></span>
                                </div>
                            </el-card>
                            <!-- 付款信息 -->
                            <PaymentInfo v-if="!submitPay" :buyerId="orderPrePayVo.buyerId" :showAdd="false" @change="changeInfo"/>
                            <!-- 转账信息 -->
                            <TransferInfo v-else :info="info"/>
                        </div>
                        <div v-else>
                            <span v-if="orderPrePayVo.largePayInfo">
                                {{orderPrePayVo.largePayInfo.unavailableMsg}}
                            </span>
                        </div>

                        <div style="margin-top:30px">
                            <el-button type="primary" v-if="!submitPay" :disabled="orderPrePayVo.largePayInfo&&!orderPrePayVo.largePayInfo.isDisable" @click="submit">确认付款</el-button>
                        </div>
                    </el-tab-pane>
                    <div v-if="!orderPrePayVo.isOpenOnlinePay && !orderPrePayVo.isOpenPay && !orderPrePayVo.openAccountPeriod">
                        {{ orderPrePayVo.unAvailableMsg }}
                    </div>
                </el-tabs>
            </div>

        </div>
        <el-dialog :visible.sync="imgVisible">
            <img width="100%" :src="dialogImageUrl" alt>
        </el-dialog>
    </div>
    </el-dialog>
</template>


<script>
    import md5 from 'md5'
    import {axiosApi, basePath} from "../api/api"
    import cashier from '~/views/shop/cashier.vue';
    import VueQArt from 'vue-qart';
    import imagePath from '~/assets/title.png';
    import PaymentInfo from '~/components/payment/paymentInfo.vue'
    import TransferInfo from '~/components/payment/transferInfo.vue'
    import { orderLargePay, getSelectBySn } from "~/api/paymentSet";
    export default {
        name:'payPopup',
        props: ["dialogvisible",],
        data() {
            const checkName = (rule, value, callback) => {
				const re = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,10}$/ig;
				if(!re.test(value)){
					callback(new Error('请输入特批人名称'))
				}else{
					callback()
				}
			}
			const checkPassWord = (rule, value, callback) => {
				const reg = /^[^\u4e00-\u9fa5]{6,16}$/ig;
				if(!reg.test(value)){
					callback(new Error('请输入正确的密码'))
				}else{
					callback()
				}
			}
            const codeFule = [{
                required: true,
                pattern: /^\d{6}$/,
                message: '请输入6位验证码',
                min: 6,
                max: 6,
                trigger: 'blur'
            }]
            return {
                submitPay: false,
                visible: false,
                loading: false,
                btnLoading: false,
                imgVisible:false,
                dialogImageUrl:'',
                paymentOnBehalf:false,
                isOpenSellerSpecialCredit:false,//是否已开启信用特批
                errMassage:null,
                checkedCredit:false,//是否选择信用特批
                dialogVisible:false,
                payType: '1',//酒店账期支付
                creditForm: {
                    mobile: sessionStorage.getItem('mobile'),
                    creditVcode: null, //验证码
                },
                stockForm: { // 账期支付 表单
                    stockCode: null, //账期支付 验证码
                    specialPerson:'',
					specialPassword:'',
                },
                codForm: {
                    codCode: null
                },
                phone: "",
                disabled: false, //账期支付 按钮是否可点
                stockTime: 0, //账期支付 重新发送验证码剩余时间
                creditFules: {
                    creditVcode: codeFule,
                    stockCode: codeFule,
                    codCode: codeFule,
                    specialPerson:[
						{ required: true, message: "请输入特批人名称",trigger:'blur'},
						{ validator:checkName,trigger:'blur' }
					],
					specialPassword:[
						{ required: true, message: "请输入特批密码" },
						{ validator:checkPassWord,trigger:'blur' }
					]
                },
                bankCardIndex: 0,
                bankInfo: {},//选择的银行卡信息
                paymentImg: null,//付款凭证 img
                dialogImageUrlList:[],
                radio: 1, // 单选框
                value: null,
                basePath: basePath,
                paymentVoucherVisible: false, //添加付款凭证
                problemVisible: false, //问题反馈
                issue: null, //问题string
                orderPrePayVo: {}, //订单预支付页面
                fileList: [], //显示的图片
                form: {
                    bankItem: null,
                    paymentImg: null, //付款凭证 img
                    id: null, //select 选中
                },
                dialogVisible: false,
                dialogImageUrl: '',
                imgLoading: false,
                traderId: null, //供应商ID
                smsTime: 0,
                isFirst: true,
                messageCode: {}, // 验证手机号是否正确
                creditVcode: null, //验证码
                sms: {
                    validate: true
                },
                isOpenOnlinePay: false, //商户线上支付开关查询
                config: {
                    value: "",
                    imagePath: imagePath,
                    filter: '',
                    size: 165
                },
                hotelStatus: sessionStorage.getItem("isHotel") === '1', //酒店业务状态 (只有账期支付)
                docDis: false, // 货到付款获取验证码按钮控制
                docTime: 0, // 货到付款倒计时
                canAccountPeriod: false, // 判断账期是否可用
                seller:'1',
                topay:'1',
                enableCalcIntegration: false, // 禁用是否计算积分
                calcIntegration: true, // 是否计算积分
                enableCalcCoin: false, // 禁用是否计算久币
                calcCoin: true, // 是否计算久币
                payform:{
                    orderSn: null,
                    // paymentBankCode: null,
                    // paymentBankName: null,
                    // paymentBankNo:null,
                    // paymentUserName: null,
                    // paymentLocationNo: null,
                    // paymentLocationName: null,
                    paymentBankId:null,
                },
                submitPay: false,
                info: null,
            }
        },
    watch: {
        dialogvisible:{
            handler:function(val){
                this.visible = val
                if(val){
                    this.$nextTick(() => {
                        this.changeCredit();
                        this.prepayOrderInfo()
                    })
                }
            },
            deep: true,
            immediate: true
        },
    },

    components: {
        cashier,
        VueQArt,
        PaymentInfo,
        TransferInfo
    },

    computed: {
        isDealerUser() {
            return this.$store.state.clientType.isDealerUser;
        },
    },

    mounted(){
        
    },
    filters: {
        seconds(value) {
            return "(" + value + "s)";
        },
        formatPhone(val = "") { //手机号格式化
            val = val.substr(0, 3) + " " + val.substr(3, 4) + " " + val.substr(7);
            return val;
        }
    },

    methods: {
        tabClick(val){
            if(this.payType=='7'){
                // 大额支付
                var data = {
                    outSn:this.orderPrePayVo.parentOrderSn,
                    outType:'order'
                }
                this.getSelectBySn(data)
            }
        },
        submit(){
            this.payform.orderSn = this.orderPrePayVo.parentOrderSn
            if(!this.payform.paymentBankId){
                this.$message.error('请选择付款银行！');
                return
            }
            this.getLargePay(this.payform)
        },
        getSelectBySn(data){
            getSelectBySn(data).then(res=>{
                if(res){
                    if (res.paySuccess) {
                         this.$message({
                            message:'支付已完成',
                            type:'success'
                        })
                        this.visible = false;
                        this.$emit('getChild',false)
                    }else{
                        this.info = res 
                        this.submitPay = true 
                    }
                }else{
                    this.submitPay = false 
                }
            }).catch(err=>{

            })
        },
        getLargePay(data){
            this.loading = true
            orderLargePay(data).then(res=>{
                if(res){
                    this.info = res 
                    this.submitPay = true 
                }
                this.loading = false
            }).catch(err=>{
                this.loading = false
            })
        },
        changeInfo(info){
            this.payform.paymentBankId= info.id
            // this.payform.paymentBankCode= info.bankCode
            // this.payform.paymentBankName= info.bankShortName
            // this.payform.paymentBankNo= info.bankNo
            // this.payform.paymentUserName= info.bankUserName
            // if(info.cardType==0){
            //     this.payform.paymentLocationNo= info.bankLocationNo
            //     this.payform.paymentLocationName= info.bankLocationName
            // }
        },
        changeVisible(){
            this.visible = false;
            // 初始化
            this.enableCalcIntegration = false // 禁用是否计算积分
            this.calcIntegration = true // 是否计算积分
            this.enableCalcCoin = false // 禁用是否计算久币
            this.calcCoin = true // 是否计算久币
            this.$emit('getChild',false)
            clearTimeout(this.orderPrePayVo.clearTime)
        },
        //是否开启信用特批
        changeCredit(){
			axiosApi('/trader/sellerSpecialCredit/select',{isSetNull:true},(res) => {
				if(res.data.data){
					this.isOpenSellerSpecialCredit = true;
				}
			},1,10,(err) => {
				this.$message.warning(err.data.message)
			})
		},
        getDialogImageUrlList(val){
            console.log(val,'vovofl');
            this.paymentImg = val;
            // this.formPay.dialogImageUrlList = val;
        },
        // 货到付款
        codPay() {
            this.$refs.codForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true //按钮设置loading状态
                    this.loading = true
                    let body = {
                        orderSn: this.orderPrePayVo.parentOrderSn,
                        payMethod: 5, // 支付方式
                        calcIntegration: this.calcIntegration, // 是否计算积分
                        calcCoin: this.calcCoin, // 是否计算久币
                    }
                    if (this.orderPrePayVo.openPayCode) {// 开通短信验证
                        body.creditVcode = this.codForm.codCode
                        body.mobile = this.orderPrePayVo.vMobile
                    }
                        // this.btnLoading = false //按钮设置loading状态
                        // this.loading = false
                        // this.visible = false;
                        // this.$emit('getChild',false)
                    axiosApi("/anon/order/cod/pay", body, res => {
                        this.$message({
                            message:'支付成功',
                            type:'success'
                        })
                        this.btnLoading = false //按钮设置loading状态
                        this.loading = false
                        this.visible = false;
                        this.$emit('getChild',false)
                        // let timer = setTimeout(() => {
                        //     clearTimeout(timer)
                        //     this.$router.go(0)
                        // }, 1000);
                        
                        
                        // this.$router.push(basePath + '/paySuccess')
                    }, null, null, err => {
                        this.btnLoading = false //按钮设置loading状态
                        this.loading = false
                        // this.$message({type: 'error', message: err.data.message})
                    })
                }
            })
        },
            stockPay() { //账期支付 确认支付
                this.$refs.stockForm.validate((valid) => {
                    if (valid) {
                        this.btnLoading = true //按钮设置loading状态
                        this.loading = true
                        let body = {
                            orderSn: this.orderPrePayVo.parentOrderSn,
                            payMethod: 6, // 支付方式
                            calcIntegration: this.calcIntegration, // 是否计算积分
                            calcCoin: this.calcCoin, // 是否计算久币
                        }
                        if (this.orderPrePayVo.openPayCode && !this.topay) {// 开通短信验证
                            body.vCode = this.stockForm.stockCode
                            body.mobile = this.orderPrePayVo.vMobile
                        }else {
                            body.source = 2
                        }
                        if(this.checkedCredit){
                            body.specialPassword = md5(this.stockForm.specialPassword);
                            body.specialPerson = this.stockForm.specialPerson;
                            body.isNeedCheckSpecial = true;
                        }
                        axiosApi("/anon/order/hotel/pay", body, res => {
                            this.btnLoading = false //按钮设置loading状态
                            this.loading = false;
                            if(sessionStorage.getItem('orderInfo') && (JSON.parse(sessionStorage.getItem('orderInfo')).length>0)){
                                this.getStatus(1);
                            }else{
                                const sellerSn = this.seller ? this.orderPrePayVo.parentOrderSn : ''
                                this.$message({
                                    message:'支付成功',
                                    type:'success'
                                })
                                this.visible = false;
                                this.$emit('getChild',false)
                                let timer = setTimeout(() => {
                                    clearTimeout(timer)
                                    this.$router.go(0)
                                }, 1000);
                                
                                // this.$router.replace(basePath + '/paySuccess' + '?m=' + this.orderPrePayVo.needPayMoney+'&sn='+sellerSn)
                            }
                        }, null, null, err => {
                            this.btnLoading = false //按钮设置loading状态
                            this.loading = false
                            // this.$message({type: 'error', message: err.data.message})
                        })
                    }
                })
            },
            /*获取账期支付或货到付款短信验证码*/
            getStockCode(smsTemplate, smsType) {
                const body = {
                        "mobile": this.orderPrePayVo.vMobile,
                        "smsTemplate": smsTemplate, // 账期支付模板类型 3和4 都行 尊敬的用户，您的验证码为${code}，本验证码${expire}分钟内有效，感谢使用！
                        "smsType": smsType,
                        "appId": "2",
                        "smsPurposeType": 1
                    },
                    type = smsTemplate === '212' ? 3 : 1 // 1 账期支付，3 货到付款
                this.smsCode(body, type)
            },
           /* onlinePay(traderId) {//查询商户线上支付开关
                axiosApi('/trader/sellerinfo/isOpenOnlinePay', {"traderId": traderId}, res => {
                    this.isOpenOnlinePay = res.data.data === 1
                })
            },*/
            bankCardClick(item, index) {
                this.bankCardIndex = index;
                this.bankInfo = item;
            },
            onlinePayment() { //在线支付
                this.$router.push(basePath + '/cashier')
            },
            /*获取短信验证码*/
            getCode() {
                if (this.orderPrePayVo.leftCreditMoney < this.orderPrePayVo.needPayMoney) {
                    this.$alert('您的授信额度不足！', '提示信息', {
                        confirmButtonText: '确定'
                    });
                } else {
                    const body = {
                        "mobile": sessionStorage.getItem('mobile'),
                        "smsTemplate": "10",
                        "smsType": "sms0010",
                        "appId": "2",
                        "smsPurposeType": 1,
                        "fields": {"money": this.orderPrePayVo.needPayMoney}
                    }
                    this.smsCode(body, 2)
                }
            },
            smsCode(body, type) {
                axiosApi('/unlogin/sms/immediatecode', body, (res) => {
                    if (type === 1) { // 账期支付
                        this.stockTime = 59 	//重新发送验证码时间
                        this.disabled = true	//发送验证码不可点
                        let timeSms1 = setInterval(() => {
                            this.stockTime--
                            if (this.stockTime == 0) {
                                clearTimeout(timeSms1)
                            }
                        }, 1000)
                    } else if (type === 2) { // 授信支付
                        this.smsTime = 59
                        this.isFirst = false
                        let timeSms2 = setInterval(() => {
                            this.smsTime--
                            if (this.smsTime == 0) {
                                clearTimeout(timeSms2)
                            }
                        }, 1000)
                    } else { // 货到付款
                        this.docTime = 59
                        this.docDis = true
                        let timeSms3 = setInterval(() => {
                            this.docTime--
                            if (this.docTime == 0) {
                                clearTimeout(timeSms3)
                            }
                        }, 1000)
                    }
                }, 1, 10, (res) => {
                    this.$message({type: 'error', message: '获取失败，请重试', duration: 1000})
                })
            },
            // 稍后支付
            nextPay() {
                this.visible = false;
                this.$emit('getChild',false)
                // this.$router.push(basePath + '/store/purchaseOrder')
            },
            /*授信支付接口*/
            orderCreditPay(body) {
                this.btnLoading = true;
                axiosApi('/anon/order/creditPay', body, (res) => {
                    this.$message({type: 'success', message: '授信支付成功', duration: 2000})
                    this.btnLoading = false;
                    setTimeout(() => {
                        this.$router.push(basePath + '/store/purchaseOrder')
                    }, 1000)
                }, 1, 10, (res) => {
                    this.btnLoading = false;
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            /*确认支付*/
            handleCommit(type) {
                if (type == 2) {/*线下支付*/
                    if (!this.paymentImg || this.paymentImg.length<=0) {
                        // this.$message.info('请上传有效清晰地支付凭证！');
                        // 10/18优化中，取消必填凭证
                        let body = {
                            orderSn: this.orderPrePayVo.parentOrderSn,//订单号
                            amount: this.orderPrePayVo.needPayMoney,//支付金额
                            calcIntegration: this.calcIntegration, // 是否计算积分
                            calcCoin: this.calcCoin, // 是否计算久币
                            urls: [],//凭证图片
                            traderBankId: this.bankInfo.id,//银行卡ID
                            bankUserName: this.bankInfo.bankUserName,//持卡人姓名
                            incomeAccountNum: this.bankInfo.bankCard,//银行卡号
                            incomeAccountName: this.bankInfo.bankDeposit,//银行支行
                        }
                        this.addApplyRemittance(body)
                    } else {
                        let imgArr = this.paymentImg.map(v => {
                            return v.url
                        });
                        let body = {
                            orderSn: this.orderPrePayVo.parentOrderSn,//订单号
                            amount: this.orderPrePayVo.needPayMoney,//支付金额
                            calcIntegration: this.calcIntegration, // 是否计算积分
                            calcCoin: this.calcCoin, // 是否计算久币
                            urls: imgArr,//凭证图片
                            traderBankId: this.bankInfo.id,//银行卡ID
                            bankUserName: this.bankInfo.bankUserName,//持卡人姓名
                            incomeAccountNum: this.bankInfo.bankCard,//银行卡号
                            incomeAccountName: this.bankInfo.bankDeposit,//银行支行
                            
                        }
                        this.addApplyRemittance(body)
                    }
                } else if (type == 1) {/*授信支付*/
                    this.$refs.creditForm.validate((valid) => {
                        if (valid) {
                            this.orderCreditPay({
                                orderSn: this.orderPrePayVo.sn,
                                payMethod: 2,
                                creditVcode: this.creditForm.creditVcode,
                                calcIntegration: this.calcIntegration, // 是否计算积分
                                calcCoin: this.calcCoin, // 是否计算久币
                            })
                        }
                    })
                }
            },
            /*remittance.apply.add 插入汇款凭证*/
            addApplyRemittance(body) {
                this.btnLoading = true
                axiosApi('/anon/order/remittance/applyAdd', body, (res) => {
                    this.btnLoading = false;
                    this.$message({type: 'success', message: '我们已经收到您的付款凭证信息，将尽快为您审核', duration: 2000});
                    /*线下支付*/
                    this.visible = false;
                    this.$emit('getChild',false)
                    this.$emit('paySuccess',true)
                    // let timer = setTimeout(() => {
                    //     clearTimeout(timer)
                    //     this.$router.go(0)
                    // }, 1000);
                    // if(sessionStorage.getItem('traderId') != this.orderPrePayVo.buyerId){
                    //     if(this.isDealerUser){
                    //         this.$router.push(basePath + '/agencyOrder');
                    //         return;
                    //     }else{
                    //         this.$router.push(basePath + '/order');
                    //         return
                    //     }
                    // }
                    // if(!sessionStorage.getItem('orderInfo')){
                    //     this.$router.push(basePath + '/store/purchaseOrder')
                    // }else{
                    //     this.getStatus();
                    // }
                }, 1, 10, (res) => {
                    this.btnLoading = false
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            //查看订单状态
            getStatus(flag){
                let arr = JSON.parse(sessionStorage.getItem('orderInfo'));
                let array = arr.map(v =>v.orderSn);
                axiosApi('/order/payStatus/query',{ orderSns:array },res => {
                    console.log(res,'status');
                    if(res.data.data.isNeedToPay){
                        this.$router.push(basePath + '/prePay');
                        // setTimeout(() => {
                        //     window.location.reload()
                        // },0)
                    }else {
                        sessionStorage.removeItem('orderSn');
                        sessionStorage.removeItem('orderInfo');
                        if(flag){
                            this.$message({
                                    message:'支付成功',
                                    type:'success'
                                })
                            this.visible = false;
                            this.$emit('getChild',false)
                            let timer = setTimeout(() => {
                                clearTimeout(timer)
                                this.$router.go(0)
                            }, 1000);
                            // this.$router.push(basePath + '/paySuccess' + '?m=' + this.orderPrePayVo.needPayMoney)
                        }else{
                            this.$router.push(basePath + '/store/purchaseOrder');
                        }
                    }
                },err => {
                    this.$message.warning(err.data.message)
                })
            },
            /*支付页面信息接口*/
            prepayOrderInfo() {
                this.loading = true;
                let body ={
                    orderSn: sessionStorage.orderSn
                };
                if(this.topay){
                    body.operatorType = 2
                }
                axiosApi('/anon/order/prepay/info', body, (res) => {
                    console.log(res,'prepay');
                    this.loading = false;

                    if(res.data.data.orderStatus == 2){
                        this.$message({
                            type:'success',
                            message:'订单已支付成功，页面即将跳转'
                        })
                        setTimeout(() => {
                            const isSelf = this.buyerId == sessionStorage.getItem('traderId')
                            const url = isSelf ? '/purchaseOrderDetail' :  `/supplierOrderDetail?sn=${sessionStorage.orderSn}&type=1`
                            this.$router.push(basePath + url)
                        },1000)
                    }
                    this.isOpenOnlinePay = res.data.data.isOpenOnlinePay;
                    this.enableCalcIntegration = res.data.data.enableCalcIntegration
                    this.enableCalcCoin = res.data.data.enableCalcCoin
                    // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
                    this.orderPrePayVo = res.data.data;
                    if(this.orderPrePayVo.isOpenPay && this.orderPrePayVo.findSellerBankInfoVOs.length<=0){
                        this.$message.warning('商家未设置收款账户,无法上传凭证')
                    }
                    sessionStorage.isParentPurchaseOrderType = this.orderPrePayVo.parentOrSon == 3 ? 2 : 1
                    /* 如果开通货到付款则默认货到付款
                        openCod	货到付款
                        openAccountPeriod	账期支付
                        creditAmount	最高额度
                        usedAmount	已用额度
                        accountAmount	可用额度
                        exceedOverDays	逾期天数
                        checkOverDays 是否检查逾期天数
                        checkAmount 是否检查账期额度
                    */
                    //正常并且订单状态为未付款且不支持货到付款，orderStatus 1-待付款 2-待发货 若订单状态 >= 2 为授信还款，
                    if (this.orderPrePayVo.orderStatus === 1 && !this.orderPrePayVo.openCod) {
                        // 账期开通且可用 或 账期开通且线下支付不可用
                        if (this.orderPrePayVo.openAccountPeriod ) {
                            this.payType = '5'; // 账期支付
                        }else if (this.orderPrePayVo.isOpenPay ) {
                            this.payType = '3'; // 线下支付
                        } 
                        // else {
                        //     // this.payType = '4'; // 线上支付
                        //     // 代客下单去支付不支持线上
                        // }
                        if(this.orderPrePayVo.isOpenPay && this.orderPrePayVo.openAccountPeriod && this.orderPrePayVo.accountsAvailable) {
                            this.payType = '3'
                            console.log('ddd')
                        }
                    }
                    //  else if (this.orderPrePayVo.orderStatus >= 2) { // 授信还款,只能使用线下或线上支付
                    // 	if (this.orderPrePayVo.findSellerBankInfoVOs && this.orderPrePayVo.findSellerBankInfoVOs.length > 0) {
                    // 		this.payType = '3'; // 线下支付
                    // 	} else {
                    // 		this.payType = '4'; // 线上支付
                    // 	}
                    // }

                    if (this.orderPrePayVo) {
                        // this.traderId = this.orderPrePayVo.orderItemPos[0].sellerId
                        this.traderId = this.orderPrePayVo.sellerId
                        // this.onlinePay(this.traderId)
                    }
                    if (this.orderPrePayVo.findSellerBankInfoVOs !== null) {
                        if (this.orderPrePayVo.findSellerBankInfoVOs.length > 0) {
                            this.bankInfo = this.orderPrePayVo.findSellerBankInfoVOs[0];
                        }
                    }
                    let time = parseInt(this.orderPrePayVo.remainAutoCancleTime / 1000)
                    this.orderPrePayVo.remainAutoCancleTime = this.timeString(time)
                    console.log(time, this.timeString(time))
                    this.orderPrePayVo.clearTime = null
                    this.orderPrePayVo.clearTime = setInterval(() => {
                        time--
                        this.orderPrePayVo.remainAutoCancleTime = this.timeString(time)
                        if (time <= 0) {
                            clearTimeout(this.orderPrePayVo.clearTime)
                        }
                    }, 1000)
                    this.config.value = `${this.PROJECT.mcbs}/payOrder?traderId=${this.traderId}&sn=${this.orderPrePayVo.parentOrderSn}&sum=${this.orderPrePayVo.needPayMoney}`;
                }, 1, 10, (err) => {
                    // this.$message(err.data.message)
                    this.loading = false
                })
            },
            cancelOrder(body) {
                this.loading = true
                axiosApi('/anon/order/cancel', body, (res) => {
                    this.loading = false
                    this.$message({
                        type: 'success',
                        message: '操作成功！',
                        duration: 1000
                    })
                    this.queryOrderList(this.form)
                    this.findSellerJurisdiction()
                    this.anonOrderCount()
                }, 1, 10, (res) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            beforeAvatarUpload(file) { //上传之前图片的判断
                let bool = this.isUploadImg(file)
                if (bool) {
                    this.imgLoading = true
                }
                return bool
            },
            handleAvatarSuccess(res, file) { //长传付款凭证  成功
                this.imgLoading = false
                this.paymentImg = res.data[0];
            }
        }
}

</script>
<style lang='less'>
    #payPopup {
        >.el-dialog {
            width: 1200px !important;
            min-height: 600px;
        }
        .formClass{
            margin-top:20px;
            .el-form-item {
                margin: 0;
            }
        }
        
    }
    .el-message {
        z-index: 100000;
    }

    #pay-order {
        /*隐藏*/

        .hide {
            display: none !important;
        }

        .onlineBtn {
            background-color: #228B22;
            color: #fff;
            border-color: #228B22;

            &:hover {
                background-color: #218868;
                border-color: #218868;
            }
        }

        canvas {
            width: 165px !important;
            height: 165px !important;
        }

        .ml-95 {
            margin-left: 95px;
        }

        .mr-95 {
            margin-right: 95px;
        }

        .pay-order-content {
            min-height: 700px;
            width: 1000px;
            // margin: 0 auto;
            // padding: 0 45px 50px;
            box-sizing: border-box;
            /*账期支付方式*/

            .stock {
                ul {
                    .ml-150 {
                        margin-left: 150px;
                    }

                    li {
                        .lspan {
                            display: inline-block;
                            width: 100px;
                            text-align: right;
                            letter-spacing: 0;
                        }

                        .rspan {
                            display: inline-block;
                            padding-left: 10px;
                        }
                    }
                }

                .stockSms {
                    .el-form-item__label:before {
                        display: none;
                    }

                    .el-form-item__error {
                        margin-left: 185px;
                    }
                }

                .w-110 {
                    width: 110px;
                }
            }

            .specialPerson .el-form-item__label {
                width: 90px;
            }
            .specialPerson .el-form-item__error{
                left: 90px;
            }
            .issue {
                color: #333333;

                &:hover {
                    color: #FF4201;
                }
            }

            /* 修改el-tab */

            .content {
                width: 1000px;
                margin: 0 -36px;

                .el-tabs__nav-wrap {
                    margin-bottom: -2px;
                }

                .el-tabs__header {
                    padding-left: 0;
                    border-bottom: 1px solid #ddd;
                }

                .el-tabs__item {
                    margin-left: 0;
                    .icon-internet {
                        color: #1FA0DD;
                        font-size: 20px;
                        vertical-align: middle;
                    }
                }

                .el-tabs__item:hover {
                    border-color: #20a0ff;
                    color: #333;
                }

                .el-tabs__item.is-disabled {
                    border-color: #ddd;
                    color: #999;

                    i {
                        color: #999;
                        cursor: pointer;
                    }
                }

                .el-tabs__item.is-active {
                    color: #333;
                }

                .el-tabs--card > .el-tabs__header .el-tabs__item.is-active, .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:hover {
                    border-color: #ddd;
                    border-bottom: 2px solid #fff;
                    border-radius: 0;
                }

                .el-tabs__content {
                    border: 1px solid #ddd;
                    border-top: 0;
                    padding: 30px 60px;
                    /* 授信 */

                    .credit-pay {
                        .credit-pay-content {
                            margin-top: 20px;

                            .w115 {
                                width: 115px;
                            }

                            .w120 {
                                width: 120px;
                            }
                        }
                    }

                    /* 线下 */

                    .line-pay {
                        position: relative;

                        .lab-title {
                            line-height: 14px;
                            border-left: 2px solid #ff4201;
                            padding-left: 5px;
                        }

                        .bank-card-wrap {
                            .bank-card {
                                width: 270px;
                                height: 150px;
                                border-radius: 6px;
                                font-size: 14px;
                                border: 1px solid #ddd;

                                .bank-card-title {
                                    border-radius: 5px 5px 0 0;
                                    height: 28px;
                                    background-color: #bbb;
                                    color: #fff;
                                    padding: 22px 30px 0;
                                }

                                .bank-card-content {
                                    height: 55px;
                                    padding: 0 30px;

                                    div {
                                        line-height: 32px
                                    }
                                }

                                .icon-correct {
                                    opacity: 0;
                                }

                                .opacity {
                                    opacity: 1;
                                }

                                .bank-deposit-w {
                                    display: flex;

                                    i {
                                        display: inline-block;
                                        width: 25px;
                                        margin-left: 5px;
                                    }
                                }

                                .bank-deposit {
                                    width: 220px;
                                }
                            }

                            .bank-card:hover {
                                .icon-correct {
                                    opacity: 1;
                                }
                            }
                        }

                        .payment-voucher-info {
                            .avatar-uploader {
                                width: 180px;
                                height: 180px;
                                border: 1px solid #BBBBBB;
                            }

                            .avatar-uploader-icon, .avatar {
                                width: 180px;
                            }

                            .uploader-mongolia-layer {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                text-align: center;
                                color: #fff;
                                opacity: 0;
                                /*// font-size: 20px;*/
                                background-color: rgba(0, 0, 0, .5);
                                transition: opacity .3s;
                                line-height: 180px;
                            }

                            .opacity:hover {
                                opacity: 1;
                            }

                            /* 上传图片加号大小 */

                            .el-icon-plus:before {
                                font-size: 30px;
                            }

                            /*// .el-form-item__error {*/
                            /*// 	top: 181px;*/
                            /*// 	left: 185px;*/
                            /*// }*/
                            /*// }*/
                        }

                        .line-pay-footer {
                            clear: both;
                        }
                    }
                }

                .pay-bank {
                    border: 1px solid #DDDDDD;
                    box-sizing: border-box;
                }

                .radio {
                    .el-radio__label {
                        font-size: 20px;
                    }
                }

            }
        }
    }
</style>