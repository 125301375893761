import { axiosApi, basePath } from "./api";

// 获取区域接口
export function getDistrict (data={traderId: sessionStorage.getItem("traderId")}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/customerDistrict/query', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 根据id获取商家信息
export function findStoreInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/findStoreInfo/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取客户信息
export function getClientAllList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/findClientAllList/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 根据客户编码查询客户详情接口
export function getK3NoList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerInfo/select/by/k3No', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 查询设置
export function getTraderInfoBySerllerId (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/findTraderInfoBySerllerId/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}



// 推荐商品添加获取商品信息
export function getByGoodsNo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/skuInfo/getByGoodsNo', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 商品分类
export function getClassifyTopList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/classifyTopList/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取员工列表
export function getEmployeeList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/employee/selectAll', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 获取未读消息
export function getUnReadCount (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/unlogin/trader/notice/unReadCount', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取商品库详情
export function getSkuInfoBack (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/skuInfoBack/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 企业认证
export function setCert (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/trader/cert', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取个人信息
export function getEmployeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/anon/cbs/account/employeeInfo', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


