<template>
  <div>
    <el-table :data="list">
      <el-table-column label="流水号" prop="chargeSn"  align="center"></el-table-column>
				<el-table-column label="客户名称" prop="buyerName"  align="center"></el-table-column>
				<el-table-column label="充值时间"  width="140"  align="center">
          <template slot-scope="{row}">
            <span v-if="row.showTime">{{ row.showTime }}</span>
            <span v-else>--</span>
           </template>
        </el-table-column>
				<el-table-column label="充值金额(元)" prop="chargeAmount"  align="center"></el-table-column>
				<el-table-column label="手续费(元)"  align="center">
          <template slot-scope="{row}">
            <span v-if="row.chargeFee">{{ row.chargeFee }}</span>
            <span v-else>--</span>
           </template>
        </el-table-column>
				<el-table-column label="充值状态"  align="center" >
          <template slot-scope="{row}">
            <el-tooltip placement="top" v-if="row.chargeStatus==='fail'">
              <div slot="content" >{{ row.failReason }}</div>
              <el-link type="danger">{{ row.chargeStatusName }}</el-link>
            </el-tooltip>
            <div v-else>{{ row.chargeStatusName }}</div>
           </template>
        </el-table-column>
				<el-table-column label="支付类型" prop="payGatewayVO.systemTypeName"  align="center"></el-table-column>
				<el-table-column label="k3收款单号" prop="erpReceiveBillNo"  align="center"></el-table-column>
				<!-- <el-table-column label="备注" prop="payGatewayVO.desc"  align="center"></el-table-column> -->
    </el-table>

    <div class="tar mt-20">
      <router-link :to="`${basePath}/largeMoneyInfo?userState=true`">
        <el-button type="text" class="width-auto">
          查看所有交易记录
          <i class="el-icon-arrow-right color-b"></i>
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { parseTime } from "@/utils";
import {Largemoneylist} from "~/api/setting";
import { IncrementalDisplayable } from "echarts/lib/util/graphic";
export default {
  name: 'largeMoney',
  props: ['id',],
  data(){
    return {
      
      basePath: basePath,
      list: [],
    }
  },
  mounted(){
    this.getList()
  },
  computed:{
   
  },
  methods:{
    getList(){
      Largemoneylist({sellerId: sessionStorage.getItem("traderId"),pageIndex:1,pageSize: 10}).then(res=>{
        this.list = res.elements.map(item=>{
          if(item.separateTime){
            item.showTime = parseTime(item.separateTime)
          }else{
            item.showTime = ''
          }
          return item
        })
      }).catch(err=>{
        console.log(err);
      })
    },
  }
}
</script>

<style lang="less" scoped>

</style>