<template>
	<div id="onlinePaymentInfo">
		<div class="clearfix">
			<el-form :inline='true' :model="formInline" class="form-search fl" label-width="auto">
				<el-form-item label="支付类型">
						<el-select v-model="formInline.payType" collapse-tags placeholder="请选择" clearable>
							<el-option v-for="item in options1" :key="item.payType" :value="item.payType" :label="item.name" />
						</el-select>
				</el-form-item>
				<el-form-item label="支付渠道">
						<el-select v-model="formInline.payChannelType" collapse-tags placeholder="请选择" clearable>
							<el-option v-for="item in options2" :key="item.payChannelType" :value="item.payChannelType" :label="item.name" />
						</el-select>
				</el-form-item>
				<el-form-item label="交易时间">
					<ys-daterange-picker v-model="time"/>
				</el-form-item>
			</el-form>
			<el-button class="fl" type="info" @click="handleSearch">查 询</el-button>
		</div>
		<div class="clearfix">
			<!-- <div @click="Export">
				<span class="fr color-blue export">导出</span>
			</div> -->
			<el-tabs class="bbd ctabs mb-10">
				<el-tab-pane label="所有明细" name="0"></el-tab-pane>
			</el-tabs>
			<el-table :data="list" :class="{'table-body-hidden':list.length==0}" stripe>
				<el-table-column label="订单号" prop="orderSn"  align="center"></el-table-column>
				<el-table-column label="支付金额(元)" prop="amount"  align="center"></el-table-column>
				<el-table-column label="手续费(元)" prop="feeAmount" v-if="userState"  align="center"></el-table-column>
				<el-table-column label="买家名称" prop="buyerName"  align="center"></el-table-column>
				<el-table-column label="支付类型" prop="payGatewayVO.payTypeName"  align="center"></el-table-column>
				<el-table-column label="支付渠道" prop="payGatewayVO.payChannelTypeName"  align="center"></el-table-column>
				<el-table-column label="是否支付成功" align="center" >
					<template slot-scope="{row}">
					<span v-if="row.payGatewayVO.paySuccess">是</span>
					<span v-else>否</span>
					</template>
				</el-table-column>
				    <el-table-column  v-if="this.userState" label="交易时间" prop="showTime"  align="center" width="140" ></el-table-column>
					<el-table-column  v-else label="支付时间" prop="showTime"  align="center" width="140" ></el-table-column>
				<el-table-column label="流水号" prop="separateSeqNo"  align="center"></el-table-column>
				<el-table-column label="备注" prop="remark"  align="center"></el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar" :current-page="pageIndex" :page-size='pageSize' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
			<div class="tac mt-30" v-if="list.length==0">
				<img src="../../../assets/empty.png" alt="" />
				<div class="color-9 mt-30">
					<span>暂无记录</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../../api/api'
	import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	import { parseTime } from "@/utils";
	import { exportInfo } from "~/api/download";
	import {Onlinepaylist} from "~/api/setting";
	export default {
		name: 'onlinePaymentInfo',
		components: {YsDaterangePicker},
		data() {
			let initDate = new Date().setHours(23, 59, 59, 0)
			return {
                buyerId:"",//TRADEID
								sellerId:null,
				userState:false,//状态 客户查看为默认fasle,商户查看为true
				time: [new Date(initDate - 1000 * 60 * 60 * 24 * 90),initDate],
        		formInline: {
					queryBeginDate:null,//开始时间
					queryEndDate:null,//结束时间
					payType:null,//支付类型
					payChannelType:null,//支付渠道
				},
				//支付类型1
				options1: [
					{payType: 12, name: '大额支付'},
					{payType: 3, name: '在线支付'},
					{payType: 4, name: '余额支付'},
					
				],
				//支付渠道2
				options2: [
					{payChannelType: 'alipay', name: '支付宝'},
					{payChannelType: 'wx', name: '微信'},
					{payChannelType: 'union', name: '银联'},
					
				],
				list: [],
				total:0,
				pageIndex:1,
				pageSize:10
			
					}
				},
				mounted() {
					this.userState = this.$route.query.userState === 'true';
					//客户查看，传值
					if(!this.userState) {
						this.buyerId=sessionStorage.getItem("traderId")
						this.sellerId=sessionStorage.getItem("sellerId")
					} 
					//商户查看，不传
					else{
						this.buyerId=""   
						this.sellerId=sessionStorage.getItem("traderId")  
					}
					this.getList()
					// console.log("是否商户:",this.userState,"buyid:",this.buyerId);	
				},

					
		methods: {
		
			handleSearch() { //查询
				this.getList()
			},
			
			// 获取列表
			getList(){
				if(this.time[0]){
					this.formInline.queryBeginDate = new Date(this.time[0]).getTime()
				}else{
					this.formInline.queryBeginDate = null
				}
				if(this.time[1]){
					this.formInline.queryEndDate = new Date(this.time[1]).getTime()
				}else{
					this.formInline.queryEndDate = null
				}
				this.formInline.pageIndex = this.pageIndex
				this.formInline.pageSize = this.pageSize
				let body={
					buyerId:this.buyerId,
					sellerId: this.sellerId,
					startDate:this.formInline.queryBeginDate,
					endDate:this.formInline.queryEndDate,
					payType:this.formInline.payType,
					payChannelType:this.formInline.payChannelType,
					pageIndex:this.formInline.pageIndex,
				    pageSize:this.formInline.pageSize
				}
				Onlinepaylist(body).then(res=>{
				this.list = res.elements.map(item=>{ 
					if(item.payGatewayVO.payTime){
						item.showTime = parseTime(item.payGatewayVO.payTime)
					}else{
						item.showTime = ''
					}
					return item
				})
				this.total=res.totalCount
				}).catch(err=>{
					console.log(err);
					
				})
				
			},
			handleCurrentChange(val) { //分页change
				this.pageIndex = val
				this.getList()
			},

			// 导出
			// exportInfo() {
			// 	var api = '/export/foundation/employee'
			// 	const body = {
			// 		loginName: this.form.loginName,
			// 		employeeName: this.form.employeeName,
			// 		departmentId: this.emptyId[this.emptyId.length - 1],
			// 		applicationType: 2,
			// 		traderId: sessionStorage.traderId,
			// 		isDeleted: 0,
			// 		idList: this.ids.join()
			// 	}
			// 	body.modelName = 'empty',
			// 	body.startRow = 0
			// 	body.fileName = '线上支付记录.xls'
			// 	exportInfo(api,body).then((res) => {
			// 	}).catch((err) => {
						
			// 	})
			// },
		}
	}
</script>

<style lang="less">
	#onlinePaymentInfo {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 50px 0;
		.export{
            font-weight: normal;
            position: relative;
            top: 10px;
            cursor: pointer;
        }
		/*表单查询*/
		.form-search {

			.el-form-item {
				margin-right: 30px;
			}
			.el-input__inner {
				font-size: 12px;
			}
			.el-form-item__label {
				padding: 0 10px 0 0;
				width: 57px;
				font-size: 12px;
				color: #333333;
				letter-spacing: -0.29px;
			}
			/*日期控件*/
			.el-date-editor--daterange.el-input {
				width: 240px;
			}
		}
		.ctabs {
			.el-tabs__header {
				padding-left: 0px;
			}
			.el-tabs__item {
				font-size: 12px;
			}
		}
		.el-table {
			th>.cell {
				font-weight: normal;
				color: #333333;
			}
			.transactiactive-text-btn {
				font-size: 12px;width: auto;
			}
		}
		.table-body-hidden {
			.el-table__empty-block {
				display: none;
			}
		}
		.h32{
			height: 32px;line-height: 32px;
		}
	}
</style>