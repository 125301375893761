
<template>
  <div class="paymaentAdd">
    <!-- 添加付款信息 -->
    <el-dialog :title="titles" append-to-body :visible="dialogVisible" @close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="170px" >
        <el-form-item label="银行类型" prop="cardType">
          <el-radio-group v-model="form.cardType" @change="changeCardType">
            <el-radio :label="0">对公账号</el-radio>
            <el-radio :label="1">对私账号</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="付款银行:" prop="bankCode">
          <el-select size="small" filterable value-key="bankCode" :filter-method="selectBankList" :loading="loading" v-model="obj" placeholder="请选择付款银行" clearable @change="changed">
            <el-option v-for="val in options" :key="val.bankCode" :label="val.bankShortName" :value="val" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户行" prop="bankLocationNo" v-if="form.cardType==0">
          <el-select size="small" filterable value-key="bankLocationNo" :filter-method="selectBankInfo" :loading="loading" v-model="objs" placeholder="请选择开户行" clearable @change="changeLocation">
            <el-option v-for="val in bankLocationList" :key="val.bankLocationNo" :label="val.bankLocationName" :value="val" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支持方式">
          <span>{{form.supportBusiness}}</span>
        </el-form-item>
        <el-form-item label="开户名称" prop="bankUserName">
          <el-input size="small" v-model.trim="form.bankUserName" auto-complete="off" placeholder="请输入开户名称" :maxlength='21'></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="bankNo">
          <el-input size="small" v-model.trim="form.bankNo" auto-complete="off" placeholder="请输入银行账号" :maxlength='26'></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </div>
    </el-dialog>

    
  </div>
</template>

<script>
import { queryBankTypeSet,updateBank, getBankLocationInfo } from "~/api/paymentSet";

export default {
  props:['showDialog','info',"titleType"],
  data(){
    return {
      titles: null,
      dialogVisible: false,
      btnloading:false,
      obj: null,
      objs: null,
      form: {
        id: null,
        cardType:0,
        optType: 1,
        bankCode: null,
        bankName:null,
        bankShortName:null,
        bankLocationNo:null,
        bankLocationName:null,
        supportBusiness: null,
        bankUserName: null,
        bankNo: null,
      },
      rules:{
        cardType: [
          { required: true, message: '请选择银行类型', trigger: 'change' }
        ],
        bankCode: [
          { required: true, message: '请选择付款银行', trigger: 'change' }
        ],
        bankLocationNo: [
          { required: true, message: '请选择开户行', trigger: 'change' }
        ],
        bankUserName: [
          { required: true, message: '请输入开户名称', trigger: 'blur' },
        ],
        bankNo: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
        ]
      },
      options:[],
      bankLocationList: [],
      loading: false
    }
  },
  watch: {
    showDialog(value) {
      this.dialogVisible = value;
      if (value) {
        // this.btnloading = false;
      }
    },
    titleType: {
      handler: function (val) {
        if (val) {
          this.titles = "编辑付款银行信息";
          this.form.optType = 2
        } else {
          this.titles = "添加付款银行信息";
        }
      },
      immediate: true,
    },
    info: {
      handler: function (val) {
        if(val){
          this.setInit(val);
          this.selectBankList(val.bankName);
          this.selectBankInfo(val.bankLocationName)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    changeCardType(val){
      console.log(val,'ccc')
      if(val==1){
        this.objs = null
        this.form.bankLocationNo = null;
        this.form.bankLocationName = null;
      }
    },
    setInit(info){
      this.form.id = info.id;
      this.form.cardType = info.cardType;
      this.form.bankCode = info.bankCode;
      this.form.bankName = info.bankName;
      this.form.bankShortName = info.bankShortName;
      this.form.supportBusiness = info.supportBusiness;
      this.form.bankLocationNo = info.bankLocationNo;
      this.form.bankLocationName = info.bankLocationName;
      this.form.bankUserName = info.bankUserName;
      this.form.bankNo = info.bankNo;
      // 选择框回显用
      this.obj = {
        bankCode:info.bankCode,
      } 
      this.objs = {
        bankLocationNo:info.bankLocationNo,
      } 
    },  
    selectBankList(bankName){
      if (bankName !== '') {
        this.loading = true;
        queryBankTypeSet({bankName}).then(res=>{
          this.loading = false;
          this.options = res
        }).catch(err=>{
          this.options = [];
          this.loading = false;
        })
      } else {
        this.options = [];
        this.loading = false;
      }
      
    },
    changed(){
      this.form.bankCode = this.obj.bankCode;
      this.form.bankName = this.obj.bankName;
      this.form.bankShortName = this.obj.bankShortName;
      this.form.supportBusiness = this.obj.supportBusiness;
    },
    selectBankInfo(bankLocationName){
      if (bankLocationName !== '') {
        var data = {
          bankLocationName:bankLocationName,
          bankCode:this.obj.bankCode
        }
        this.getBankLocation(data)
      } else {
        this.bankLocationList = [];
      }
    },
    getBankLocation(data){
      getBankLocationInfo(data).then(res=>{
        this.bankLocationList = res
      }).catch(err=>{
        this.bankLocationList = [];
      })
    },
    changeLocation(){
      this.form.bankLocationNo = this.objs.bankLocationNo;
      this.form.bankLocationName = this.objs.bankLocationName;
    },
    submit(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.saveBank()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveBank(){
      if(this.form.cardType==1){
        // 对私移除开户行信息
        this.form.bankLocationNo = null
        this.form.bankLocationName = null
      }
      updateBank(this.form).then(res=>{
        this.$emit('change')
        this.closeDialog()
      }).catch(err=>{
        this.closeDialog()
      })
    },
    closeDialog(){
      this.dialogVisible = false
      this.obj = null;
      this.objs = null;
      this.form = {
        id: null,
        cardType:0,
        optType: 1,
        bankCode: null,
        bankName:null,
        bankShortName:null,
        bankLocationNo:null,
        bankLocationName:null,
        supportBusiness: null,
        bankUserName: null,
        bankNo: null,
      }
      this.$emit('close')
    },
  }
}
</script>

<style lang="less">
  .paymaentAdd{
    .el-select .el-input__inner {
      padding-right: 30px;
    }
  }
   
</style>