<template>
	<div class="accountIndex">
		<div class="info bg-f plr-20 pt-10">
			<div class="imgWrap">
                <img :src="form.headportraitUrl" alt="" />
            </div>
			<div class="rightInfo">
				<div class="icon-wrap font-14 color-333">
                    <div class="inline-div">{{ ruleForm.storeName }}</div>
                    <div class="inline-div icon upgrade" :id="isCompanyVerified ? 'company-icon' : ''">
                        <span>企</span>
                        <div class="tip" v-if="isCompanyVerified">
                            <p>企业名称：{{ ruleForm.storeName | hideInformation }}</p>
                            <p>营业执照注册号：{{ ruleForm.licenseNum | hideInformation }}</p>
                            <i class="iconfont icon-triangle"></i>
                        </div>
                        <div v-else>
                            <div class="tip tip2">
															<p>您尚未企业认证  <el-link type="primary" :underline="false" @click="showVisible = true">立即认证</el-link></p>

                              <!-- <p>您尚未企业认证 <span><router-link :to="basePath + '/enterpriseAuthentication?mobile=' + ruleForm.mobile + '&storeName=' + ruleForm.storeName + '&licenseNum=' + ruleForm.licenseNum">立即认证</router-link></span></p> -->
                              <i class="iconfont icon-triangle"></i>
                            </div>
                        </div>
                    </div>
                    <div class="inline-div icon upgrade" >
                       <div :class="isVerified ? 'active-icon' : ''">
                            <span>个</span>
                            <div class="tip tip2" v-if="!isVerified">
                                <p>您尚未个人认证 <span><router-link :to="basePath + '/pay/nameverify'">立即认证</router-link></span></p>
                                <i class="iconfont icon-triangle"></i>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="color-999">
					<div class="inline-div">用户名：{{ ruleForm.loginName }}</div>
					<div class="inline-div">绑定手机：{{ ruleForm.mobile  }}</div>
				</div>
			</div>
		</div>
		<div class="title-tabs bg-f">
			<el-tabs
				v-model="type"
				@tab-click="handleTabs"
				class="activityTabs"
			>
				<el-tab-pane
					:label="item"
					:name="index + ''"
					v-for="(item, index) in dataTabs"
                    :key="index+'ax'"
				></el-tab-pane>
			</el-tabs>
		</div>
		<div class="account-content">
			<AccountInfo
				v-show="type == 0"
				@reset="achieve"
				:parentForm="form"
			/>
			<StoreInformation
				v-show="type == 1"
                :address="address"
				:parentRuleForm="ruleForm"
				@reset="getStoreInfo"
			/>
			<Address v-show="type == 2" />
		</div>
		<!-- 企业认证弹框 --> 
    <EnterpriseAuthentication :dialogVisible="showVisible" @confirm="confirmInfo" @close="showVisible=false" />
	</div>
</template>

<script>
import { axiosApi, basePath } from '~/api/api'
import AccountInfo from './accountInfo.vue'
import Address from '../address.vue'
import StoreInformation from './storeInformation.vue'
import EnterpriseAuthentication from "~/components/enterpriseAuthentication";

import _ from 'underscore'
export default {
	name: 'acountIndex',
	components: {
		AccountInfo,
		Address,
		StoreInformation,
		EnterpriseAuthentication
	},
	data() {
		return {
      address:'',
			type: '0',
			currentPage: 1,
			form: {

			}, //帐号信息
			ruleForm: {}, //商家资料->统一改成商户资料
			dataTabs: ['帐号信息', '商户资料', '收货地址'],
			showVisible: false,// 企业认证弹框
			isCompanyVerified:false,//企业是否认证
			isVerified:false,//个人是否认证
		}
	},
	methods: {
		//切换tab
		handleTabs(tab) {
			if (this.type != tab.index) {
				this.type = teb.index
			}
		},
		confirmInfo(){
			this.checkIdentification()
		},
		//获取帐号信息
		achieve() {
			const api = '/foundation/employee/selectExtJson'
			const body = {
				id: sessionStorage.id,
			}
			axiosApi(api, body, this.callback, this.currentPage, 10)
		},
		callback(res) {
			this.form = res.data.data
			if (this.form.businessDistrictPath != null) {
				this.form.businessDistrictPath = this.form.businessDistrictPath.split(',')
			} else {
				this.form.businessDistrictPath = []
			}
		},
		//获取店铺信息
		getStoreInfo() {
			const api = '/trader/findStoreInfo/select'
			const body = { traderId: sessionStorage.traderId }
			axiosApi(api, body, (res) => {
				res.data.data.photos.map((item) => {
					item.url = item.photoUrl
				})
				this.ruleForm = _.pick(
					res.data.data,
					'loginName',
					'traderNumber',
					'avater',
					'storeName',
					'platformDescription',
					'addressVO',
					'licenseNum',
					'photos',
					'linkname',
					'jobOffer',
                    'mobile',
					'tel',
					'email',
					'name',
					'distManagerMobile',
					'traderNumber'
				)
				this.address =
					res.data.data.addressVO.provinceName +
					'/' +
					res.data.data.addressVO.cityName +
					'/' +
					res.data.data.addressVO.regionName
				this.ruleForm.licenseImg = []
				this.ruleForm.licenseImg.push({ url: res.data.data.licenseImg }) //营业执照
			})
        },
        //是否认证
        checkIdentification(){
            axiosApi('/anon/cbs/account/employeeInfo',{},res => {
                this.isCompanyVerified = res.data.data.isCompanyVerified;
                this.isVerified = res.data.data.isVerified;
            },1,10,err => {
                this.$message({
                    type:'warning',
                    message:err.data.message
                })
            })
        },
	},
	mounted() {
		this.achieve()
        this.getStoreInfo()
        this.checkIdentification()
	},
}
</script>

<style lang="less">
.accountIndex {
	.info {
        height: 95px;
        .imgWrap{
            float: left;
            width: 90px;
			height: 90px;
            img {
                width: 100%;
                height: 100%;
            }
        }
		.rightInfo {
            float: left;
            height: 100%;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .inline-div{
                display: inline-block;
                position: relative;
                .active-icon{
                    background-color: #f04844
                }
            }
            .icon-wrap{
                .tip{
                    position: absolute;
                    display: none;
                    color: #666;
                    width: 250px;
                    line-height: 24px;
                    border: 1px solid #999;
                    border-radius: 10px;
                    background-color: #fff;
                    opacity: 0.7;
                    left: -114px;
                    top: 36px;
                    z-index: 10;
                    i{
                        position: absolute;
                        top: -21px;
                        left: 44%;
                        font-size: 26px;
                        color: #999;
                    }

                }
                .tip2{
                    left: -114px;
                }
                #company-icon:hover,.upgrade:hover{
                    .tip{
                        display: block;
                    }
                }
            }
            .icon{
                font-size: 12px;
                display: inline-block;
                text-align: center;
                width: 20px;
                line-height: 20px;
                border-radius: 30%;
                color: #fff;
                cursor: pointer;
                position: relative;
            }
            #company-icon{
                background-color: rgba(240, 72, 68, 1);
               
            }
            .upgrade{
                background-color: rgba(242, 242, 242, 1);
            }
		}
	}
}
</style>
