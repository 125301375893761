import { axiosApi, basePath } from "./api";

// 付款账号列表
export function getBankTypeSet(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/buyerPaymentBankInfo/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
// 查询银行
export function queryBankTypeSet(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/bankTypeSet/query', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 更新银行卡信息
export function updateBank(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/buyerPaymentBankInfo/opt', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 默认银行卡信息
export function defaultBank(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/buyerPaymentBankInfo/default', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取开户行
export function getBankLocationInfo(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/bankLocationInfo/query', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 检查线上预付款是否可用
export function payCheck(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerOnlineSet/payCheck', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取余额
export function getBalanceInfo(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/accountBalance/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 预付款充值
export function largePay(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/finance/charge/large/pay', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 大额支付
export function orderLargePay(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/order/large/pay', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 大额支付校验
export function getSelectBySn(data = {}) {
  return new Promise((resolve, reject) => {
    axiosApi('/payCenter/largePay/continuePay', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}



