
<template>
  <!-- 转账信息 -->
  <div class="transferInfo">
    <div class="alignCenter addDiv">
      <span>付款信息</span>
    </div>
    <el-card class="box-card">
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">付款银行:</div>
          <div class="itemDivRight">{{info.paymentBankName}}</div>
        </div>
      </div>
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">开户名称:</div>
          <div class="itemDivRight">{{info.paymentUserName}}</div>
        </div>
      </div>
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">付款银行号:</div>
          <div class="itemDivRight">{{info.paymentBankNo}}</div>
        </div>
      </div>
    </el-card>
    <div class="alignCenter addDiv">
      <span>转账信息</span>
    </div>
    <el-card class="box-card">
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">收款银行:</div>
          <div class="itemDivRight">{{info.receiveBankName}}</div>
        </div>
        <el-link type="primary"
         v-clipboard:copy="info.receiveBankName"
         v-clipboard:success="onCopy"
         v-clipboard:error="onError"
        >复制</el-link>
      </div>
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">收款户名:</div>
          <div class="itemDivRight">{{info.receiveUserName}}</div>
        </div>
        <el-link type="primary"
         v-clipboard:copy="info.receiveUserName"
         v-clipboard:success="onCopy"
         v-clipboard:error="onError"
        >复制</el-link>
      </div>
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">收款账户号:</div>
          <div class="itemDivRight">{{info.receiveBankNo}}</div>
        </div>
        <el-link type="primary"
         v-clipboard:copy="info.receiveBankNo"
         v-clipboard:success="onCopy"
         v-clipboard:error="onError"
        >复制</el-link>
      </div>
      <div class="alignCenter" v-if="info.receiveLocationNo">
        <div class="itemDiv">
          <div class="itemDivLeft">联行号:</div>
          <div class="itemDivRight">{{info.receiveLocationNo}}</div>
        </div>
        <el-link type="primary"
         v-clipboard:copy="info.receiveLocationNo"
         v-clipboard:success="onCopy"
         v-clipboard:error="onError"
        >复制</el-link>
      </div>
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft">支付截止时间:</div>
          <div class="itemDivRight">{{info.expireTime | time}}</div>
        </div>
      </div>
      <div class="alignCenter">
        <div class="itemDiv">
          <div class="itemDivLeft itemDivTips">友情提示:</div>
          <div class="itemDivRight itemDivTips">{{info.remind}}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  props:['info'],
  data(){
    return {

    }
  },
  watch:{
    info: {
      handler: function (val) {
        
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    onCopy() {
      this.$message({
        type: "success",
        message: "复制成功",
      });
    },
    onError() {
      this.$message({
        type: "success",
        message: "复制失败",
      });
    },
  }
}
</script>

<style lang="less">
  .transferInfo{
    .alignCenter{
       display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .itemDiv{
      display: flex;
      margin: 5px 0;
      .itemDivLeft{
        flex: 0 0 auto;
        text-align: right;
        width: 110px;
        margin-right: 20px;
      }
      .itemDivRight{
        flex: 1;
      }
      .itemDivTips{
        color: red;
      }
    }
    .addDiv{
      margin: 20px 0 10px;
    }
  }
   
</style>