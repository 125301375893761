<template>
    <div class="my-bill">
        <div class="title-top bg-f">
					<el-tabs v-model="active">
							<el-tab-pane label="订单交易明细" name="1"></el-tab-pane>
							<el-tab-pane label="大额转账记录" name="2"></el-tab-pane>
					</el-tabs>
		</div>
        <div class="bg-f  pl-20  pr-20 bill-content pb-50" >
            <OnlinePaymentInfo v-if="active==1" />
			<LargeMoneyInfo v-else  />
        </div>
    </div>
</template>
<script>
	import OnlinePaymentInfo from "~/views/supplier/paymodule/onlinePaymentInfo.vue";
	import LargeMoneyInfo from "~/views/supplier/paymodule/largeMoneyInfo.vue";
	export default {
		components:{
			OnlinePaymentInfo,
			LargeMoneyInfo
		},
		data(){
			
				return{
					active:'1',
					// loading:"true"
				}
		},
		created(){
	
		},
		mounted(){
			
			
		},
	  beforeRouteLeave (to, form, next){
    	next()
		},
	  methods:{
		// onComponentLoaded() {
		// 	this.loading = false;  // 加载完成，设置 loading 为 false
		// 	},
	    	
	  }
	}
</script>
<style lang="less">
    .my-bill{
        .bill-content{
            min-height: 661px;
        }
        .tabs{
            .el-tabs__header{padding-left: 0;}
        }
        .table-body-hidden{
            .el-table__body-wrapper{
                display: none;
            }
        }
        .el-table .tag-row .cell{
            color: #ff4201;
        }
        .textBtn{
        	width: 28px;
        }
    }
</style>
