
<template>
  <!-- 大额支付的付款信息&预付款的付款信息 -->
  <div class="paymaentInfo">
    <div>
      <div class="alignCenter addDiv">
        <span>付款信息</span>
        <el-link v-if="showAdd" type="danger" :underline="false" @click="dialogVisible = true">添加付款信息</el-link>
      </div>
      <el-card class="box-card" v-if="info">
        <div class="alignCenter">
          <div>
            <div class="itemDiv">
              <div class="itemDivLeft">银行类型:</div>
              <div class="itemDivRight">{{info.cardType==0?"对公账号":"对私账号"}}</div>
            </div>
            <div class="itemDiv">
              <div class="itemDivLeft">付款渠道:</div>
              <div class="itemDivRight">{{info.bankShortName}}</div>
            </div>
            <div class="itemDiv" v-if="info.cardType==0">
              <div class="itemDivLeft">开户行:</div>
              <div class="itemDivRight">{{info.bankLocationName}}</div>
            </div>
            <div class="itemDiv">
              <div class="itemDivLeft">开户名称:</div>
              <div class="itemDivRight">{{info.bankUserName}}</div>
            </div>
            <div class="itemDiv">
              <div class="itemDivLeft">银行账号:</div>
              <div class="itemDivRight">{{info.bankNo}}</div>
            </div>
            <div class="itemDiv">
              <div class="itemDivLeft">支持方式:</div>
              <div class="itemDivRight">{{info.supportBusiness}}</div>
            </div>
          </div>
          <div><el-link type="primary" @click="openDialog">更多信息</el-link></div>
        </div>
      </el-card>
    </div>

    <!-- 添加付款信息 -->
    <DialogAdd :showDialog="dialogVisible" :titleType="false" :info="null" @change="submit" @close="dialogVisible=false" />

    <!-- 选择付款 -->
    <el-dialog title="更换付款信息" append-to-body destroy-on-close :visible="dialogPayInfo" width="70%" label-width="70px" @close="closeDialog">
      <div style="margin:0 20px">
        <el-table
          :data="list"
          border>
          <el-table-column prop="bankUserName" label="开户名称" min-width="100">
          </el-table-column>
          <el-table-column prop="cardType" label="银行类型" min-width="100">
            <template slot-scope="{row}">
              {{row.cardType==0?"对公账号":"对私账号"}}
            </template>
          </el-table-column>
          <el-table-column prop="bankShortName" label="付款渠道" min-width="100">
          </el-table-column>
          <el-table-column prop="bankLocationName" label="开户行" min-width="100">
          </el-table-column>
          <el-table-column prop="supportBusiness" label="支持方式" min-width="150">
          </el-table-column>
          <el-table-column prop="bankNo" label="账号" min-width="150">
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100">
            <template slot-scope="{row}">
              <div>
                <el-link v-if="row.selectItem" @click="selectInfo(row,false)">取消选择</el-link>
                <el-link v-else type="primary" @click="selectInfo(row,true)">选择</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DialogAdd from "@/components/payment/dialogAdd.vue";
import { getBankTypeSet } from "~/api/paymentSet";

export default {
  components:{
    DialogAdd
  },
  props:{
    showAdd: {
      type: Boolean,
      default: true
    },
    buyerId:{
      type: Number,
      default: null
    }
  },
  data(){
    return {
      form:{},
      dialogVisible: false,
      dialogPayInfo: false,
      list: [],
      info:null,
    }
  },
  activated(){
    this.getList()
  },
  mounted(){
   
    this.getList()
  },
  methods:{
    submit(){
      this.getList();
    },
    // 获取列表
    getList(){
      getBankTypeSet({traderId:this.buyerId}).then(res=>{
        this.list = res.elements.map(item=>{
          // 初始化选中状态
          if(item.defaulted){ // 选中默认
            item.selectItem = true
            this.info = item
            this.$emit('change',this.info)
          }else{
            item.selectItem = false
          }
          return item
        })    
        
      }).catch(err=>{
        this.list = []
      })
    },
    openDialog(){
      this.dialogPayInfo=true
    },
    selectInfo(row,type){
      this.list.forEach(item=>{
        if(item.id === row.id){
          item.selectItem = type
        }else{
          item.selectItem = false
        }
      })
    },
    submitInfo(){
      this.list.forEach(item=>{
        // 筛选选中
        if(item.selectItem){ 
          this.info = item
          this.$emit('change',this.info)
        }
      })   
      this.dialogPayInfo = false
    },
    closeDialog(){
      this.dialogPayInfo = false
    },
  },
}
</script>

<style lang="less">
  .paymaentInfo{
    .alignCenter{
       display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .itemDiv{
      display: flex;
      margin: 5px 0;
      .itemDivLeft{
        flex: 0 0 auto;
        text-align: right;
        width: 80px;
        margin-right: 20px;
      }
      .itemDivRight{
        flex: 1;
      }
    }
    .addDiv{
      margin: 20px 0 10px;
    }
  }
   
</style>